import React, { useState } from "react";
import "./../../../app/payments.css";
import Payment from "./Payment";

// Define the Plan type
type Plan = {
  name: string;
  price: number;
  duration: string;
};

const plans = {
  monthly: [
    {
      name: "Grátis",
      price: 0,
      color: "white",
      action: "Ativo",
      duration: "Mensal",
    },
    {
      name: "Individual",
      price: 19.99,
      color: "#d5f279",
      action: "Selecionar",
      duration: "Mensal",
    },
    {
      name: "Organização",
      price: 199.99,
      color: "#b184e7",
      action: "Contactar",
      duration: "Mensal",
    },
  ],
  annual: [
    {
      name: "Grátis",
      price: 0,
      color: "white",
      action: "Ativo",
      duration: "Anual",
    },
    {
      name: "Individual",
      price: 199.99,
      color: "#d5f279",
      action: "Selecionar",
      duration: "Anual",
    },
    {
      name: "Organização",
      price: 1999.99,
      color: "#b184e7",
      action: "Contactar",
      duration: "Anual",
    },
  ],
};

const Pricing = () => {
  const [pricingPlan, setPricingPlan] = useState<Plan | undefined>(undefined);
  const [monthly, setMonthly] = useState(true);

  return (
    <>
      {!pricingPlan ? (
        <div
          data-current="Tab 1"
          data-easing="ease"
          data-duration-in="300"
          data-duration-out="100"
          className="w-tabs"
        >
          <div
            data-w-id="3c647b7e-29ad-fd15-d91f-ec8e9e7d0557"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="pricing-tab-content w-tab-content"
          >
            <div className="flex items-center justify-center space-x-2 my-2">
              <span
                onClick={() => setMonthly(true)}
                className={`px-6 py-3 rounded-full hover:cursor-pointer border border-black ${
                  monthly ? "bg-black text-white" : ""
                }`}
              >
                <div className="text-xl">Mensal</div>
              </span>
              <span
                onClick={() => setMonthly(false)}
                className={`px-6 py-3 rounded-full hover:cursor-pointer border border-black ${
                  monthly ? "" : "bg-black text-white"
                }`}
              >
                <div className="text-xl">Anual</div>
              </span>
            </div>
            <div
              data-w-tab="Tab 1"
              className="w-tab-pane w--tab-active"
              id="w-tabs-0-data-w-pane-0"
              role="tabpanel"
              aria-labelledby="w-tabs-0-data-w-tab-0"
              style={{ opacity: 1 }}
            >
              <div className="w-dyn-list w-full">
                <div
                  role="list"
                  className="pricing-collection-list w-dyn-items flex flex-wrap"
                >
                  {plans[monthly ? "monthly" : "annual"].map((plan) => (
                    <div
                      key={plan.name}
                      style={{
                        backgroundColor: plan.color,
                        flex: "0 0 33.33%",
                        boxSizing: "border-box",
                        padding: "20px", // Add some padding if you want
                      }}
                      className="pricing-container-single"
                    >
                      <div className="margin-bottom margin-medium">
                        <div className="text-align-center">
                          <div className="margin-bottom margin-medium">
                            <h2 className="pricing-type">{plan.name}</h2>
                          </div>
                          <div className="margin-bottom margin-medium">
                            <div className="line-horizontal"></div>
                          </div>
                          <div className="margin-bottom margin-medium">
                            <h3
                              data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                              className="pricing-value"
                            >
                              {plan.price}€
                            </h3>
                            <p className="pricing-annually">
                              Billed as $96 per year
                            </p>
                          </div>
                          <div className="line-horizontal"></div>
                        </div>
                      </div>
                      <div className="price-list-wrapper">
                        <div className="margin-bottom margin-small">
                          <div className="pricing-single-list">
                            <img
                              loading="lazy"
                              src="https://cdn.prod.website-files.com/668962d6d23ca506ff361c2e/668e88a48a28c4596dd6a127_check.svg"
                              alt=""
                            />
                            <p>Unlimited members</p>
                          </div>
                        </div>
                        <div className="margin-bottom margin-small">
                          <div className="pricing-single-list">
                            <img
                              loading="lazy"
                              src="https://cdn.prod.website-files.com/668962d6d23ca506ff361c2e/668e88a48a28c4596dd6a127_check.svg"
                              alt=""
                            />
                            <p>Unlimited feedback</p>
                          </div>
                        </div>
                        <div className="margin-bottom margin-small">
                          <div className="pricing-single-list">
                            <img
                              loading="lazy"
                              src="https://cdn.prod.website-files.com/668962d6d23ca506ff361c2e/668e88a48a28c4596dd6a127_check.svg"
                              alt=""
                            />
                            <p>Team feedback history</p>
                          </div>
                        </div>
                        <div className="margin-bottom margin-small">
                          <div className="pricing-single-list">
                            <img
                              loading="lazy"
                              src="https://cdn.prod.website-files.com/668962d6d23ca506ff361c2e/668e88a48a28c4596dd6a127_check.svg"
                              alt=""
                            />
                            <p>Team feedback history (30 items)</p>
                          </div>
                        </div>
                      </div>
                      <div className="margin-top margin-medium">
                        <a
                          className="button is-full-width w-button"
                          onClick={() => {
                            if (plan.action === "Selecionar") {
                              setPricingPlan(plan);
                            }
                          }}
                        >
                          {plan.action}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Payment
            plan={pricingPlan}
            back={() => setPricingPlan(undefined)}
          ></Payment>
        </>
      )}
    </>
  );
};

export default Pricing;
