"use client";

import * as React from "react";
import { CheckIcon } from "@radix-ui/react-icons";
import { cn } from "../lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";
import { Dialog } from "./ui/dialog";

import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { UserContext } from "../services/user";
import { useContext, useEffect } from "react";
import { userAffiliation } from "../types";
import { UserContextWithUserType } from "../shared/UserContentWithUser";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface TeamSwitcherProps extends PopoverTriggerProps {
  className: string;
}

type Team = {
  label: string;
  value: string;
};

type Group = {
  label: string;
  teams: Team[];
};

type Groups = Group[];

export default function SourceSwitcher({ className }: TeamSwitcherProps) {
  // Context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const [open, setOpen] = React.useState(false);
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false);
  const [groups, setGroups] = React.useState<Groups>([
    {
      label: "Conta Pessoal",
      teams: [
        {
          label: "",
          value: "",
        },
      ],
    },
    {
      label: "Unidade de Saúde",
      teams: [
        {
          label: "",
          value: "",
        },
      ],
    },
  ]);

  const [selectedTeam, setSelectedTeam] = React.useState<Team | undefined>(
    groups[0].teams[0]
  );

  useEffect(() => {
    const groups = [
      {
        label: "Conta Pessoal",
        teams: [
          {
            label: userContext.user.profile.name,
            value: userContext.user.profile.name,
          },
        ],
      },
      {
        label: "Unidade de Saúde",
        teams: (userContext.user.affiliations || []).map((affiliation) => ({
          label: affiliation.organization.name,
          value: affiliation.organization.name
            .toLowerCase()
            .replace(/\s/g, "-"), // Example value generation
        })),
      },
    ];
    setGroups(groups);
    setSelectedTeam(
      !userContext.activeAffiliation
        ? groups[0].teams[0]
        : groups[1].teams.find(
            (team: Team) =>
              team.label === userContext.activeAffiliation?.organization.name
          )
    );
  }, [userContext.user]);

  const _setSelectedTeam = (team: Team, affiliation: boolean) => {
    if (!affiliation) userContext.updateActiveAffiliation(undefined);
    else {
      userContext.updateActiveAffiliation(
        userContext.user.affiliations.find(
          (affiliation: userAffiliation) =>
            affiliation.organization.name === team.label
        )
      );
    }
  };

  type Team = (typeof groups)[number]["teams"][number];

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("w-[200px] justify-between", className)}
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={`https://avatar.vercel.sh/${selectedTeam?.value}.png`}
                alt={selectedTeam?.label}
                className="grayscale"
              />
              <AvatarFallback></AvatarFallback>
            </Avatar>
            {selectedTeam?.label}
            <div className="flex">
              <ChevronDown
                className={cn(
                  "h-5 w-5 transition-all duration-300 scale-100",
                  open ? "rotate-90 scale-0" : "rotate-0 scale-100"
                )}
              />
              <ChevronUp
                className={cn(
                  "absolute h-5 w-5 transition-all  duration-300 scale-0",
                  open ? "rotate-0 scale-100" : "rotate-90 scale-0"
                )}
              />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0 z-[1]">
          <Command>
            <CommandList>
              {groups.map((group: Group) => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.teams.map((team: Team) => (
                    <CommandItem
                      key={team.value}
                      onSelect={() => {
                        setSelectedTeam(team);
                        setOpen(false);
                        _setSelectedTeam(
                          team,
                          group.label === "Unidade de Saúde"
                        );
                      }}
                      className="text-sm hover:cursor-pointer hover:bg-neutral-100"
                    >
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          src={`https://avatar.vercel.sh/.png`}
                          alt={team.label}
                          className="grayscale"
                        />
                        <AvatarFallback>SC</AvatarFallback>
                      </Avatar>
                      {team.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedTeam?.value === team.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                  {group.teams.length === 0 && (
                    <>
                      {userContext?.user?.creationStatus === "PENDING_FORM" ? (
                        <CommandItem className="flex items-center justify-center text-xs text-center text-white hover:cursor-pointer bg-teal-600 hover:bg-teal-800">
                          A validar conta
                        </CommandItem>
                      ) : (
                        <CommandItem className="flex items-center justify-center text-xs text-center text-white hover:cursor-pointer bg-teal-600 hover:bg-teal-800">
                          Verificar conta
                        </CommandItem>
                      )}
                    </>
                  )}
                </CommandGroup>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </Dialog>
  );
}
