import React, { memo, useContext, useEffect, useState } from "react";
import "../../app/App.css";
import { UserContext } from "../../services/user";
import { listConsents, listOverallConsents } from "../../types";
import Loading from "../../components/loading";
import { Button } from "../../components/ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "../../components/ui/carousel";
import { dateToBlockchain } from "../../utils/dateTimeUtils";
import Card4Confirmation from "./tabs/Card4Confirmation";
import showToast from "../../components/toast";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";

interface PendingConsentsProps {
  setPendingCount: React.Dispatch<React.SetStateAction<number>>;
}

const PendingConsents = ({ setPendingCount }: PendingConsentsProps) => {
  const userContext = useContext(UserContext) as UserContextWithUserType;

  const [pendingRequests, setPendingRequests] = useState<listConsents[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const listPendingRequests = async () => {
      try {
        const { data } =
          await userContext.axiosInstance.get<listOverallConsents>(
            `consent/my?status=PENDING`
          );
        setPendingRequests(
          // Fix sometimes not assuming a date format
          data.consents.map((consent: listConsents) => ({
            ...consent,
            startsAt: consent.startsAt ? new Date(consent.startsAt) : undefined,
            expiresAt: consent.expiresAt
              ? new Date(consent.expiresAt)
              : undefined,
          }))
        );
        setPendingCount(data.length);
      } catch (error) {
        console.error("Error fetching consents pending:", error);
        showToast("Erro ao buscar pedidos pendentes.", "error", 3000);
      } finally {
        setIsLoading(false);
      }
    };

    listPendingRequests();
  }, []);

  const accept = async (consent: listConsents) => {
    if (!consent.askedBy?.smartWallet) return; // Accepting, must have a requester
    setIsLoading(true);
    try {
      const validHash = (
        await userContext.axiosInstance.get(
          `consent/validHash/${consent.id}/${consent.hash}`
        )
      ).data;
      if (!validHash) {
        showToast("Existe um erro neste consentimento.", "error", 3000);
        return;
      }
      await userContext.blockchain.giveConsent({
        userId: userContext.user.id,
        requester: consent.askedBy.smartWallet,
        hash: consent.hash,
        duration: consent.duration,
        ...(consent.startsAt
          ? { startAt: dateToBlockchain(consent.startsAt) }
          : {}),
      });
      setPendingRequests((pendingRequests) =>
        pendingRequests.filter((request) => request.id !== consent.id)
      );
      setPendingCount((count: number) => count - 1);
      showToast("Pedido de acesso aceite com sucesso!", "success", 3000);
    } catch (error) {
      console.error("Error accepting request:", error);
      showToast("Erro ao aceitar o pedido de acesso.", "error", 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const reject = async (consentId: string) => {
    setIsLoading(true);
    try {
      const response = await userContext.axiosInstance.patch(
        `consent/reject/${consentId}`,
        {}
      );
      setPendingRequests((pendingRequests) =>
        pendingRequests.filter((request) => request.id !== consentId)
      );
      setPendingCount((count: number) => count - 1);
      if (response && response.data) {
        showToast("Pedido de acesso rejeitado com sucesso!", "success", 3000);
      }
    } catch (error) {
      console.error("Error rejecting request:", error);
      showToast("Erro ao rejeitar o pedido de acesso.", "error", 3000);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="py-32">
          <Loading full={false} timeout={30000} />
        </div>
      ) : (
        <>
          <Carousel className="ml-14 w-full max-w-lg">
            <CarouselContent>
              {pendingRequests.map((consent: listConsents, index: number) => (
                <CarouselItem key={index}>
                  <div className="p-1">
                    <Card4Confirmation
                      userSharing={userContext.user.profile.name || ""}
                      userAsking={consent.askedBy?.name}
                      affiliations={consent.sharingWithAffiliations}
                      departmentNames={consent.sharingWithDepartmentsNames}
                      organizationName={consent.sharingWithOrganizationName}
                      categories={consent.categories || []}
                      startsAt={consent.startsAt}
                      duration={consent.duration}
                      expiresAt={consent.expiresAt}
                    />
                    <div className="flex justify-between mb-2">
                      <Button
                        className="bg-red-600"
                        onClick={() => reject(consent.id)}
                      >
                        Rejeitar
                      </Button>
                      <Button onClick={() => accept(consent)}>Confirmar</Button>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </>
      )}
    </>
  );
};

export default memo(PendingConsents);
