import { motion } from "framer-motion";
import React, { memo, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface LoadingPageProps {
  full?: boolean;
  loadingText?: string;
  timeout?: number;
  disableTimeout?: boolean;
  navigateOnTimeout?: boolean;
  children?: ReactNode;
}

function Loading({
  full = true,
  loadingText,
  timeout = 20000,
  disableTimeout = false,
  navigateOnTimeout = true,
  children,
}: LoadingPageProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (disableTimeout) return;
    const timer = setTimeout(() => {
      if (navigateOnTimeout) navigate("/not-found?mode=TIMEOUT");
    }, timeout); // 20 seconds
    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(0, 0, 0, 0)",
    },
    visible: {
      pathLength: 1,
      fill: "rgba(0, 0, 0, 1)",
    },
  };

  const paths = [
    "M81.53,570.8c-27.06-51.1-41.36-108.84-41.36-166.99c0-86.99,31.63-170.82,89.05-236.04 C186.01,103.28,263.89,61.4,348.57,49.8c2.59,166.55,140.08,301.94,306.74,301.94c28.23,0,56.23-3.86,83.25-11.46 C734.99,429,673.08,486.2,543.79,520.24C424.56,551.63,257.95,560.95,81.53,570.8z",
    "M397.53,761.17c-62.18,0-123.41-16.21-177.07-46.89c-51.81-29.62-95.75-72.09-127.14-122.89 331.3-18.53,617.57-34.67,660.88-209.8c0.46,7.38,0.69,14.84,0.69,22.22c0,48.24-9.45,95.04-28.08,139.1 c-18,42.55-43.77,80.77-76.59,113.59c-32.82,32.82-71.04,58.59-113.59,76.59C492.58,751.72,445.78,761.17,397.53,761.17z",
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: full ? "100vh" : "auto",
      }}
    >
      {children && <div className="mb-6">{children}</div>}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 795.06 810.89"
        width="90"
        height="90"
      >
        {paths.map((d, index) => (
          <motion.path
            key={index}
            d={d}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: {
                duration: 1.2,
                ease: "circInOut",
                repeat: Infinity,
                repeatType: "reverse",
              },
              fill: {
                duration: 1.2,
                ease: [1, 0, 0.8, 1],
                repeat: Infinity,
                repeatType: "reverse",
              },
            }}
            stroke="gray"
            strokeWidth="4"
          />
        ))}
      </svg>
      {loadingText && <p style={{ color: "white" }}>{loadingText}</p>}
    </div>
  );
}
export default memo(Loading);
