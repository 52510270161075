import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import { ThirdwebProvider } from "thirdweb/react";

// console.error = () => {};
//console.warn = () => {};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ThirdwebProvider>
    <App />
  </ThirdwebProvider>
);
