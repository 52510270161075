// UI
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";

//React
import React, {
  useState,
  useEffect,
  useContext,
  FormEvent,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

// Thirdweb
import { createThirdwebClient, defineChain } from "thirdweb";
import { inAppWallet, Account, smartWallet } from "thirdweb/wallets";
import { baseSepolia } from "thirdweb/chains";
import { getUserEmail, preAuthenticate } from "thirdweb/wallets/in-app";

// Internal
import { CLIENT_ID, FACTORY_ADDRESS } from "../../environment/variables";
import { UserContext } from "../../services/user";
import { ThirdwebLoginMethod } from "../../types";
import { Separator } from "../../components/ui/separator";

import { motion } from "framer-motion";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../components/ui/otp-input";
import showToast from "../../components/toast";
import { isEmail } from "../../utils/stringUtils";
import { PhoneInputLight } from "../../components/ui/phone-input-light";
import { HiArrowLongLeft } from "react-icons/hi2";
import debounce from "lodash/debounce";

const Login = () => {
  // Context
  const userContext = useContext(UserContext);

  // Navigation
  const navigate = useNavigate();

  // Thirdweb
  const client = useMemo(
    () => createThirdwebClient({ clientId: CLIENT_ID }),
    []
  );
  const chain = defineChain(baseSepolia); // Better without useMemo

  // Login email
  const [email, setEmail] = useState<string>("");

  // Login phone
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode] = useState<string | undefined>("PT");

  const [otp, setOtp] = useState("");

  // Toggle
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [hasAnimated, setHasAnimated] = useState(true);
  const [phoneVerificationModal, setPhoneVerificationModal] =
    useState<boolean>(false);
  const [isEntering, setIsEntering] = useState<boolean>(true);

  useEffect(() => {
    if (!userContext?.user) return;
    if (userContext.activeAffiliation) navigate("/people");
    else navigate("/consents");
  }, [userContext?.user, navigate]);

  useEffect(() => {
    if (!loggingIn && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [loggingIn, hasAnimated]);

  useEffect(() => {
    if (userContext) setLoggingIn(userContext.loggingIn);
  }, [userContext?.loggingIn]);

  // Debounce the signIn function (avoid multiple consecutive calls to the function)
  const debouncedSignIn = useCallback(
    debounce(() => {
      if (loggingIn) return;
      setLoggingIn(true);

      if ((phoneNumber && email) || (!phoneNumber && !email)) {
        showToast("Insira um email ou um telemóvel", "error", 3000);
        setLoggingIn(false);
        return;
      }

      if (phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber, countryCode as CountryCode)) {
          showToast("Número de telemóvel inválido", "error", 3000);
          setLoggingIn(false);
          return;
        }
        _sendOTP();
        return;
      }

      if (!isEmail(email)) {
        showToast("Email inválido", "error", 3000);
        setLoggingIn(false);
        return;
      }

      instantiateAccount(ThirdwebLoginMethod.EMAIL);
    }, 300), // 300 ms debounce
    [loggingIn, phoneNumber, email, countryCode]
  );

  const instantiateAccount = useCallback(
    async (method: ThirdwebLoginMethod) => {
      if (!loggingIn) setLoggingIn(true);
      try {
        const userInAppWallet = inAppWallet();
        let eoaAccount: Account;
        let loginValue: string;

        switch (method) {
          case ThirdwebLoginMethod.EMAIL:
            loginValue = email;
            eoaAccount = await userInAppWallet.connect({
              client,
              chain,
              strategy: "iframe_email_verification",
              email: loginValue,
            });
            break;
          case ThirdwebLoginMethod.GOOGLE:
            eoaAccount = await userInAppWallet.connect({
              client,
              chain,
              strategy: "google",
            });
            loginValue = (await getUserEmail({ client })) || "";
            break;
          case ThirdwebLoginMethod.PHONE:
            loginValue = phoneNumber;
            eoaAccount = await userInAppWallet.connect({
              client,
              chain,
              strategy: "phone",
              phoneNumber: loginValue,
              verificationCode: otp,
            });
            setOtp("");
            break;
        }
        const smart = smartWallet({
          factoryAddress: FACTORY_ADDRESS,
          gasless: true,
          chain: chain,
        });
        const smartAccount = await smart.connect({
          client,
          personalAccount: eoaAccount,
        });
        userContext?.manualConnect(
          userInAppWallet,
          eoaAccount,
          smartAccount,
          method,
          loginValue
        );
      } catch (error) {
        console.error(error);
        setLoggingIn(false);
      }
    },
    [loggingIn, client, chain, email, phoneNumber, otp, userContext]
  );

  const signIn = () => {
    debouncedSignIn();
  };

  async function _sendOTP() {
    try {
      await preAuthenticate({
        client,
        strategy: "phone",
        phoneNumber: phoneNumber,
      });
      setPhoneVerificationModal(true);
    } catch (error) {
      console.error(error);
      setLoggingIn(false);
    }
  }

  // Phone OTP Login
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    if (otp.length === 6) instantiateAccount(ThirdwebLoginMethod.PHONE);
  };

  // Function to toggle the state
  const handleToggle = () => {
    setIsEntering(!isEntering);
  };

  const icon = {
    hidden: {
      pathLength: 0,
      fill: "black",
    },
    visible: {
      pathLength: 1,
      fill: "black",
    },
  };

  // Extracted path data from your SVG
  const paths = [
    "M81.53,570.8c-27.06-51.1-41.36-108.84-41.36-166.99c0-86.99,31.63-170.82,89.05-236.04 C186.01,103.28,263.89,61.4,348.57,49.8c2.59,166.55,140.08,301.94,306.74,301.94c28.23,0,56.23-3.86,83.25-11.46 C734.99,429,673.08,486.2,543.79,520.24C424.56,551.63,257.95,560.95,81.53,570.8z",
    "M397.53,761.17c-62.18,0-123.41-16.21-177.07-46.89c-51.81-29.62-95.75-72.09-127.14-122.89 331.3-18.53,617.57-34.67,660.88-209.8c0.46,7.38,0.69,14.84,0.69,22.22c0,48.24-9.45,95.04-28.08,139.1 c-18,42.55-43.77,80.77-76.59,113.59c-32.82,32.82-71.04,58.59-113.59,76.59C492.58,751.72,445.78,761.17,397.53,761.17z",
  ];

  return (
    <>
      {!phoneVerificationModal ? (
        <div className="flex h-screen w-screen bg-white">
          <div className="flex items-center justify-center w-full transition-all">
            <div className="flex flex-col justify-between w-full h-full lg:p-10 p-7 transition-all">
              <div className="flex justify-between items-center">
                <div className="flex items-end space-x-1">
                  <motion.div
                    key={loggingIn ? "loggingIn" : "notLoggingIn"}
                    animate={loggingIn ? "animate" : "static"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 795.06 810.89"
                      className="w-12 h-12 lg:w-10 lg:h-10"
                    >
                      {paths.map((d, index) => (
                        <motion.path
                          key={index}
                          d={d}
                          variants={{
                            hidden: { pathLength: 0, fill: "rgba(0, 0, 0, 0)" },
                            visible: {
                              pathLength: 1,
                              fill: "rgba(0, 0, 0, 1)",
                            },
                            animate: {
                              pathLength: [0, 1, 0],
                              fill: [
                                "rgba(0, 0, 0, 0)",
                                "rgba(0, 0, 0, 1)",
                                "rgba(0, 0, 0, 0)",
                              ],
                            },
                          }}
                          initial="hidden"
                          animate={loggingIn ? "animate" : "visible"}
                          transition={{
                            duration: 1.5,
                            ease: "easeInOut",
                            repeat: loggingIn ? Infinity : 0,
                            repeatType: "loop",
                          }}
                          stroke={loggingIn ? "gray" : "black"}
                          strokeWidth="4"
                        />
                      ))}
                    </svg>
                  </motion.div>
                  <p className="text-4xl font-medium lg:flex hidden">Humanos</p>
                </div>
                <div
                  className="flex relative bg-neutral-100 rounded-full items-center hover:cursor-pointer select-none"
                  onClick={() => {
                    if (!loggingIn) handleToggle();
                  }}
                >
                  <div
                    className={`absolute  h-9  bg-white rounded-full shadow-md transition-all duration-500 ease-in-out ${
                      isEntering
                        ? "translate-x-0 w-[4.5rem]"
                        : "translate-x-[4.5rem] w-[6.6rem]"
                    }`}
                  ></div>

                  {/* Entrar Button */}
                  <div
                    className={`${
                      isEntering ? " text-teal-700 " : "text-neutral-500"
                    } rounded-full px-4 py-2 transition-all duration-300 z-50 text-sm`}
                  >
                    Entrar
                  </div>

                  {/* Criar Conta Button */}
                  <div
                    className={`${
                      !isEntering ? " text-teal-700 " : "text-neutral-500"
                    } rounded-full px-4 py-2 transition-all duration-300 z-50 text-sm`}
                  >
                    Criar Conta
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex pb-5 space-x-2 lg:flex">
                    <h1 className="text-4xl font-medium">
                      {isEntering ? "Entrar" : "Criar Conta"}
                    </h1>
                  </div>
                  <div className="flex flex-col w-full max-w-xs mb-1">
                    <Input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => {
                        setPhoneNumber("");
                        setEmail(e.target.value);
                      }}
                      className=""
                      value={email}
                      disabled={!userContext?.allowLogin || loggingIn}
                      //onClear={() => {
                      //  setEmail("");
                      //}}
                    />
                    <div className="w-full flex justify-center text-[0.7rem] uppercase text-nowrap items-center my-3 select-none">
                      ou
                    </div>
                    <PhoneInputLight
                      defaultCountry={"PT"}
                      onChange={(value) => {
                        setPhoneNumber(value);
                        setEmail("");
                      }}
                      value={phoneNumber}
                      disabled={!userContext?.allowLogin || loggingIn}
                    />
                    <Button
                      className="mt-3"
                      variant="default"
                      onClick={signIn}
                      disabled={!userContext?.allowLogin || loggingIn}
                    >
                      {isEntering ? "Iniciar Sessão" : "Criar Conta"}
                    </Button>

                    <div className="flex items-center justify-center space-x-2 mt-5">
                      <Separator className="bg-black w-24" />
                      <p className="text-xs uppercase text-nowrap select-none">
                        {isEntering ? "ou entrar com" : "ou criar com"}
                      </p>
                      <Separator className="bg-black w-24" />
                    </div>

                    <div className="flex mt-5 space-x-2 w-full">
                      <Button
                        className="w-full gap-1 bg-neutral-100 hover:bg-neutral-200 transition-all text-neutral-800"
                        onClick={() => {
                          instantiateAccount(ThirdwebLoginMethod.GOOGLE);
                        }}
                        disabled={!userContext?.allowLogin || loggingIn}
                      >
                        {<FcGoogle />} Google
                      </Button>
                      <Button
                        className="w-full gap-1 bg-neutral-100 hover:bg-neutral-200 transition-all text-neutral-800 "
                        disabled={!userContext?.allowLogin || loggingIn}
                        variant="destructive"
                      >
                        {<FaApple />} Apple
                      </Button>
                    </div>
                    <div className="flex w-full justify-center">
                      <p className="mt-4 text-xs font-light break-all text-center lg:max-w-[18.8rem] max-w-[18rem] text-neutral-500 ">
                        Ao clicar em continuar, você concorda com os <br />
                        nossos{" "}
                        <span className="underline hover:cursor-pointer text-black transition-all ">
                          Termos de Serviço
                        </span>{" "}
                        e{" "}
                        <span className="underline hover:cursor-pointer text-black transition-all">
                          Política de Privacidade
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="font-light text-sm">
                Humanos® All Rights Reserved, 2024{" "}
              </div>
            </div>
          </div>
          {/* Right Division */}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-screen h-screen bg-white">
          <form
            className="flex flex-col items-center justify-center space-y-4"
            onSubmit={handleSubmit} // Handle form submission
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 795.06 810.89"
              width="60"
              height="60"
            >
              {paths.map((d, index) => (
                <motion.path
                  key={index}
                  d={d}
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 1.5, ease: "circInOut" },
                    fill: { duration: 1.7, ease: [1, 0, 0.8, 1] },
                  }}
                  stroke="black"
                  fill="black"
                  strokeWidth="4"
                  style={{ fill: "black" }} // Ensure fill is applied directly
                />
              ))}
            </svg>
            <h1 className="text-2xl font-bold text-center mt-5 pb-3">
              Humanos OTP
            </h1>
            <InputOTP
              maxLength={6}
              onChange={setOtp}
              autoFocus // Optionally focus on the first input
            >
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            <span className="text-xs text-gray-400 mt-2">
              Insira o código de verificação.
            </span>
            <Button
              type="submit" // Change button type to submit
              className="mt-5"
              disabled={otp.length !== 6}
            >
              Confirmar
            </Button>
          </form>
          <span
            className="flex flex-inline cursor-pointer items-center fixed top-0 right-0 m-4 space-x-2 px-4 py-2 hover:bg-gray-100 rounded-lg transition-all duration-300"
            onClick={() => {
              setPhoneVerificationModal(false);
              setLoggingIn(false);
            }}
          >
            <HiArrowLongLeft className="w-6 h-6" />
            <span>Voltar</span>
          </span>
        </div>
      )}
    </>
  );
};

export default Login;
