import { CardDescription, CardTitle } from "../../../components/ui/card";

import { CardHeader } from "../../../components/ui/card";

import Loading from "../../../components/loading";
import { Button } from "../../../components/ui/button";
import { CardContent } from "../../../components/ui/card";
import { showToast } from "../../../components/toast";

interface Card2SignatureProps {
  setDialogIsOpen: (value: boolean) => void;
}

const Card2Signature: React.FC<Card2SignatureProps> = ({ setDialogIsOpen }) => {
  const handleButtonClick = () => {
    const timer = setTimeout(() => {
      setDialogIsOpen(false);
      showToast("Consentimentos assinados com sucesso", "success", 3000);
    }, 5000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <CardHeader>
        <CardTitle>Inserir Cartão de Cidadão</CardTitle>
        <CardDescription className="text-sm text-gray-800">
          Insira o cartão de cidadão do utente para assinar os consentimentos
          selecionados.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col justify-between">
        <div className="flex-grow flex items-center justify-center py-28">
          <Loading full={false} disableTimeout={true} />
        </div>
        <Button
          className="bg-teal-700 hover:bg-teal-900 w-full"
          onClick={handleButtonClick} // Updated to use the new handler
          disabled={false}
        >
          Ler Cartão
        </Button>
      </CardContent>
    </>
  );
};

export default Card2Signature;
