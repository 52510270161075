import React, { FC, ReactNode, useRef } from "react";
import { Card } from "./card";

interface Props {
  children: ReactNode;
  tooltip?: string;
}

const ToolTip: FC<Props> = ({ children, tooltip }): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left } = container.current.getBoundingClientRect();
        tooltipRef.current.style.left = clientX - left + "px";
        tooltipRef.current.style.top = "0%";
      }}
      className="group relative inline-block"
    >
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="invisible group-hover:visible transition absolute top-full whitespace-nowrap z-999"
        >
          <Card className="px-3 py-2">{tooltip}</Card>
        </span>
      ) : null}
    </div>
  );
};
export default ToolTip;
