export const consentAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_humanosUserPoolAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "ConsentNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "IndexOutOfBounds",
    type: "error",
  },
  {
    inputs: [],
    name: "MaxLengthReached",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "NotAHumanosUser",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "requester",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "wallet",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "startsAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "duration",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expiresAt",
        type: "uint256",
      },
    ],
    name: "ConsentGiven",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expiresAt",
        type: "uint256",
      },
    ],
    name: "EndConsent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "EndFutureConsent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "consentIsValid",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "consents",
    outputs: [
      {
        internalType: "uint256",
        name: "startsAt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expiresAt",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "usersIds",
        type: "bytes32[]",
      },
      {
        internalType: "bytes32[]",
        name: "hashes",
        type: "bytes32[]",
      },
      {
        internalType: "uint16[]",
        name: "indexes",
        type: "uint16[]",
      },
      {
        internalType: "uint256[]",
        name: "timestamps",
        type: "uint256[]",
      },
    ],
    name: "consentsValidaty",
    outputs: [
      {
        internalType: "bool[]",
        name: "",
        type: "bool[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "uint16",
        name: "index",
        type: "uint16",
      },
    ],
    name: "getConsentInstanceTimestamps",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startsAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct HumanosConsent.ConsentTimestamps",
        name: "consentTimestamps",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "getLastConsent",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startsAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct HumanosConsent.ConsentTimestamps",
        name: "consentTimestamps",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "usersIds",
        type: "bytes32[]",
      },
      {
        internalType: "bytes32[]",
        name: "hashes",
        type: "bytes32[]",
      },
      {
        internalType: "uint16[]",
        name: "indexes",
        type: "uint16[]",
      },
    ],
    name: "getMultipleConsentTimestamps",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startsAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct HumanosConsent.ConsentTimestamps[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "uint16",
        name: "index",
        type: "uint16",
      },
    ],
    name: "getMyConsentTimestamps",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startsAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct HumanosConsent.ConsentTimestamps",
        name: "consentTimestamps",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "userId",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "getUserConsentHistory",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startsAt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiresAt",
            type: "uint256",
          },
        ],
        internalType: "struct HumanosConsent.ConsentTimestamps[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "requester",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "startsAt",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "hoursToExpire",
        type: "uint32",
      },
    ],
    name: "giveConsent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "requester",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "hoursToExpire",
        type: "uint32",
      },
    ],
    name: "giveImeadiateConsent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "humanosUserPool",
    outputs: [
      {
        internalType: "contract HumanosUserPool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "revoke",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
