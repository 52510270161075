// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// Internal
import "../../app/App.css";
import { UserContext } from "../../services/user";
import { dateToBlockchain, timeConverter } from "../../utils/dateTimeUtils";
import {
  affiliationByName,
  consentBase,
  dataCategoryWithId,
  listOrganizationsConsentCard,
  listOrganizationsOrDepartments,
  listOrganizationsWithDetails,
  userByIndentity,
} from "../../types";
import Card0Patient from "./tabs/Card0Patient";
import Card2Categories from "./tabs/Card2Categories";
import Card3Time from "./tabs/Card3Time";
import Card1ShareWith from "./tabs/Card1ShareWith";
import showToast from "../../components/toast";
import Loading from "../../components/loading";
import Card4Confirmation from "./tabs/Card4Confirmation";
import { CCService } from "../../services/CC";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";
import ConsentProgressBar from "./ProgressBar";
import { equalObjects } from "../../utils/compareEntity";

// Add TypeScript types for props
interface ConsentCardProps {
  give: boolean;
  hash?: string;
  patient?: userByIndentity | null; // can be defined on patient list page, ...
  handleDialogClose: () => void;
  preSelectedUser?: userByIndentity;
}

const ConsentCard = ({
  give,
  hash,
  patient,
  handleDialogClose,
}: ConsentCardProps) => {
  // General
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const ccService = useMemo(() => new CCService(userContext.axiosInstance), []);
  // Dont think hash is being used for now
  const [step, setStep] = useState(hash ? 4 : patient || give ? 1 : 0); // Zero is ask and patient not chosen yet
  const minStep = useMemo(() => {
    if (hash) return 3;
    if (userContext.activeAffiliation) return 0;
    return 1;
  }, [hash, userContext.activeAffiliation]);

  const [maxStep, setMaxStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDisableTimeout, setIsLoadingDisableTimeout] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  // Card 0 (Procurar Utente)
  const [selectedUser, setSelectedUser] = useState<userByIndentity | null>(
    patient || null
  );

  // Card 1 (Partilhar com)
  const [organizations, setOrganizations] = useState<
    listOrganizationsConsentCard[] | null
  >(null);
  const [shareType, setShareType] = useState<string>("");
  const [selectedOrganization, setSelectedOrganization] =
    useState<listOrganizationsConsentCard | null>(null);
  const [departments, setDepartments] = useState<
    listOrganizationsOrDepartments[]
  >([]);
  const [selectedDepartments, setSelectedDepartments] = useState<
    listOrganizationsOrDepartments[]
  >([]);
  const [professionals, setProfessionals] = useState<affiliationByName[]>([]);
  const [selectedAffiliations, setSelectedAffiliations] = useState<
    affiliationByName[]
  >([]);
  // Card 2 (Categorias)
  const [categories, setCategories] = useState<dataCategoryWithId[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    dataCategoryWithId[]
  >([]);

  // Card 3 (Tempo)
  const [startDate, setStartDate] = useState<undefined | Date>(undefined);
  const [duration, setDuration] = useState(
    userContext.activeAffiliation ? 41 : 7
  ); // HARDCODED (one week default)
  const actualDuration = useMemo(() => {
    return timeConverter(duration);
  }, [duration]);

  // Card 4 (Confirmar)
  const [awaitingBlockchain, setAwaitingBlockchain] = useState<boolean>(false);
  const [consent, setConsent] = useState<consentBase>({
    sharingWithAffiliationsIds: [],
    sharingWithDepartmentsIds: [],
    sharingWithOrganizationId: "",
    categoriesIds: [],
  });
  const [blockchainTimestamps, setBlockchainTimestamps] = useState<Date[]>([]);

  // Steps
  const unfinishedStep1 = useMemo(() => {
    if (maxStep <= 1) return false; // Not set can be incomplete due to change
    if (shareType === "" || !selectedOrganization) return true;
    if (shareType === "organization") return false; // complete
    // Departments, affiliations
    return (
      // Since the logic only fills one
      selectedDepartments.length === 0 && selectedAffiliations.length === 0
    );
  }, [
    shareType,
    selectedOrganization,
    selectedDepartments,
    selectedAffiliations,
  ]);
  const unfinishedStep2 = useMemo(
    () => selectedCategories.length === 0 && maxStep > 2,
    [selectedCategories]
  );

  useEffect(() => {
    if (step > maxStep) {
      setMaxStep(step);
      if (step === 1) fetchOrganizations();
      if (step === 2) fetchCategories();
    }
  }, [step]);

  useEffect(() => {
    if (selectedUser && userContext.activeAffiliation)
      setSelectedOrganization({
        id: userContext.activeAffiliation.id,
        name: userContext.activeAffiliation.name,
      });
  }, []);

  const _askConsentBackend = useCallback(async () => {
    const response = await userContext.axiosInstance.post(
      `consent/ask-consent/`,
      {
        ...consent,
        startsAt: startDate ? startDate : undefined,
        duration: timeConverter(duration),
        askedId: selectedUser?.id || "",
      }
    );
    return response;
  }, [userContext, consent, startDate, duration, selectedUser]);

  const askConsentBackend = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await _askConsentBackend();
      if (response) {
        showToast("Pedido de acesso enviado", "success", 3000);
      }
    } catch (error: any) {
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
      ) {
        showToast(error.response.data.message, "error", 3000);
      } else {
        showToast("Erro ao pedir acesso", "error", 3000);
      }
    } finally {
      setIsLoading(false);
    }
  }, [_askConsentBackend]);

  const giveConsentBlockchain = useCallback(async () => {
    setIsLoading(true);
    setLoadingText("A processar o seu consentimento");
    try {
      const response = await userContext.axiosInstance.post(
        "consent/give-consent/",
        consent
      );
      if (response) {
        await userContext.blockchain.giveConsent({
          userId: userContext.user.id,
          duration: timeConverter(duration),
          consent: consent,
          ...(startDate ? { startsAt: dateToBlockchain(startDate) } : {}),
        });
        showToast("Consentimento submetido com sucesso", "success", 3000);
        handleDialogClose();
      }
    } catch (error) {
      showToast("Erro ao dar consentimento", "error", 3000);
      console.error("Error giving consent:", error);
    } finally {
      setIsLoading(false);
      setLoadingText("");
    }
  }, [userContext, consent, duration, startDate, handleDialogClose]);

  const giveConsentCC = useCallback(async () => {
    if (!selectedUser) return;
    setIsLoadingDisableTimeout(true);
    setIsLoading(true);
    setLoadingText("A processar o seu consentimento e Cartão de Cidadão");
    try {
      await _askConsentBackend();
      await ccService.connectUser(false);
      await ccService.connectUser(false);
      await ccService.giveConsent({
        userId: selectedUser.id,
        requester: userContext.user.smartWallet,
        duration: timeConverter(duration),
        consent: consent,
        ...(startDate ? { startsAt: dateToBlockchain(startDate) } : {}),
      });
      showToast("Consentimento submetido com sucesso", "success", 3000);
      handleDialogClose();
    } catch (error) {
      console.error("Error giving consent:", error);
    } finally {
      setIsLoadingDisableTimeout(false);
      setIsLoading(false);
      setLoadingText("");
    }
  }, [
    selectedUser,
    _askConsentBackend,
    ccService,
    userContext,
    duration,
    consent,
    startDate,
    handleDialogClose,
  ]);

  // Step 1
  const fetchOrganizations = async () => {
    try {
      const response = await userContext.axiosInstance.get(
        `organizations/list`
      );
      setOrganizations(
        response.data.map((org: listOrganizationsWithDetails) => ({
          id: org.id,
          name: org.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };
  useEffect(() => {
    if (!selectedOrganization || shareType !== "department") return;
    const fetchDepartments = async () => {
      if (!selectedOrganization) return;
      setDepartments([]);
      try {
        const response = await userContext.axiosInstance.get(
          `organizations/departments/${selectedOrganization.id}`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
        setShareType("");
        setSelectedOrganization(null);
      }
    };
    fetchDepartments();
  }, [selectedOrganization]);

  // Step 2
  const fetchCategories = async () => {
    try {
      const response = await userContext.axiosInstance.get("category/list");
      const _categories = response.data;
      setCategories(_categories);
      setSelectedCategories(_categories); // start all selected
    } catch (error) {
      console.error("Error fetching data categories:", error);
      handleDialogClose();
    }
  }; // No need to use useCallback

  // Step 4
  const getBlockchainTimestamps = useCallback(
    async (_consent: consentBase) => {
      setAwaitingBlockchain(true);
      const _timestamps = await userContext.blockchain.getLastConsent({
        userId: patient ? patient.id : userContext.user.id,
        consent: _consent,
        hash: hash,
      });
      setBlockchainTimestamps(_timestamps);
      setAwaitingBlockchain(false);
    },
    [patient, userContext.blockchain, hash]
  );
  const handleUpdateBlockchainTimestamps = useCallback(async () => {
    if (!unfinishedStep1 && !unfinishedStep2 && !awaitingBlockchain) {
      const _consent = {
        sharingWithAffiliationsIds: selectedAffiliations.map((prof) => prof.id),
        sharingWithDepartmentsIds: selectedDepartments.map((dep) => dep.id),
        sharingWithOrganizationId: selectedOrganization?.id || "",
        categoriesIds: selectedCategories.map((cat) => cat.id) || [],
      };
      console.log(
        "consent",
        consent,
        "_consent",
        _consent,
        "equal",
        equalObjects(consent, _consent)
      );
      if (!equalObjects(consent, _consent)) {
        setConsent(_consent);
        console.log("Calculating timestamp");
        await getBlockchainTimestamps(_consent);
      }
    }
    setStep(4);
  }, [
    unfinishedStep1,
    unfinishedStep2,
    awaitingBlockchain,
    selectedAffiliations,
    selectedDepartments,
    selectedOrganization,
    selectedCategories,
    consent,
    getBlockchainTimestamps,
    setConsent,
    setStep,
  ]);

  const setStepAccountAwaitBlockchain = useCallback(
    (_step: number) => {
      if (_step === 4) handleUpdateBlockchainTimestamps();
      else setStep(_step);
    },
    [handleUpdateBlockchainTimestamps, setStep]
  );

  const prevStep = useCallback(() => {
    if (step > minStep) {
      setStep(step - 1);
    }
  }, [step, minStep, setStep]);

  const nextStep = useCallback(() => {
    if (step === 3) {
      handleUpdateBlockchainTimestamps();
    } else if (step < 3) {
      setStep(step + 1);
    }
  }, [step, handleUpdateBlockchainTimestamps, setStep]);

  const prevButton = (
    <button
      type="button"
      className="bg-teal-700 hover:bg-teal-900 text-white font-bold py-2 px-4 rounded"
      onClick={() => {
        prevStep();
      }}
      disabled={false}
    >
      Anterior
    </button>
  );
  return (
    <>
      {isLoading && (
        <div className="py-32">
          <Loading
            full={false}
            disableTimeout={isLoadingDisableTimeout}
            timeout={30000}
            children={<span className="font-md">{loadingText}</span>}
          />
        </div>
      )}
      {!isLoading && (
        <div className={`flex flex-col gap-2 ${step === 4 ? "" : "h-[550px]"}`}>
          <ConsentProgressBar
            affiliation={!!userContext.activeAffiliation}
            maxStep={maxStep}
            currentStep={step}
            setStepAccountAwaitBlockchain={setStepAccountAwaitBlockchain}
            unfinishedStep1={unfinishedStep1}
            unfinishedStep2={unfinishedStep2}
          />
          {step === 0 && (
            <Card0Patient
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              nextStep={nextStep}
            />
          )}
          {step === 1 && (selectedUser || give) && organizations && (
            <Card1ShareWith
              shareType={shareType}
              setShareType={setShareType}
              organizations={organizations}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              departments={departments}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
              professionals={professionals}
              setProfessionals={setProfessionals}
              selectedAffiliations={selectedAffiliations}
              setSelectedAffiliations={setSelectedAffiliations}
              prevButton={minStep > 1 ? prevButton : <div></div>}
              nextStep={nextStep}
            />
          )}
          {step === 2 && categories && (
            <Card2Categories
              categories={categories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
          {step === 3 && (
            <div>
              <Card3Time
                startDate={startDate}
                setStartDate={setStartDate}
                duration={duration}
                setDuration={setDuration}
                prevStep={prevStep}
                nextStep={nextStep}
              />
            </div>
          )}
          {step === 4 && duration > 0 && (
            <Card4Confirmation
              userSharing={
                selectedUser
                  ? selectedUser.name
                  : userContext.user.profile.name || ""
              }
              userAsking={userContext.activeAffiliation?.name || undefined}
              organizationName={selectedOrganization?.name || ""}
              affiliations={selectedAffiliations}
              departmentNames={selectedDepartments.map(
                (department) => department.name
              )}
              categories={selectedCategories}
              startsAt={startDate}
              duration={actualDuration}
              inPerson={
                userContext.activeAffiliation ? giveConsentCC : undefined
              }
              inApp={
                userContext.activeAffiliation
                  ? askConsentBackend
                  : giveConsentBlockchain
              }
              blockchainTimestamps={blockchainTimestamps}
              unfinishedStep1={unfinishedStep1}
              unfinishedStep2={unfinishedStep2}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ConsentCard;
