import React, { useContext, useEffect, useMemo, useState } from "react";
import Loading from "../../../components/loading";
import { CCService } from "../../../services/CC";
import { UserContext } from "../../../services/user";
import showToast from "../../../components/toast";
import {
  CardDescription,
  CardTitle,
  CardContent,
  CardHeader,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";

interface UpdateCCProps {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCC = ({ setIsDialogOpen }: UpdateCCProps) => {
  // Context and CC service
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const ccService = useMemo(() => new CCService(userContext.axiosInstance), []);

  // Steps
  const [waiting, setWaiting] = useState(false);
  const [payloadExpiresAt, setPayloadExpiresAt] = useState<Date | null>(null);

  useEffect(() => {
    if (payloadExpiresAt && new Date() > payloadExpiresAt) {
      if (waiting) return;
      showToast("Sessão para criar novo utilizador expirou", "error", 3000);
      setIsDialogOpen(false);
    }
  }, [waiting]);

  async function updateUser() {
    setWaiting(true);
    try {
      //console.log("Connecting User...");
      await ccService.updateUser();
      const _payloadExpiresAt = ccService.payloadExpiresAt;
      setPayloadExpiresAt(_payloadExpiresAt);
      if (_payloadExpiresAt) {
        const timer = _payloadExpiresAt.getTime() - new Date().getTime();
        setTimeout(() => {
          if (waiting) return; // Executes in the useEffect then
          showToast(
            "Sessão para atualizar informação do utilizador expirou",
            "error",
            3000
          );
          setIsDialogOpen(false);
        }, timer);
      }
      setIsDialogOpen(false);
      return;
    } catch (error: any) {
      console.error("Error reading CC data", error);
      const status = error.response?.status;
      if (status && status === 409) {
        //Cartão de Cidadão já associado a um utilizador
        setIsDialogOpen(false);
      }
    } finally {
      setWaiting(false);
    }
  }
  return (
    <div className={`flex flex-col gap-2 h-[500px]`}>
      <CardHeader>
        <CardTitle>Atualizar informação do Cartão de Cidadão</CardTitle>
        <CardDescription className="text-sm text-gray-800">
          Quando o cartao de cidadão estiver inserido no leitor, clique em Ler
          Cartão
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col justify-between h-full">
        <div className="flex-grow flex items-center justify-center">
          <Loading full={false} disableTimeout={true} />
        </div>
        <Button
          className="bg-teal-700 hover:bg-teal-900 w-full"
          onClick={updateUser}
          disabled={waiting}
        >
          Ler Cartão
        </Button>
      </CardContent>
    </div>
  );
};

export default UpdateCC;
