// React
import { createContext, useState, useEffect, ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// Thirdweb
import { Account, Wallet, inAppWallet, smartWallet } from "thirdweb/wallets";
import { createThirdwebClient, defineChain } from "thirdweb";
import { baseSepolia } from "thirdweb/chains";

// External
import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

// Internal
import {
  ThirdwebLoginMethod,
  user,
  userAffiliation,
  LoginOptionalParams,
} from "../types";
import {
  CLIENT_ID,
  COOKIE_NAME,
  FACTORY_ADDRESS,
  BACKEND_URL,
  CACHE_COOKIE_OPTIONS,
} from "../environment/variables";
import { login } from "../auth/auth";
import { Blockchain } from "./Blockchain";
import showToast from "../components/toast";

type UserProviderProps = {
  children: ReactNode;
};

export type UserContextType = {
  eoaAccount: Account | null;
  smartAccount: Account | null;
  user: user | null;
  setUser: (user: user | null) => void; // update user
  allowLogin: boolean;
  loggingIn: boolean;
  manualConnect: (
    _userInAppWallet: Wallet<"inApp">,
    _eoaAccount: Account,
    _smartAccount: Account,
    _loginMethod: ThirdwebLoginMethod,
    _loginValue: string
  ) => void;
  logout: (logoutCall?: boolean) => Promise<void>;
  axiosInstance: AxiosInstance;
  activeCountryAbbv: string | undefined;
  updateActiveCountryAbbv: (value: string) => void;
  activeAffiliation: undefined | userAffiliation;
  updateActiveAffiliation: (value: undefined | userAffiliation) => void;
  blockchain: Blockchain;
} | null;

const UserContext = createContext<UserContextType>(null);
const UserProvider = ({ children }: UserProviderProps) => {
  // Hooks
  const navigate = useNavigate();

  // SESSION (unchanged) Variables
  const client = useMemo(
    () => createThirdwebClient({ clientId: CLIENT_ID }),
    []
  );
  const chain = useMemo(() => defineChain(baseSepolia), []);
  const blockchain = useMemo(
    () => new Blockchain(client, chain),
    [client, chain]
  );

  const [userInAppWallet, setUserInAppWallet] =
    useState<Wallet<"inApp"> | null>(null);
  const [eoaAccount, setEoaAccount] = useState<Account | null>(null); // Refresh Tokens
  const [smartAccount, setSmartAccount] = useState<Account>({} as Account); // Consent Transactions

  // State variables - User
  const [user, setUser] = useState<user | null>(null);

  const [allowLogin, setAllowLogin] = useState<boolean>(false);
  const [loginAttempt, setLogginAttempt] = useState<number>(0);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  // Login Other Optional Params
  const [loginMethod, setLoginMethod] = useState<
    ThirdwebLoginMethod | undefined
  >(undefined);
  const [loginValue, setLoginValue] = useState<string>("");

  const [activeCountryAbbv, setActiveCountryAbbv] = useState<
    string | undefined
  >(Cookies.get("activeCountryAbbv"));
  const [activeAffiliation, setActiveAffiliation] = useState<
    undefined | userAffiliation
  >(undefined);

  // Cant use useMemo so it updates cookies and xsrf token
  const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    timeout: 3000, // 3 seconds
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
    },
    withCredentials: true,
    //xsrfCookieName: "XSRF-TOKEN",
    //xsrfHeaderName: "X-XSRF-TOKEN",
    //withXSRFToken: true,
  });

  // Request interceptor to add a timestamp parameter
  axiosInstance.interceptors.request.use(
    async function (config) {
      try {
        if (!eoaAccount) return Promise.reject("Erro");
        if (!Cookies.get(COOKIE_NAME)) {
          // Refresh Token if cookie expired
          const user = await login(eoaAccount);
          if (!user) {
            await logout();
            return Promise.reject("Erro");
          }
        }
        const timestamp = new Date().getTime();
        config.params = {
          ...config.params,
          _timestamp: timestamp,
        };
        return config;
      } catch (error) {
        return Promise.reject("Erro");
      }
    },
    function (error) {
      // Handle the error
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (eoaAccount) blockchain.updateAccounts(eoaAccount, smartAccount);
  }, [smartAccount]);

  // Allow login --> Landing page loading - ready
  // User -> login - logged in
  useEffect(() => {
    // This runs at start or when the email is provived if on login page
    (async () => {
      console.log("useEffect");
      let _eoaAccount = eoaAccount;
      if (!_eoaAccount) {
        _eoaAccount = await _autoConnect();
        console.log("_eoaAccount", _eoaAccount);
        if (!_eoaAccount) {
          setAllowLogin(true);
          navigate("/");
          return;
        }
      }
      // Token - USER
      const debbug = user
        ? "user"
        : Cookies.get(COOKIE_NAME)
        ? "whoami"
        : "login";
      console.log("debbug ", debbug);
      const _user = user
        ? user
        : Cookies.get(COOKIE_NAME)
        ? await _whoAmI()
        : await _login(_eoaAccount);
      if (!_user || (eoaAccount && _user.wallet !== eoaAccount.address)) {
        await logout();
        return;
      }
      handleAffiliation(_user);
      setUser(_user);
      console.log("user", _user);
      console.log(
        `use effect end ->
          allowLogin: ${allowLogin},
          user: ${_user?.id}
          cookie: ${!!Cookies.get(COOKIE_NAME)}
          loginValue: ${loginValue}`
      );
    })();
  }, [loginAttempt]); // Login attempt on login page

  async function _login(_eoaAccount: Account): Promise<user | null> {
    console.log("login");
    setLoggingIn(true);
    let loginOptionalParams: LoginOptionalParams = {};
    if (loginValue)
      // manual login - login page
      loginOptionalParams = {
        smartWallet: smartAccount.address,
        loginMethod,
        loginValue,
      };
    const res = await login(_eoaAccount, loginOptionalParams);
    setLoggingIn(false); // Allows to show loading on login page
    console.log("login end");
    return res;
  }

  const _autoConnect = async () => {
    try {
      const _inAppWallet = inAppWallet();
      const _eoaAccount = await _inAppWallet.autoConnect({ client, chain });
      setEoaAccount(_eoaAccount);
      const smart = smartWallet({
        factoryAddress: FACTORY_ADDRESS,
        gasless: true,
        chain: chain,
      });
      setSmartAccount(
        await smart.connect({
          client,
          personalAccount: _eoaAccount,
        })
      );
      setUserInAppWallet(_inAppWallet);
      return _eoaAccount;
    } catch {
      return null;
    }
  };

  const handleAffiliation = (_user: user) => {
    // Affiliation
    const activeAffiliationId = Cookies.get("activeAffiliation");
    if (activeAffiliationId)
      // Extend Cookie
      Cookies.set(
        "activeAffiliation",
        activeAffiliationId,
        CACHE_COOKIE_OPTIONS
      );
    if (activeCountryAbbv)
      // Extend Cookie
      Cookies.set("activeCountryAbbv", activeCountryAbbv, CACHE_COOKIE_OPTIONS);
    // Affiliation
    if (!activeCountryAbbv) {
      const _activeCountryAbbv =
        _user.identities.length > 0 ? _user.identities[0].countryAbbv : "PRT"; // Default to Portugal
      setActiveCountryAbbv(_activeCountryAbbv);
      Cookies.set(
        "activeCountryAbbv",
        _activeCountryAbbv,
        CACHE_COOKIE_OPTIONS
      );
    }
    if (_user.affiliations.length > 0 && activeAffiliationId) {
      const aff = _user.affiliations.find(
        (affiliation) => affiliation.id === activeAffiliationId
      );
      if (!aff) Cookies.remove("activeAffiliation"); // not found
      else setActiveAffiliation(aff);
    }
  };

  const manualConnect = (
    _userInAppWallet: Wallet<"inApp">,
    _eoaAccount: Account,
    _smartAccount: Account,
    _loginMethod: ThirdwebLoginMethod,
    _loginValue: string
  ) => {
    setUserInAppWallet(_userInAppWallet);
    setEoaAccount(_eoaAccount);
    setSmartAccount(_smartAccount);
    setLoginMethod(_loginMethod);
    setLoginValue(_loginValue);
    setLogginAttempt(loginAttempt + 1); // will auto rerun the useEffect
  };

  // Called when user changes active country. Gets the user info for the new country
  async function _whoAmI(): Promise<user | null> {
    try {
      return (await axios.get("/users", { withCredentials: true })).data;
    } catch (error) {
      console.error("Erro ao fazer whoami", error);
      return null;
    }
  }

  function updateActiveCountryAbbv(_activeCountryAbbv: string) {
    setActiveCountryAbbv(_activeCountryAbbv);
    Cookies.set("activeCountryAbbv", _activeCountryAbbv, CACHE_COOKIE_OPTIONS);
  }

  function updateActiveAffiliation(
    _activeAffiliation: userAffiliation | undefined
  ) {
    if (!_activeAffiliation) {
      // Patient
      setActiveAffiliation(undefined);
      Cookies.remove("activeAffiliation");
    } else {
      setActiveAffiliation(_activeAffiliation);
      Cookies.set(
        "activeAffiliation",
        _activeAffiliation.id,
        CACHE_COOKIE_OPTIONS
      );
    }
    return;
  }

  async function logout() {
    try {
      if (Cookies.get(COOKIE_NAME)) {
        try {
          await axiosInstance.post("/auth/logOut", {});
        } catch {} // Proceed with rest of logout (could cause auto connect to force connect )
      }
      // Remove all cookies
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        Cookies.remove(name);
      }
      // Remove all local storage
      localStorage.clear();
      sessionStorage.clear();

      // Remove cookie
      Cookies.remove(COOKIE_NAME);

      // Logout from thirdweb ()
      if (userInAppWallet) await userInAppWallet.disconnect(); // not working

      // Reset user states
      setUser(null);
      setAllowLogin(true);

      // Reset thirdweb states
      setUserInAppWallet(null);
      setEoaAccount(null);
      setSmartAccount({} as Account);

      // Reset Session Cache
      setActiveAffiliation(undefined);
      setActiveCountryAbbv(undefined);
      //Cookies.remove("activeCountryAbbv");
      //Cookies.remove("activeAffiliation");

      navigate("/");
    } catch (error) {
      console.error("Erro ao fazer logout", error);
      showToast("Erro ao fazer logout", "error", 3000);
    }
  }

  return (
    <UserContext.Provider
      value={{
        eoaAccount,
        smartAccount,
        user,
        setUser,
        allowLogin,
        loggingIn,
        manualConnect,
        logout,
        axiosInstance,
        activeCountryAbbv,
        updateActiveCountryAbbv,
        activeAffiliation,
        updateActiveAffiliation,
        blockchain,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserContext, UserProvider };
