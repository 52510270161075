// React
import React, { memo, useEffect, useState } from "react";

// Internal
import { timeDisplayConverter } from "../../../utils/dateTimeUtils";
import showToast from "../../../components/toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { format } from "date-fns";
import { Button } from "../../../components/ui/button";
import { Slider } from "../../../components/ui/slider";
import { CalendarIcon } from "lucide-react";
import {
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { CardContent } from "@mui/material";

// Add TypeScript types for props
interface Card3TimeProps {
  startDate: Date | undefined;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  prevStep: () => void;
  nextStep: () => void;
}

const Card3Time = ({
  startDate,
  setStartDate,
  duration,
  setDuration,
  prevStep,
  nextStep,
}: Card3TimeProps) => {
  // State
  const [displayStartDate, setDisplayStartDate] = useState<Date | undefined>(
    undefined
  );
  // Toggles
  const [dateNow, setDateNow] = useState(true); // start now

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      console.log("e.ctrlKey", e);
      if (e.code === "Enter") {
        nextStep();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const toggleDateNow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateNow(event.target.checked);
  };

  function isStartDateValid() {
    if (!startDate) return true;
    if (startDate > new Date(Date.now() + 5 * 60 * 1000)) return true;
    showToast("Data tem de ser no futuro.", "error", 3000);
    return false;
  }

  const handleSliderChange = (value: number[]) => {
    if (setDuration) {
      setDuration(value[0] || 1);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div>
        <CardHeader>
          <CardTitle>Duração</CardTitle>
          <CardDescription>
            Selecione a duração e data de início de consentimento
          </CardDescription>
        </CardHeader>
        <CardContent className="h-[350px]">
          <div className="my-8">
            <div className="flex justify-between">
              <p className="text-sm text-gray-600 font-semibold ml-1">
                Duração
              </p>
              <p>{timeDisplayConverter(Number(duration))}</p>
            </div>
            <Slider
              className="mt-2"
              value={[duration || 2]}
              defaultValue={[duration || 2]}
              max={42}
              min={2}
              step={1}
              onValueChange={handleSliderChange}
            />
            <div className="flex justify-between py-5 mt-20">
              {!dateNow && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={`w-[240px] pl-3 text-left font-normal ${
                        !startDate ? "text-muted-foreground" : ""
                      }`}
                    >
                      {startDate ? (
                        format(startDate, "PPP")
                      ) : (
                        <span>Escolher a data</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <input
                      type="datetime-local"
                      value={
                        displayStartDate
                          ? displayStartDate.toISOString().substring(0, 16)
                          : ""
                      }
                      min={new Date(Date.now() + 60 * 60 * 1000)
                        .toISOString()
                        .substring(0, 16)}
                      onChange={(e) => {
                        const utcDate = new Date(e.target.value);
                        const localDate = new Date(
                          utcDate.getTime() -
                            utcDate.getTimezoneOffset() * 60000
                        );
                        setDisplayStartDate(localDate);
                        setStartDate(utcDate);
                      }}
                      disabled={dateNow}
                    />
                  </PopoverContent>
                </Popover>
              )}
              <label className="inline-flex items-center cursor-pointer my-2 ml-auto">
                <span className="ms-3 text-sm text-gray-600 mr-1">
                  Começar agora
                </span>
                <input
                  checked={dateNow}
                  onChange={toggleDateNow}
                  type="checkbox"
                  value=""
                  className="sr-only peer ml-2"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
          </div>
        </CardContent>
        <CardFooter className="mt-auto flex justify-between">
          <>
            <Button
              className="bg-teal-700 hover:bg-teal-900"
              onClick={() => {
                prevStep();
                setDuration(30);
                setStartDate(undefined);
              }}
              disabled={false}
            >
              Anterior
            </Button>
            <Button
              className="bg-teal-700 hover:bg-teal-900"
              onClick={() => {
                if (isStartDateValid()) nextStep();
              }}
              disabled={false}
            >
              Próximo
            </Button>
          </>
        </CardFooter>
      </div>
    </div>
  );
};

export default memo(Card3Time);
