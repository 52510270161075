import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import Loading from "../../components/loading";
import { UserContext } from "../../services/user";
import { PiShareNetworkLight } from "react-icons/pi";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { TbBuildingHospital } from "react-icons/tb";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import { useNavigate } from "react-router-dom";

import {
  sharingStats,
  sharingUser,
  SharingType,
  sharingUsersQuery,
  dataCategoryWithId,
  userByIndentity,
  sharingUsers,
  sharingUsersParams,
} from "../../types";
import { MdOutlineMedicalInformation } from "react-icons/md";
import { Input } from "../../components/ui/input";
import showToast from "../../components/toast";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";
import ToolTip from "../../components/ui/tooltip";
import Card0Patient from "../consents/tabs/Card0Patient";
import ConsentCard from "../consents/ConsentCard";

import {
  PAGE_SIZE,
  PARAMS_DEBOUNCE_TIME,
  SEARCH_DEBOUNCE_TIME,
} from "../../environment/variables";
import { useDebounceSearch } from "../../utils/debounceSearch";
import { IoShareSocialOutline } from "react-icons/io5";
import InternalConsentsCard from "../internalConsents/InternalconsentCard";
import CreateCC from "../consents/CC/create";
import { Button } from "../../components/ui/button";
import { TiDocumentText } from "react-icons/ti";

const dummyPatients: sharingUser[] = [
  {
    id: "1",
    name: "João Silva",
    fullName: "João Silva",
    birth: "15/03/1980",
    docId: "1234567890",
    categories: [
      { name: "Exames", color: "FFE5B4", id: "1" },
      { name: "Análises", color: "E6E6FA", id: "1" },
    ],
  },
  {
    id: "2",
    name: "Maria Santos",
    fullName: "Maria Santos",
    birth: "22/07/1992",
    categories: [
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
    ],
    docId: "1234567890",
  },
  {
    id: "3",
    name: "António Ferreira",
    fullName: "António Ferreira",
    birth: "10/11/1975",
    docId: "1234567890",
    categories: [
      { name: "Prescrições", color: "FFD1DC", id: "1" },
      { name: "Exames", color: "FFE5B4", id: "1" },
    ],
  },
  {
    id: "4",
    name: "Ana Rodrigues",
    fullName: "Ana Rodrigues",
    birth: "05/09/1988",
    docId: "1234567890",
    categories: [
      { name: "Análises", color: "E6E6FA", id: "1" },
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
    ],
  },
  {
    id: "5",
    name: "Manuel Costa",
    fullName: "Manuel Costa",
    birth: "30/12/1970",
    docId: "1234567890",
    categories: [{ name: "Exames", color: "FFE5B4", id: "1" }],
  },
  {
    id: "6",
    name: "Carla Oliveira",
    fullName: "Carla Oliveira",
    birth: "18/04/1985",
    docId: "1234567890",
    categories: [{ name: "Prescrições", color: "FFD1DC", id: "1" }],
  },
  {
    id: "7",
    name: "Rui Martins",
    fullName: "Rui Martins",
    docId: "1234567890",
    birth: "25/06/1978",
    categories: [
      { name: "Análises", color: "E6E6FA", id: "1" },
      { name: "Exames", color: "FFE5B4", id: "1" },
    ],
  },
  {
    id: "8",
    name: "Sofia Almeida",
    fullName: "Sofia Almeida",
    docId: "1234567890",
    birth: "14/02/1990",
    categories: [
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
      { name: "Prescrições", color: "FFD1DC", id: "1" },
    ],
  },
  {
    id: "9",
    name: "Pedro Sousa",
    fullName: "Pedro Sousa",
    docId: "1234567890",
    birth: "07/08/1982",
    categories: [
      { name: "Exames", color: "FFE5B4", id: "1" },
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
    ],
  },
  {
    id: "10",
    name: "Inês Fernandes",
    fullName: "Inês Fernandes",
    birth: "20/01/1995",
    categories: [{ name: "Análises", color: "E6E6FA", id: "1" }],
    docId: "1234567890",
  },
  {
    id: "11",
    name: "António Silva",
    fullName: "António Silva",
    docId: "1234567890",
    birth: "15/03/1988",
    categories: [
      { name: "Prescrições", color: "FFD1DC", id: "1" },
      { name: "Exames", color: "FFE5B4", id: "1" },
    ],
  },
  {
    id: "12",
    name: "Mariana Santos",
    fullName: "Mariana Santos",
    docId: "1234567890",
    birth: "22/09/1992",
    categories: [
      { name: "Análises", color: "E6E6FA", id: "1" },
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
    ],
  },
  {
    id: "13",
    name: "João Ferreira",
    fullName: "João Ferreira",
    docId: "1234567890",
    birth: "10/11/1975",
    categories: [
      { name: "Exames", color: "FFE5B4", id: "1" },
      { name: "Prescrições", color: "FFD1DC", id: "1" },
    ],
  },
  {
    id: "14",
    name: "Ana Rodrigues",
    fullName: "Ana Rodrigues",
    docId: "1234567890",
    birth: "05/06/1980",
    categories: [
      { name: "Análises", color: "E6E6FA", id: "1" },
      {
        name: "Registos Clínicos",
        color: "D5F5E3",
        id: "1",
      },
    ],
  },
  {
    id: "15",
    name: "Miguel Costa",
    fullName: "Miguel Costa",
    docId: "1234567890",
    birth: "18/12/1987",
    categories: [
      { name: "Exames", color: "FFE5B4", id: "1" },
      { name: "Prescrições", color: "FFD1DC", id: "1" },
    ],
  },
];

const PatientList = () => {
  // Context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const navigate = useNavigate();

  const [utentes, setUtentes] = useState<sharingUser[]>([]);
  const [stats, setStats] = useState<sharingStats | undefined>();
  const [maxPages, setMaxPages] = useState<number>(1);

  //SharingType
  const [params, setParams] = useState<sharingUsersParams>({
    page: 1,
    search: "",
    sharingType: undefined,
  });

  // Input variables
  const [selectedUser, setSelectedUser] = useState<userByIndentity | null>(
    null
  );

  // toggles
  const [isSharingConsent, setIsSharingConsent] = useState<boolean | null>(
    null
  );
  const [isSharingDialogOpen, setIsSharingDialogOpen] = useState(false);
  const [isInternalDialogOpen, setIsInternalDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [inPageLoading, setInPageLoading] = useState(false);

  // filtered patients

  const handleSharingDialogClose = useCallback(() => {
    setIsSharingDialogOpen(false);
  }, [setIsSharingDialogOpen]);

  const fetchUtentes = useCallback(async () => {
    setInPageLoading(true);
    try {
      const withStats = !stats;
      let query: sharingUsersQuery = {
        withStats: withStats,
        page: params.page,
      };
      if (params.sharingType) query.sharingType = params.sharingType;
      if (params.search) query.search = params.search;
      //if (params.docId) query.docId =  params.docId;
      const { data } = await userContext.axiosInstance.get<sharingUsers>(
        `users/sharingUsers`,
        {
          withCredentials: true,
          params: query,
        }
      );
      setUtentes(data.users);
      setMaxPages(Math.max(1, Math.ceil(data.length / PAGE_SIZE)));
      if (withStats) setStats(data.sharingStats);
    } catch (error: any) {
      console.error("fetchUtentes error", error);
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao procurar utentes a partilhar", "error", 3000);
    } finally {
      setInPageLoading(false);
    }
    // eslint-disable-next-line
  }, [params, userContext.activeAffiliation]);

  // Runs on start, and every time fetchUtentes dependency changes
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchUtentes();
    }, PARAMS_DEBOUNCE_TIME);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchUtentes]);

  function filterPatients(searchString: string) {
    if (!utentes) return [];
    const dummyPatientsFilter = [
      ...dummyPatients,
      ...utentes,
      ...dummyPatients,
    ];
    const normalizedSearchString = searchString.toLowerCase();

    return dummyPatientsFilter.filter((patient) => {
      // Normalize and check if searchString is in sharingName
      const nameMatch = patient.fullName
        ?.toLowerCase()
        .includes(normalizedSearchString);

      const dateMatch = patient.birth
        ?.toLowerCase()
        .includes(normalizedSearchString);

      // Combine all checks with OR to filter in any match
      return nameMatch || dateMatch;
    });
  }

  return (
    <>
      <div className="flex flex-col md:flex-row w-full max-w-screen-2xl mx-auto transition-all md:px-12 px-6 pt-24 pb-4 space-y-5 md:space-y-0">
        <div className="md:w-1/4 self-start w-full">
          {isSharingConsent === null ? (
            <div className="w-full flex flex-col rounded-lg border">
              <div
                onClick={() => setIsSharingConsent(true)}
                className="bg-transparent hover:bg-gray-200 h-[16vh] transition-all duration-200 flex flex-col items-center justify-center p-4 cursor-pointer rounded-lg"
              >
                <IoShareSocialOutline className="w-10 h-10" />
                <span className="mt-6">
                  Consentimento para Partilha de Dados
                </span>
              </div>
              <div
                onClick={() => setIsSharingConsent(false)}
                className="bg-transparent hover:bg-gray-200 h-[16vh] transition-all duration-200 flex flex-col items-center justify-center p-4 cursor-pointer rounded-lg"
              >
                <TiDocumentText className="w-10 h-10" />
                <span className="mt-6">Consentimento Informado</span>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <div className="rounded-lg border bg-card shadow-sm">
                <Card0Patient
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  prevStep={() => setIsSharingConsent(null)}
                  nextStep={() =>
                    isSharingConsent
                      ? setIsSharingDialogOpen(true)
                      : setIsInternalDialogOpen(true)
                  }
                />
              </div>
            </div>
          )}
          <Dialog
            open={isCreateDialogOpen}
            onOpenChange={setIsCreateDialogOpen}
          >
            <DialogTrigger asChild>
              <Button className="w-full mt-2">Criar Utente</Button>
            </DialogTrigger>
            <DialogContent className="w-full">
              <CreateCC setIsDialogOpen={setIsCreateDialogOpen} />
            </DialogContent>
          </Dialog>
          <Dialog
            open={isSharingDialogOpen}
            onOpenChange={setIsSharingDialogOpen}
          >
            <DialogContent className="sm:max-w-2xl min-h-8xl">
              <ConsentCard
                give={userContext.activeAffiliation ? false : true}
                handleDialogClose={handleSharingDialogClose}
                patient={selectedUser}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={isInternalDialogOpen}
            onOpenChange={setIsInternalDialogOpen}
          >
            <DialogContent className="sm:max-w-2xl min-h-8xl">
              <InternalConsentsCard
                setDialogIsOpen={setIsInternalDialogOpen}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex flex-col md:pl-4 md:w-3/4 w-full">
          <div className="hidden grid-cols-4 gap-2 md:grid ">
            <Card
              onClick={() =>
                setParams({ ...params, sharingType: undefined, page: 1 })
              }
              className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
                <CardTitle className="text-sm font-medium">
                  Total Partilhados
                </CardTitle>
                <PiShareNetworkLight />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">175</div>
                <p className="text-xs text-muted-foreground">
                  Número total de utentes a partilhar.
                </p>
              </CardContent>
            </Card>
            <Card
              onClick={() =>
                setParams({ ...params, sharingType: "AFFILIATIONS", page: 1 })
              }
              className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
                <CardTitle className="text-sm font-medium">
                  A Partilhar Comigo
                </CardTitle>
                <AiOutlineUserSwitch />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">7</div>
                <p className="text-xs text-muted-foreground">
                  Partilhado diretamente comigo.
                </p>
              </CardContent>
            </Card>
            <Card
              onClick={() =>
                setParams({ ...params, sharingType: "DEPARTMENTS", page: 1 })
              }
              className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
                <CardTitle className="text-sm font-medium">
                  Departamento
                </CardTitle>
                <MdOutlineMedicalInformation />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">22</div>
                <p className="text-xs text-muted-foreground">
                  Partilhado com os meus departamentos
                </p>
              </CardContent>
            </Card>
            <Card
              onClick={() =>
                setParams({ ...params, sharingType: "ORGANIZATION", page: 1 })
              }
              className="hover:cursor-pointer hover:scale-105 transition-all hover:shadow-md"
            >
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
                <CardTitle className="text-sm font-medium">
                  Organização
                </CardTitle>
                <TbBuildingHospital />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">144</div>
                <p className="text-xs text-muted-foreground">
                  Partilhados com a minha Unidade Hospitalar
                </p>
              </CardContent>
            </Card>
          </div>
          <Input
            type="text"
            onChange={(e) =>
              setParams({ ...params, search: e.target.value, page: 1 })
            }
            value={params.search}
            placeholder="Pesquisar utentes..."
            className="border w-1/2 mt-3 mb-1"
          />
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Utente</TableHead>
                <TableHead>Categorias</TableHead>
                <TableHead>Data de Nascimento</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="hover:cursor-pointer">
              {inPageLoading ? (
                <TableCell colSpan={3}>
                  <div className="flex justify-center items-center text-center py-32 h-full">
                    <Loading full={false} />
                  </div>
                </TableCell>
              ) : (
                <>
                  {filterPatients(params.search).length > 0 ? (
                    filterPatients(params.search).map(
                      (patient: sharingUser, index) => (
                        <TableRow
                          key={index}
                          onClick={() => navigate(`/people/${patient.id}`)}
                        >
                          <TableCell className="font-medium">
                            <ToolTip
                              children={<div>{patient.name}</div>}
                              tooltip={`${patient.fullName}`}
                            />
                          </TableCell>
                          <TableCell>
                            <div className="flex flex-wrap gap-2 mt-1">
                              {patient.categories.map(
                                (
                                  category: dataCategoryWithId,
                                  index: number
                                ) => (
                                  <h3
                                    key={index}
                                    className={`text-gray-600 px-2 py-1 rounded-lg text-xs`}
                                    style={{
                                      backgroundColor: "#" + category.color,
                                    }}
                                  >
                                    {category.name}
                                  </h3>
                                )
                              )}
                            </div>
                          </TableCell>
                          <TableCell>{patient.birth}</TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        {" "}
                        Nenhum utente a partilhar...
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          {utentes.length > 0 && (
            <Pagination className="mt-5">
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      if (params.page > 1)
                        setParams((prev) => ({
                          ...prev,
                          page: prev.page - 1,
                        }));
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink isActive={true}>{params.page}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      if (params.page < maxPages)
                        setParams((prev) => ({
                          ...prev,
                          page: prev.page + 1,
                        }));
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientList;
