import React, { memo } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { dataCategory } from "../../../types";
import { formatDate } from "../../../utils/dateTimeUtils";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../components/ui/dialog";
import Loading from "../../../components/loading";
import showToast from "../../../components/toast";
import { BsFillInfoSquareFill } from "react-icons/bs";

interface AccessConfirmationProps {
  userSharing: string;
  userAsking?: string;
  affiliations: { name: string; role: string }[];
  departmentNames: string[];
  organizationName: string;
  categories: dataCategory[];
  startsAt?: Date;
  duration: number;
  expiresAt?: Date;
  inPerson?: () => void;
  inApp?: () => void;
  blockchainTimestamps?: Date[];
  prevStep?: () => void;
  unfinishedStep1?: boolean;
  unfinishedStep2?: boolean;
}

const Card4Confirmation: React.FC<AccessConfirmationProps> = ({
  userSharing,
  userAsking,
  organizationName,
  departmentNames,
  affiliations,
  categories,
  startsAt,
  duration,
  expiresAt,
  inPerson,
  inApp,
  blockchainTimestamps = [],
  prevStep,
  unfinishedStep1 = false,
  unfinishedStep2 = false,
}) => {
  const startDate = startsAt ? startsAt : new Date();
  const startDateString = formatDate(startDate.toISOString());
  const expiresAtDate = expiresAt
    ? expiresAt
    : new Date(startDate.getTime() + duration * 3600000);
  const expiresAtDateString = formatDate(expiresAtDate.toISOString());
  const isConsentActive = false;
  // blockchainTimestamps.length === 2 &&
  // blockchainTimestamps[0] < startDate &&
  // blockchainTimestamps[1] > expiresAtDate;
  return (
    <div className="flex flex-col h-full">
      {isConsentActive ? (
        <>
          <CardHeader>
            <CardTitle>Consentimento</CardTitle>
          </CardHeader>
          <CardContent className="flex-grow">
            <div className="flex items-center justify-center h-full">
              <div className="rounded-md text-sm leading-loose border p-4 relative flex flex-col">
                <CardTitle className="flex text-xl space-x-3 mb-4">
                  <BsFillInfoSquareFill className="w-10 h-10" />
                  <span className="mt-2">Consentimento ativo</span>
                </CardTitle>
                <p className="text-sm text-gray-600 mb-4">
                  O consentimento que definiu já se encontra ativo para o
                  intervalo de tempo que escolheu. Atualmente a serem
                  partilhados entre:
                </p>
                <p className="text-base font-semibold mb-4">
                  <strong className="text-lg">
                    {formatDate(blockchainTimestamps[0].toISOString())}
                  </strong>{" "}
                  e{" "}
                  <strong className="text-lg">
                    {formatDate(blockchainTimestamps[1].toISOString())}
                  </strong>
                </p>
              </div>
            </div>
          </CardContent>
          {prevStep && (
            <CardFooter className="mt-auto flex justify-between">
              <Button
                className="bg-teal-700 hover:bg-teal-900"
                onClick={() => {
                  prevStep();
                }}
                disabled={false}
              >
                Anterior
              </Button>
            </CardFooter>
          )}
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>Consentimento</CardTitle>
            <CardDescription>
              Aqui pode ver todos os campos associados ao consentimento
            </CardDescription>
          </CardHeader>
          <CardContent className="flex-grow">
            <div className="my-5">
              <div className="flex flex-inline mt-1 gap-2">
                <div className="w-1/2">
                  <span className="font-semibold mb-1">Utente</span>
                  <div className="flex items-center space-x-4 border p-2 rounded-md">
                    <Avatar className="h-12 w-12 cursor-pointer">
                      <AvatarImage
                        src={
                          "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fHw%3D"
                        }
                        alt="@shadcn"
                      />
                      <AvatarFallback>RG</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col text-sm">
                      <span className="font-semibold">{userSharing}</span>
                      <span>14085467</span>
                    </div>
                  </div>
                </div>
                {userAsking && (
                  <div className="w-1/2">
                    <span className="font-semibold mb-1">
                      Profissional de Saúde
                    </span>
                    <div className="flex items-center space-x-4 border p-2 rounded-md">
                      <Avatar className="h-12 w-12 cursor-pointer">
                        <AvatarImage
                          src={
                            "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fHw%3D"
                          }
                          alt="@shadcn"
                        />
                        <AvatarFallback>RG</AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col text-sm">
                        <span className="font-semibold">{userAsking}</span>
                        <span>#5473456</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="h-[1px] bg-gray-200"></div>
            <div className="my-5">
              <div className="flex flex-inline mt-1 gap-2">
                <div className="w-1/2">
                  <span className="font-semibold">Organização</span>
                  {unfinishedStep1 ? (
                    <div>Sem Informação</div>
                  ) : (
                    <>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-label="Select a team"
                        className="flex w-[200px] rounded-full mt-2"
                      >
                        <div className="bg-black w-4 h-4 rounded-full mr-2"></div>
                        {organizationName}
                      </Button>
                    </>
                  )}
                </div>
                <div className="w-1/2">
                  <span className="font-semibold">Partilhado com</span>
                  {unfinishedStep1 ? (
                    <div>Sem Informação</div>
                  ) : (
                    <>
                      {affiliations.length === 0 &&
                        departmentNames.length === 0 && (
                          <p
                            className={`text-gray-600 py-2 p-3 rounded-full text-sm border mt-2`}
                          >
                            Toda a organização
                          </p>
                        )}
                      {departmentNames.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {departmentNames.map((name: string, index) => (
                            <p
                              key={index}
                              className={`bg-gray-100 text-gray-600 py-2 px-3 rounded-full text-sm`}
                            >
                              {name}
                            </p>
                          ))}
                        </div>
                      )}
                      {affiliations.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {affiliations.map((affiliation, index) => (
                            <h3
                              key={index}
                              className={`bg-gray-100 text-gray-600 py-2 px-3 rounded-full text-sm`}
                            >
                              {affiliation.name}
                            </h3>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[1px] bg-gray-200"></div>
            <div className="my-5">
              <span className="font-semibold">Categorias de dados</span>
              <div className="flex flex-wrap gap-2 mt-1">
                {unfinishedStep2 ? (
                  <div>Sem Informação</div>
                ) : (
                  categories.map((category: dataCategory, index) => (
                    <h3
                      key={index}
                      className={`text-gray-600 px-3 py-2 rounded-lg text-sm`}
                      style={{ backgroundColor: "#" + category.color }}
                    >
                      {category.name}
                    </h3>
                  ))
                )}
              </div>
            </div>
            <div className="h-[1px] bg-gray-200"></div>
            <div className="my-5">
              <div className="flex flex-inline mt-1 gap-2">
                <div className="w-1/2">
                  <span className="font-semibold mb-1">Início</span>
                  <div className="text-sm mb-4">{startDateString}</div>
                </div>
                <div className="w-1/2">
                  <span className="font-semibold mb-1">Fim</span>
                  <div className="text-sm mb-4">{expiresAtDateString}</div>
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-between mt-auto">
            {(inPerson || inApp) && (
              <div className="flex w-full justify-between mt-auto">
                {inApp && (
                  <Button
                    className="bg-teal-700 hover:bg-teal-900"
                    onClick={() => {
                      if (unfinishedStep1 || unfinishedStep2)
                        showToast(
                          "Falta concluir alguns passos do consentimento",
                          "info",
                          1500
                        );
                      else inApp();
                    }}
                    disabled={false}
                  >
                    Via Digital
                  </Button>
                )}
                {inPerson && (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        className="bg-teal-700 hover:bg-teal-900"
                        onClick={() => {
                          if (unfinishedStep1 || unfinishedStep2)
                            showToast(
                              "Falta concluir alguns passos do consentimento",
                              "info",
                              1500
                            );
                          else inPerson();
                        }}
                        disabled={false}
                      >
                        Via Cartão de Cidadão
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="w-full">
                      <CardHeader>
                        <CardTitle>Criar conta com Cartão de Cidadão</CardTitle>
                        <CardDescription className="text-sm text-gray-800">
                          Quando o cartão de cidadão estiver inserido no leitor,
                          clique em Ler Cartão
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="flex flex-col h-full">
                        <div className="py-16">
                          <Loading full={false} disableTimeout={true} />
                        </div>
                        <Button
                          className="bg-teal-700 hover:bg-teal-900 mt-8"
                          onClick={() => {}} // readCCData
                          disabled={false} // waiting
                        >
                          Ler Cartão
                        </Button>
                      </CardContent>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            )}
          </CardFooter>
        </>
      )}
    </div>
  );
};

export default memo(Card4Confirmation);
