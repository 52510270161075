// React
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Internal
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import PatientCard from "../../components/patient-card";
import { hexToRgba } from "../../utils/stringUtils";
import { Input } from "../../components/ui/input";
import { dataCategory } from "../../types";

import { CiPill } from "react-icons/ci";
import { PiSyringeLight } from "react-icons/pi";
import { CiTextAlignJustify } from "react-icons/ci";
import { CiMedicalClipboard } from "react-icons/ci";
import { HiArrowLongLeft } from "react-icons/hi2";
import { UserContext } from "../../services/user";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";

const records = [
  {
    name: "Análises ao Sangue (Geral)",
    type: "Análises",
    organization: "Laboratórios Teixeira & Góis",
    department: "Análises de Sangue",
    date: "12/07/2024",
    // eslint-disable-next-line
    image: require("../../components/images/exam2.png"),
    text: {
      report: `Lesões hiperintensas em T2/FLAIR na substância branca profunda frontal à esquerda com aspecto oval e centrípetas ao contorno epedndimário. Adicionalmente observa-se lesão juxtacortical à direita com atingimento fibras U. 
        Não foi administrado gadolíneo por recusa do examinado. 
        Estes aspectos são sugestivos de lesões desmielinizantes.
        `,
    },
    pdfUrl: "analysis.pdf",
  },
  {
    name: "Ressonância Magnética Crânio Mai 2024",
    type: "Exames",
    organization: "Clínica da Sé",
    department: "Imagiologia",
    date: "15/05/2024",
    // eslint-disable-next-line
    image: require("../../components/images/exam2.png"),
    text: {
      report: `Lesões hiperintensas em T2/FLAIR na substância branca profunda frontal à esquerda com aspecto oval e centrípetas ao contorno epedndimário. Adicionalmente observa-se lesão juxtacortical à direita com atingimento fibras U. 
      Não foi administrado gadolíneo por recusa do examinado. 
      Estes aspectos são sugestivos de lesões desmielinizantes.`,
    },
  },
  {
    name: "Registo Clínico Abr 2024",
    type: "Registos Clínicos",
    organization: "ULS Santa Maria",
    department: "USF Tílias",
    date: "15/04/2024",
    image: null,
    text: {
      S: "Doente com queixas de dormência na mão esquerda. Traz resultado de TAC",
      O: "Relatório: Não se identificam lesões focais com edema ou efeito de massa. Estruturas medianas centradas. Sem hidrocefalia.",
      A: "N-81 Lesão do Sistema Neurológico, outra",
      P: "Doente melhor dos sintomas, explicada situação e indicação para regressar se novos sintomas.",
    },
  },
  {
    name: "Tomografia Computorizada Crânio Abr 2024",
    type: "Exames",
    organization: "Clínica da Sé",
    department: "Neurorradiologia",
    date: "8/04/2024",
    // eslint-disable-next-line
    image: require("../../components/images/exam1.jpg"),
    text: {
      report: `Não se identificam lesões focais com edema ou efeito de massa.
      Estruturas medianas centradas.
      Sem hidrocefalia.`,
    },
  },
  {
    name: "Registo Clínico Abr 2024",
    type: "Registos Clínicos",
    organization: "ULS Santa Maria",
    department: "USF Tílias",
    date: "1/04/2024",
    image: null,
    text: {
      S: "Doente com queixas de dormência na mão esquerda.",
      O: "ENS sem alterações.",
      A: "N-81 Lesão do Sistema Neurológico, outra",
      P: "Peço exames - TAC",
    },
  },
];

const categories = [
  {
    name: "Exames",
    color: "#FFE5B4",
    icon: CiMedicalClipboard,
  },
  {
    name: "Análises",
    color: "#E6E6FA",
    icon: PiSyringeLight,
  },
  {
    name: "Registos Clínicos",
    color: "#D5F5E3",
    icon: CiTextAlignJustify,
  },
  {
    name: "Prescrições",
    color: "#FFD1DC",
    icon: CiPill,
  },
];

function RecordsList() {
  // Context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const { userId } = useParams();

  // Hooks
  const navigate = useNavigate();

  const isMounted = useRef(false);

  // State Variables
  const [selectedCategory, setSelectedCategory] = useState<
    dataCategory | undefined
  >(undefined);

  // Input Variables
  const [search, setSearch] = useState("");

  const goToRecordsDetail = () => {
    navigate("/records-detail", {
      state: { records, userId },
    });
  };

  const handleCategoryChange = (category: dataCategory) => {
    if (selectedCategory === category) setSelectedCategory(undefined);
    else setSelectedCategory(category);
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    navigate("/people");
  }, [userContext.activeAffiliation]);

  return (
    <div>
      <div className="max-w-screen-2xl mx-auto pt-24 pb-4 transition-all md:px-12 px-6">
        <button
          onClick={() => navigate("/people")}
          className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700 mb-2"
        >
          <HiArrowLongLeft className="w-6 h-6" />
          <span>Voltar</span>
        </button>
        <div className="flex md:flex-row flex-col gap-4">
          <div className="grid grid-cols-1 md:w-1/3 w-full gap-y-4">
            <PatientCard
              userId={userId ? userId : ""}
              records={records.filter((record) =>
                ["Immunization", "AllergyIntolerance"].includes(record.type)
              )}
            />
          </div>
          <div className="flex flex-col md:pl-4 md:w-2/3 w-full gap-2">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {categories.map((category, index) => (
                <Card
                  key={index}
                  className={`hover:cursor-pointer hover:scale-105 transition-all hover:shadow-lg ${
                    selectedCategory === category ? "scale-105 shadow-md" : ""
                  }`}
                  style={
                    selectedCategory === category
                      ? { backgroundColor: hexToRgba(category.color, 1) }
                      : { backgroundColor: hexToRgba(category.color, 0.3) }
                  }
                  onClick={() => handleCategoryChange(category)}
                >
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 pt-4">
                    <CardTitle className="text-sm font-medium">
                      {category.name}
                    </CardTitle>
                    {category.icon && (
                      <category.icon className="text-2xl text-gray-600" />
                    )}
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">
                      {
                        records.filter(
                          (record) => record.type === category.name
                        ).length
                      }
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
            <div className="flex w-full md:w-1/2 mt-2">
              <Input
                className=""
                placeholder="Procurar registo..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></Input>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Registo</TableHead>
                  <TableHead>Tipo</TableHead>
                  <TableHead>Organização</TableHead>
                  <TableHead>Departamento</TableHead>
                  <TableHead>Data</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="hover:cursor-pointer">
                {records
                  .filter((record: any) => {
                    if (selectedCategory) {
                      return selectedCategory.name === record.type;
                    }
                    return true; // If no selectedCategory, include all records
                  })
                  .filter(
                    (record: any) =>
                      record.name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      record.organization
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      record.department
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      record.date.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((record: any, index: number) => (
                    <TableRow key={index} onClick={goToRecordsDetail}>
                      <TableCell className="font-medium">
                        {record.name}
                      </TableCell>
                      <TableCell>
                        <span
                          key={index}
                          className={`text-gray-600 px-2 py-1 rounded-lg text-xs`}
                          style={{
                            backgroundColor: categories.find(
                              (category) => category.name === record.type
                            )?.color,
                          }}
                        >
                          {record.type}
                        </span>
                      </TableCell>
                      <TableCell>{record.organization}</TableCell>
                      <TableCell>{record.department}</TableCell>
                      <TableCell>{record.date}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Pagination className="mt-3">
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious href="#" />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">1</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#" isActive>
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">3</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationEllipsis />
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext href="#" />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(RecordsList);
