import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, CardHeader } from "./ui/card";
import { UserContext } from "../services/user";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { UserContextWithUserType } from "../shared/UserContentWithUser";
import { patient } from "../types";
import { calculateAge } from "../utils/dateTimeUtils";
import Loading from "./loading";

interface PatientCardInterface {
  userId: string;
  records: any[];
}

// They change all at the same time, so not a lot of re-renders
function PatientCard({ userId, records }: PatientCardInterface) {
  // Context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const [patient, setPatient] = useState<patient | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPatient = async () => {
      if (!userId) return;
      if (userId.length === 1) return;
      setIsLoading(true);
      try {
        const response = await userContext.axiosInstance.get(
          `users/patientById/${userId}`
        );
        console.log("response", response);
        if (response.data) {
          setPatient(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPatient();
  }, [userId]);

  return (
    <div>
      <Card className="border bg-white">
        {isLoading ? (
          <div className="py-48">
            <Loading full={false} />
          </div>
        ) : (
          <div>
            <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-0 pt-4">
              <div className="flex">
                <div className="w-28 h-28 rounded-full overflow-hidden mr-3">
                  <img
                    src={
                      patient?.photo
                        ? `data:image/png;base64,${patient.photo}`
                        : // eslint-disable-next-line
                          require("../components/images/profile.jpg")
                    }
                    alt="Profile"
                    className="w-28 h-28 object-cover"
                  />
                </div>
                <CardContent className="p-0">
                  <div className="text-xl font-bold">
                    {patient?.fullName || ""}
                  </div>
                  <p className="text-sm text-muted-foreground">
                    {patient?.birth || "01/08/2000"}
                  </p>
                </CardContent>
              </div>
            </CardHeader>
            <Accordion
              type="single"
              collapsible
              className="w-full px-10 mt-10"
              defaultValue="item-1"
            >
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-sm text-base lg:text-lg">
                  Dados de saúde
                </AccordionTrigger>
                <AccordionContent>
                  <div className="info-list mt-4">
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Tipo de sangue
                      </span>
                      <span className="value text-right flex-grow">A+</span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Alergias</span>
                      <span className="value text-right flex-grow">
                        <span className="bg-red-300 rounded-md px-2 py-1 text-xs text-gray-700">
                          PENICILINA
                        </span>
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Doenças</span>
                      <span className="value text-right flex-grow">
                        Nenhuma
                      </span>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger className="text-sm text-base lg:text-lg">
                  Informação
                </AccordionTrigger>
                <AccordionContent>
                  <div className="info-list mt-4">
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Data de nascimento
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.birth || "01/08/2000"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Idade</span>
                      <span className="value text-right flex-grow">
                        {calculateAge(patient?.birth || "")}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Sexo</span>
                      <span className="value text-right flex-grow">
                        Masculino
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Cartão de Cidadão
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.docId || "14476890"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">
                        Número de Utente
                      </span>
                      <span className="value text-right flex-grow">
                        {patient?.healthNumber || "2325645654"}
                      </span>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3" className="border-b-0">
                <AccordionTrigger className="text-sm text-base lg:text-lg">
                  Contactos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="info-list mt-4">
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Email</span>
                      <span className="value text-right flex-grow">
                        {patient?.email || "goncalo.almeida@gmail.com"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Telemóvel</span>
                      <span className="value text-right flex-grow">
                        {patient?.phone || "+351 912 345 678"}
                      </span>
                    </div>
                    <div className="info-item flex justify-between py-2">
                      <span className="label font-bold mr-2">Emergência</span>
                      <div className="flex flex-col items-end">
                        <div className="value text-right">
                          Mãe: +351 916 334 667
                        </div>
                        <div className="value text-right">
                          Pai: +351 962 225 653
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        )}
      </Card>
    </div>
  );
}

export default PatientCard;
