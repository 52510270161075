// React
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// External
import { Wallet, Settings } from "lucide-react";
import { FaRegUser } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { IoMdHelp } from "react-icons/io";

// Internal
import { UserContext } from "../../services/user";
import { Separator } from "../../components/ui/separator";
import ProfileTab from "./tabs/ProfileTab";
import SettingsTab from "./tabs/SettingsTab";
import HelpTab from "./tabs/HelpTab";
import PaymentsTab from "./tabs/PaymentsTab";
import AffiliationsTab from "./tabs/AffiliationsTab";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";

type item = {
  name: string;
  key: number;
  subtitle: string;
};

const menuItems = [
  {
    name: "Perfil",
    key: 0,
    subtitle: "Veja e edite as suas informações de perfil",
    icon: <FaRegUser className="mr-2 h-4 w-4" />,
  },
  {
    name: "Afiliações",
    key: 1,
    subtitle: "Veja e edite as suas afiliações",
    icon: <Wallet className="mr-2 h-4 w-4" />,
  },
  {
    name: "Definições",
    key: 2,
    subtitle: "Ajuste as suas preferências de sistema",
    icon: <Settings className="mr-2 h-4 w-4" />,
  },
  {
    name: "Pagamentos",
    key: 3,
    subtitle: "Efetue os seus pagamentos para desbloquear funcionalidades",
    icon: <MdOutlinePayments className="mr-2 h-4 w-4" />,
  },
  {
    name: "Ajuda",
    key: 4,
    subtitle: "Precisa de ajuda? Encontre respostas aqui",
    icon: <IoMdHelp className="mr-2 h-4 w-4" />,
  },
];

const NewSettings = () => {
  // Context
  const userContext = useContext(UserContext) as UserContextWithUserType;

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  // Const
  const [selectedMenu, setSelectedMenu] = useState<item | null>(menuItems[0]);

  // Toggles
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    let tab: string | number | null = queryParams.get("tab");
    if (!tab) {
      navigate("/settings/tab=0");
      return;
    }
    tab = parseInt(tab, 10);
    const item = menuItems.find((item) => item.key === tab);
    if (!item) {
      navigate("/settings/tab=0");
      return;
    }
    if (userContext.user.affiliations.length === 0 && item.key === 1)
      navigate("/settings/tab=0");
    setSelectedMenu(item);
  }, [queryParams]);

  return (
    <>
      <div className="max-w-screen-2xl mx-auto space-y-2 pt-10 lg:pt-24 px-6 md:px-12 transition-all">
        <div className="lg:flex lg:flex-col hidden">
          <h2 className="text-2xl font-bold tracking-tight">Definições</h2>
          <p className="text-muted-foreground">
            Gira as definições da sua conta e defina as preferências de sistema.
          </p>
        </div>
        <Separator className="my-4 hidden lg:flex" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0 pt-4">
          <aside className="hidden lg:flex lg:flex-col lg:w-1/5 -m-2 tracking-wide">
            <div className="flex flex-col space-y-2">
              {selectedMenu &&
                menuItems.map((item) =>
                  item.name === "Afiliações" &&
                  userContext.user.affiliations.length === 0 ? (
                    <></>
                  ) : (
                    <p
                      key={item.key}
                      className={`flex flex-inline p-2 rounded-md hover:cursor-pointer ${
                        selectedMenu.key === item.key
                          ? "bg-neutral-100"
                          : "bg-none"
                      }`}
                      onClick={() => navigate(`/settings?tab=${item.key}`)}
                    >
                      <span className="mt-1">{item.icon}</span>
                      {item.name}
                    </p>
                  )
                )}
            </div>
          </aside>

          {/* Profile Page*/}
          <div className="flex-1 space-y-4 w-full lg:w-[40vw]">
            <div>
              <h3 className="lg:text-lg text-2xl font-medium">
                {selectedMenu && selectedMenu.name}
              </h3>
              <p className="lg:text-sm text-base text-muted-foreground">
                {selectedMenu && selectedMenu.subtitle}
              </p>
            </div>
            <Separator />

            {/* Profile */}
            {selectedMenu && selectedMenu.name === "Perfil" && <ProfileTab />}
            {/* Affiliations Page */}
            {selectedMenu &&
              selectedMenu.name === "Afiliações" &&
              userContext.user.affiliations.length > 0 && <AffiliationsTab />}
            {/* Settings Page */}
            {selectedMenu && selectedMenu.name === "Definições" && (
              <SettingsTab />
            )}
            {/* Help Page */}
            {selectedMenu && selectedMenu.name === "Help" && <HelpTab />}
            {/* Payments Page */}
            {selectedMenu && selectedMenu.name === "Pagamentos" && (
              <PaymentsTab />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSettings;
