"use server";

// Thirdweb
import { createAuth, LoginPayload, signLoginPayload } from "thirdweb/auth";
import { Account } from "thirdweb/wallets";

// External
import axios from "axios";

// Internal
import { user } from "../types";
import { COOKIE_NAME, FRONTEND_DOMAIN } from "../environment/variables";
import { LoginOptionalParams } from "../types/interfaces";
import Cookies from "js-cookie";
import showToast from "../components/toast";
import { verifyPayloadDto } from "../types/";

const thirdwebAuth = createAuth({
  domain: FRONTEND_DOMAIN,
});

// Signs payload received from backend
export async function login(
  account: Account,
  loginOptionalParams?: LoginOptionalParams
): Promise<user | null> {
  try {
    const manualLogin =
      loginOptionalParams !== undefined &&
      Object.keys(loginOptionalParams).length > 0;
    // Paload = sucess ;
    // else true = user exists but is blocked
    // false --> user exist but loggedOut
    const response: [boolean, LoginPayload | null] | null = (
      await axios.get(`auth/payload/${account.address}/${manualLogin}`)
    ).data;
    console.log("payload response", response);
    if (response === null) {
      // User exists but inactive
      showToast(
        "Esta conta pode ter sido apagada ou bloqueada. Entre em contacto com o suporte para ativar",
        "error",
        1500
      );
      return null;
    }
    if (response[1] === null) return null; // == if (!manualLogin && response[0] === true) return null; --> Cancel Auto connect once loggedOut
    const payload = response[1] as LoginPayload;
    // Step 2: CLIENT - Signs the payload
    const signResult = await signLoginPayload({ account, payload });
    // Step 3: SERVER - Verifies the payload
    if (!(await thirdwebAuth.verifyPayload(signResult))) return null; // Double check
    const verifyPayloadDto: verifyPayloadDto = {
      payload: signResult.payload as LoginPayload,
      signature: signResult.signature as string,
      ...loginOptionalParams,
    };
    const res = await axios.post(`auth/verifyPayload`, verifyPayloadDto, {
      withCredentials: true,
      timeout: 15000,
    });
    console.log("Received token", Cookies.get(COOKIE_NAME));
    console.log("Received user", res.data);
    return res.data; // user
  } catch (error) {
    console.error("Login Error", error);
    return null;
  }
}
