export interface LogOutcomeInterface {
  SUCCESS: "SUCCESS";
  FAILED: "FAILED";
  BLOCKED: "BLOCKED";
}
export const LogOutcome: LogOutcomeInterface = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  BLOCKED: "BLOCKED",
};
export type LogOutcome = (typeof LogOutcome)[keyof typeof LogOutcome];

export interface ConsentStatusInterface {
  PENDING: "PENDING";
  ACTIVE: "ACTIVE";
  FUTURE: "FUTURE";
  EXPIRED: "EXPIRED";
}
export const ConsentStatus: ConsentStatusInterface = {
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  FUTURE: "FUTURE",
  EXPIRED: "EXPIRED",
};
export type ConsentStatus = (typeof ConsentStatus)[keyof typeof ConsentStatus];

export interface ThirdwebLoginMethodInterface {
  EMAIL: "EMAIL";
  GOOGLE: "GOOGLE";
  PHONE: "PHONE";
}
export const ThirdwebLoginMethod: ThirdwebLoginMethodInterface = {
  EMAIL: "EMAIL",
  GOOGLE: "GOOGLE",
  PHONE: "PHONE",
};
export type ThirdwebLoginMethod =
  (typeof ThirdwebLoginMethod)[keyof typeof ThirdwebLoginMethod];

interface SharingTypeInterface {
  ORGANIZATION: "ORGANIZATION";
  DEPARTMENTS: "DEPARTMENTS";
  AFFILIATIONS: "AFFILIATIONS";
}
export const SharingType: SharingTypeInterface = {
  ORGANIZATION: "ORGANIZATION",
  DEPARTMENTS: "DEPARTMENTS",
  AFFILIATIONS: "AFFILIATIONS",
};
export type SharingType = (typeof SharingType)[keyof typeof SharingType];

interface UserCreationStatusInterface {
  NEW: "NEW";
  PENDING_FORM: "PENDING_FORM";
  CREATED: "CREATED";
}
export const UserCreationStatus: UserCreationStatusInterface = {
  NEW: "NEW",
  PENDING_FORM: "PENDING_FORM",
  CREATED: "CREATED",
};
export type UserCreationStatus =
  (typeof UserCreationStatus)[keyof typeof UserCreationStatus];
