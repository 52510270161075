import React from "react";
import { Button } from "../../components/ui/button";
import { recordType } from "../../types";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { Separator } from "../../components/ui/separator";
import {
  CalendarIcon,
  Building2Icon,
  FolderIcon,
  CopyIcon,
} from "lucide-react";
import showToast from "../../components/toast";
import DicomSimple from "../dicom/DicomSimple";
//import DicomSimple from "../dicom/DicomSimple";

interface ImageDetailProps {
  record: recordType;
}

function ImageDetail({ record }: ImageDetailProps) {
  const navigate = useNavigate();
  console.log(record);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(record.text?.report || "");
    showToast("Texto copiado para a área de transferência", "success", 3000);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div className="flex flex-col order-1 md:order-2">
          <CardHeader>
            <CardTitle className="text-2xl md:text-3xl font-bold">
              {record.name}
            </CardTitle>
            <Badge variant="secondary" className="mt-2 w-fit">
              {record.type}
            </Badge>
          </CardHeader>
          <CardContent className="flex-grow">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Building2Icon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Organização
                  </p>
                  <p className="text font-semibold">{record.organization}</p>
                </div>
              </div>
              <Separator className="my-2 bg-gray-400" />
              <div className="flex items-center space-x-3">
                <FolderIcon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Departamento
                  </p>
                  <p className="text font-semibold">{record.department}</p>
                </div>
              </div>
              <Separator className="my-2 bg-gray-400" />
              <div className="flex items-center space-x-3">
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Data</p>
                  <p className="text font-semibold">{record.date}</p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center mb-1">
                <p className="text-sm font-semibold">Descrição do Exame</p>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={copyToClipboard}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <CopyIcon className="h-4 w-4 mr-1" />
                  Copiar
                </Button>
              </div>
              <div className="bg-gray-50 rounded-lg p-2 text-sm text-gray-700 border border-gray-200">
                {record.text?.report}
              </div>
            </div>
          </CardContent>
        </div>
        <div className="order-2 md:order-1">{<DicomSimple />}</div>
      </div>
    </div>
  );
}

export default ImageDetail;
