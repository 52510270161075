// src/pages/internalConsents/SelectTemplate.tsx

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Input } from "../../../components/ui/input";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { UserContext } from "../../../services/user";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import Loading from "../../../components/loading";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Button } from "../../../components/ui/button";
import { Template } from "../../../types/internalConsents";

// Add TypeScript types for props
interface Card1TemplatesProps {
  selectedTemplates: Template[];
  setSelectedTemplates: (templates: Template[]) => void;
  nextStep: () => void;
}

const Card1Templates = ({
  selectedTemplates,
  setSelectedTemplates,
  nextStep,
}: Card1TemplatesProps) => {
  const userContext = useContext(UserContext);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [templateContent, setTemplateContent] = useState<any>(null);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await userContext?.axiosInstance.get(
          "/consent-template"
        );
        const data = response?.data;
        console.log(data);
        setTemplates(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const fetchPdfDetail = async (template: Template) => {
    try {
      const response = await userContext?.axiosInstance.get(
        `consent-template/${template.id}`
      );
      console.log(response?.data);
      setTemplateContent(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Filter templates based on the search term
  const filteredTemplates = useMemo(() => {
    return templates.filter(
      (template) =>
        template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [templates, searchTerm]);

  const templateDetails = (template: Template) => {
    return (
      <div>
        <DialogTitle>{template.name}</DialogTitle>
        <DialogDescription>{template.description}</DialogDescription>
        <Accordion type="single" className="mt-5" collapsible>
          <AccordionItem value={"html"}>
            <AccordionTrigger
              className="text-sm"
              onClick={() => fetchPdfDetail(template)}
            >
              Template
            </AccordionTrigger>
            <AccordionContent>
              {templateContent && (
                <div
                  className="max-h-[400px] overflow-y-auto"
                  dangerouslySetInnerHTML={{
                    __html: templateContent.fileContent,
                  }}
                />
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    );
  };

  return (
    <div>
      <CardHeader>
        <CardTitle>Escolha os Consentimentos</CardTitle>
        <CardDescription>
          Escolha os consentimentos que deseja pedir ao Utente
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-2">
        <div>
          <Input
            className="mb-2"
            placeholder="Pesquisar Consentimentos..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loading ? (
            <div className="py-20">
              <Loading full={false} />
            </div>
          ) : (
            <div className="">
              {filteredTemplates.map((template: Template, index: number) => (
                <div
                  onClick={() => {
                    // Toggle selection: if already selected, remove it; otherwise, add it
                    if (selectedTemplates.includes(template)) {
                      setSelectedTemplates(
                        selectedTemplates.filter((t) => t !== template)
                      );
                    } else {
                      setSelectedTemplates([...selectedTemplates, template]);
                    }
                  }}
                  key={index}
                  className={`flex h-1/8 rounded-sm transition-all hover:cursor-pointer items-center px-4 py-5 mb-1 shadow-sm ${
                    selectedTemplates.includes(template)
                      ? "bg-teal-600 text-white"
                      : "bg-gray-50 hover:bg-gray-100 hover:scale-x-105"
                  }`}
                >
                  <span>{template.name}</span>
                  <div className="ml-auto flex space-x-2">
                    <Dialog>
                      <DialogTrigger asChild>
                        <span
                          className={`text-sm ${
                            selectedTemplates.includes(template)
                              ? "text-white"
                              : "text-gray-500 hover:text-gray-700"
                          } hover:cursor-pointer`}
                          onClick={() => setCurrentTemplate(template)} // Set current template on click
                        >
                          Ver Detalhes
                        </span>
                      </DialogTrigger>
                      <DialogContent>{templateDetails(template)}</DialogContent>
                    </Dialog>
                  </div>
                </div>
              ))}
              {filteredTemplates.length === 0 && (
                <div className="flex justify-center items-center min-h-60">
                  <p className="text-gray-500">
                    Nenhum consentimento encontrado
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end mt-2">
          <Button onClick={nextStep}>Próximo</Button>
        </div>
      </CardContent>
    </div>
  );
};

export default Card1Templates;
