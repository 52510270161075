import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";
import { updateAffiliationDto } from "../../../types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Input } from "../../../components/ui/input";
import { PhoneInputLight } from "../../../components/ui/phone-input-light";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  filterChanges,
  showCancelAndDisableUpdate,
} from "../../../utils/compareEntity";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";

import {
  affiliationNameMax,
  affiliationNameMaxWords,
} from "../../../environment/variables";
import { UserContext } from "../../../services/user";
import showToast from "../../../components/toast";
import InputWithinLimits from "../../../components/ui/inputWithinLimits";
import { isEmail } from "../../../utils/stringUtils";
import { Index } from "dwv";

const AffiliationsTab = ({}) => {
  const userContext = useContext(UserContext) as UserContextWithUserType;

  const [countryCode, setCountryCode] = useState<string | undefined>("PT");
  const [updateAffiliations, setUpdateAffiliations] = useState<
    updateAffiliationDto[]
  >(
    userContext.user.affiliations.map((affiliation) => {
      return {
        id: affiliation.id,
        name: affiliation.name,
        email: affiliation.email,
        phone: affiliation.phone,
      };
    }) || []
  );
  const { toUpdateAffiliations, showCancels, disableUpdates } = useMemo(() => {
    const updates = userContext.user.affiliations.map((affiliation, index) => {
      const update = filterChanges(
        userContext.user.affiliations[index],
        affiliation
      );
      const [showCancel, disableUpdate] = showCancelAndDisableUpdate(update);
      return {
        toUpdateAffiliation: update as updateAffiliationDto,
        showCancel: showCancel,
        disableUpdate: disableUpdate,
      };
    });
    return {
      toUpdateAffiliations: updates.map((update) => update.toUpdateAffiliation),
      showCancels: updates.map((update) => update.showCancel),
      disableUpdates: updates.map((update) => update.disableUpdate),
    };
  }, [userContext.user.affiliations, updateAffiliations]);

  const submitUpdateAffiliation = useCallback(
    async (index: number) => {
      const toUpdateAffiliation = toUpdateAffiliations[index];
      if (toUpdateAffiliation.email && !isEmail(toUpdateAffiliation.email)) {
        // Empty string
        showToast(`Email inválido`, "error", 1500);
        return false;
      }
      if (
        toUpdateAffiliation.phone &&
        !isValidPhoneNumber(
          toUpdateAffiliation.phone,
          countryCode as CountryCode
        )
      ) {
        showToast(`Numero de telemóvel inválido`, "error", 1500);
        return false;
      }
      if (
        toUpdateAffiliation.name &&
        !/^(\w+\s+){1,4}\w+$/.test(toUpdateAffiliation.name)
      ) {
        showToast(
          `O seu nome deve ter entre 2 a 5 palavras, com no minimo 3 caracteres cada`,
          "error",
          300
        );
        return false;
      }
      try {
        const response = await userContext.axiosInstance.patch(
          "users/affiliation",
          toUpdateAffiliation
        );
        if (response) {
          const affilationUpdated = response.data;
          userContext.setUser({
            ...userContext.user,
            affiliations: userContext.user.affiliations.map((affiliation) =>
              affiliation.id === affilationUpdated.id
                ? affilationUpdated
                : affiliation
            ),
          });
          showToast("Informação atualizada com sucesso", "success", 3000);
        }
      } catch (error) {
        console.error("Error updating:", error);
        showToast("Erro ao atualizar informação", "error", 3000);
      }
    },
    [userContext, updateAffiliations]
  );

  const handleCancelAffiliation = useCallback(
    (index: number) => {
      setUpdateAffiliations(
        userContext.user.affiliations.map((affiliation, i) => {
          if (index === i) {
            const _affiliation = userContext.user.affiliations[i];
            return {
              id: _affiliation.id || "",
              name: _affiliation.name || "",
              email: _affiliation.email || "",
              phone: _affiliation.phone || "",
            };
          }
          return affiliation;
        }) || []
      );
    },
    [userContext.user.affiliations]
  );

  const handleOnChangeAffiliations = useCallback(
    (
      affiliationIndex: number,
      key: keyof updateAffiliationDto,
      value: string | boolean
    ) => {
      setUpdateAffiliations((prevState) => {
        const updatedAffiliations = prevState.map((affiliation, index) => {
          if (index === affiliationIndex) {
            return { ...affiliation, [key]: value };
          }
          return affiliation;
        });
        return updatedAffiliations;
      });
    },
    []
  );
  return (
    <>
      {updateAffiliations.length > 0 &&
        updateAffiliations.map((affiliation, index) => (
          <Accordion
            type="single"
            collapsible
            className="w-full"
            key={index}
            defaultValue={
              updateAffiliations.length === 1 ? `item-0` : undefined
            } // Start opened if only one affiliation
          >
            <AccordionItem value={`item-${index}`}>
              <AccordionTrigger className="text-xl">
                {userContext.user.affiliations[index].organization.name} -{" "}
                {userContext.user.affiliations[index].role.name}
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-6">
                  <div className="space-y-1">
                    <p className="text-sm font-semibold text-gray-800">Nome</p>
                    <div className="relative flex items-center">
                      {userContext.user.affiliations[index].role.name ===
                        "Médico" && (
                        <div className="absolute left-0 top-0 bottom-0 flex items-center px-3 rounded-l-lg text-gray-700 z-10">
                          <span className="font-bold">
                            {userContext.user.identities[0].gender === "M"
                              ? "Dr."
                              : "Dra."}
                          </span>
                        </div>
                      )}
                      <Input
                        type="text"
                        placeholder="Pedro Silva"
                        onChange={(e) => {
                          const value = e.target.value;
                          const words = value.trim().split(/\s+/); // count words
                          const regex = /^[A-Za-zÀ-ÿ.\s]*$/; // Only letters, dots and spaces
                          const preventMultipleSpaces = !/\s{2,}/.test(value); // Prevent consecutive spaces
                          if (
                            value.length <= affiliationNameMax &&
                            (words.length < affiliationNameMaxWords ||
                              (words.length === affiliationNameMaxWords &&
                                !value.endsWith(" "))) && // Dont allow more spaces after 5 words
                            regex.test(value) &&
                            preventMultipleSpaces
                          ) {
                            handleOnChangeAffiliations(index, "name", value);
                          }
                        }}
                        value={affiliation.name || ""}
                        className={`border-stone-200 ${
                          userContext.user.affiliations[index].role.name ===
                          "Médico"
                            ? "pl-10"
                            : ""
                        }`}
                        maxLength={100}
                        //onClear={() =>
                        //  handleOnChangeAffiliations(
                        //    index,
                        //    "name",
                        //    userContext.user.affiliations[index].name || ""
                        //  )
                        //}
                      />
                    </div>
                    <InputWithinLimits text={affiliation.name} max={100} />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-semibold text-gray-800">Email</p>
                    <Input
                      type="text"
                      placeholder="emailExemplo@example.com"
                      onChange={(e) =>
                        handleOnChangeAffiliations(
                          index,
                          "email",
                          e.target.value
                        )
                      }
                      value={affiliation.email || ""}
                      className="border-stone-200"
                      //onClear={() =>
                      //  handleOnChangeAffiliations(
                      //    index,
                      //    "email",
                      //    userContext.user.affiliations[index].email || ""
                      //  )
                      //}
                    />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-semibold text-gray-800">
                      Número de Telemóvel
                    </p>
                    <PhoneInputLight
                      className="w-full mt-3 placeholder-gray-500"
                      defaultCountry="PT"
                      onChange={(value) =>
                        handleOnChangeAffiliations(index, "phone", value)
                      }
                      value={affiliation.phone || ""}
                      initialValueFormat="national"
                    />
                  </div>
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm font-semibold text-gray-800 mb-2">
                        Departamentos
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {userContext.user.affiliations[index].departments.map(
                          (department, deptIndex) => (
                            <div
                              key={deptIndex}
                              className="bg-gray-100 hover:bg-gray-200 cursor-pointer transition-colors duration-200 rounded-md px-3 py-1 text-sm text-gray-700"
                            >
                              {department.name}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="justify-end flex space-x-2 pt-10 pb-16 lg:p-0 lg:pb-10">
                    {showCancels[index] && (
                      <Button
                        onClick={() => handleCancelAffiliation(index)}
                        variant="secondary"
                      >
                        Cancelar
                      </Button>
                    )}
                    <Button
                      className="px-6"
                      onClick={() => submitUpdateAffiliation(index)}
                      disabled={disableUpdates[index]}
                    >
                      Aplicar Alterações
                    </Button>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
    </>
  );
};

export default memo(AffiliationsTab);
