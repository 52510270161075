import {
  BLOCKCHAIN_USERID_SALT,
  CONSENT_ADDRESS,
  USERID_SALT,
} from "../environment/variables";
import {
  PreparedTransaction,
  getContract,
  readContract,
  prepareContractCall,
  sendTransaction,
  resolveMethod,
  waitForReceipt,
  ThirdwebContract,
  Chain,
  ContractOptions,
} from "thirdweb";
import { BlockchainGiveParams, BlockchainParams } from "../types";
import { Account } from "thirdweb/wallets";

import { keccak256 } from "@ethersproject/keccak256";
import { toUtf8Bytes } from "@ethersproject/strings";
import { consentAbi } from "../environment/abi";
import { blockchainToDate } from "../utils/dateTimeUtils";

export class Blockchain {
  contract: ThirdwebContract;
  eoaAccount: Account = {} as Account;
  smartAccount: Account = {} as Account;

  constructor(client: any, chain: Chain) {
    this.contract = (
      getContract as (options: ContractOptions<any>) => ThirdwebContract<any>
    )({
      client: client,
      chain: chain,
      address: CONSENT_ADDRESS,
      abi: consentAbi,
    });
  }

  updateAccounts(eoaAccount: Account, smartAccount: Account) {
    this.eoaAccount = eoaAccount;
    this.smartAccount = smartAccount;
  }

  async consentIsValid({
    userId,
    consent,
    hash,
  }: BlockchainParams): Promise<boolean> {
    try {
      if (!hash) {
        if (!userId || !consent) throw new Error("Erro");
        hash = this._buildHash(
          userId,
          consent.sharingWithAffiliationsIds,
          consent.sharingWithDepartmentsIds,
          consent.sharingWithOrganizationId,
          consent.categoriesIds
        );
      }
      //console.log(
      //  "consentIsValid",
      //  CONSENT_ADDRESS,
      //  this.smartAccount.address,
      //  hash
      //);
      const valid = await (readContract as (options: any) => Promise<any>)({
        contract: this.contract,
        method: resolveMethod("consentIsValid"),
        params: [this._builUserID(userId), hash],
      });

      const isValid = valid as unknown as boolean;
      return isValid;
    } catch (error) {
      console.error("Read call error", error);
      throw new Error("Error getting timestamps");
    }
  }

  async getLastConsent({
    userId,
    consent,
    hash,
  }: BlockchainParams): Promise<Date[]> {
    try {
      if (!hash) {
        if (!userId || !consent) throw new Error("Erro");
        hash = this._buildHash(
          userId,
          consent.sharingWithAffiliationsIds,
          consent.sharingWithDepartmentsIds,
          consent.sharingWithOrganizationId,
          consent.categoriesIds
        );
      }
      const timestamps = (await (
        readContract as (options: any) => Promise<any>
      )({
        contract: this.contract,
        method: resolveMethod("getLastConsent"),
        params: [this._builUserID(userId), hash],
      })) as unknown as { startsAt: bigint; expiresAt: bigint };
      //console.log("timestamps", timestamps);
      if (timestamps.startsAt === BigInt(0)) return [];
      return [
        blockchainToDate(timestamps.startsAt),
        blockchainToDate(timestamps.expiresAt),
      ];
      // eslint-disable-next-line
    } catch (error: any) {
      console.error("Read call error", error);
      // ERROR
      return []; // returns as if there is any, so it can proceed with no problems
    }
  }

  async revoke({ userId, consent, hash }: BlockchainParams): Promise<boolean> {
    try {
      //console.log("revoke", userId, consent, hash);
      if (!hash) {
        if (!userId || !consent) throw new Error("Erro");
        hash = this._buildHash(
          userId,
          consent.sharingWithAffiliationsIds,
          consent.sharingWithDepartmentsIds,
          consent.sharingWithOrganizationId,
          consent.categoriesIds
        );
      }

      const transaction = (
        prepareContractCall as (options: any) => PreparedTransaction
      )({
        contract: this.contract,
        method: resolveMethod("revoke"),
        params: [hash],
        //maxFeePerGas: BigInt(300), // 30
        //maxPriorityFeePerGas: BigInt(10), //1
      });

      // console.log("Transaction", transaction);
      // const estimate = await estimateGas({
      //   transaction,
      //   account: this.smartAccount,
      // });
      // console.log("Estimate", estimate);

      // const result = await (
      //   simulateTransaction as (options: any) => Promise<any>
      // )({
      //   transaction,
      // });

      //////////// HANDLE RESULT
      const transactionResult = await sendTransaction({
        account: this.smartAccount,
        transaction,
      });
      //console.log("Transaction Result", transactionResult);
      await waitForReceipt(transactionResult);
      return true;
    } catch (error) {
      console.error("Revoke error", error, this.smartAccount);
      throw new Error("Error ao terminar consentimento");
    }
  }

  async giveConsent({
    userId,
    requester,
    hash,
    consent,
    startsAt,
    duration,
  }: BlockchainGiveParams): Promise<boolean> {
    try {
      if (!hash) {
        if (!userId || !consent) throw new Error("Erro");
        hash = this._buildHash(
          userId,
          consent.sharingWithAffiliationsIds,
          consent.sharingWithDepartmentsIds,
          consent.sharingWithOrganizationId,
          consent.categoriesIds
        );
      }
      const _requester = requester || this.smartAccount.address;
      const params = startsAt
        ? [_requester, hash, startsAt, duration]
        : [_requester, hash, duration];
      const transaction = (
        prepareContractCall as (options: any) => PreparedTransaction
      )({
        contract: this.contract,
        method: startsAt
          ? resolveMethod("giveConsent")
          : resolveMethod("giveImeadiateConsent"),
        params: params,
      });
      //console.log("Transaction", transaction);
      const transactionResult = await sendTransaction({
        account: this.smartAccount,
        transaction,
      });
      //console.log("Transaction Result", transactionResult);
      await waitForReceipt(transactionResult);
      return true;
    } catch (error) {
      console.error("error", error);
      throw new Error("Error ao dar consentimento");
    }
  }

  _builUserID(userId: string): string {
    return keccak256(toUtf8Bytes(userId + BLOCKCHAIN_USERID_SALT));
  }

  _buildHash(
    userId: string,
    sharingWithAffiliationsIds: string[],
    sharingWithDepartmentsIds: string[],
    sharingWithOrganizationId: string,
    categoriesIds: string[]
  ): string {
    const humanosUserPoolId = keccak256(toUtf8Bytes(userId + USERID_SALT));
    const concat = [
      humanosUserPoolId,
      ...sharingWithAffiliationsIds,
      ...sharingWithDepartmentsIds,
      sharingWithOrganizationId,
      ...categoriesIds,
    ];
    // Bytes32 hash
    const hash = keccak256(toUtf8Bytes(concat.join("")));
    console.log(
      "build hash",
      userId,
      USERID_SALT,
      humanosUserPoolId,
      sharingWithAffiliationsIds,
      sharingWithDepartmentsIds,
      sharingWithOrganizationId,
      categoriesIds,
      hash
    );
    return hash;
  }
}
