import { useNavigate, useLocation } from "react-router-dom";

// Define the custom hook that uses other hooks
export const useNavigateIfPathDiffers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (targetPath: string) => {
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  };
};
