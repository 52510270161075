// Thirdweb
import {
  Chain,
  createThirdwebClient,
  defineChain,
  ThirdwebClient,
} from "thirdweb";
import { CC_CLIENT_ID, FACTORY_ADDRESS } from "../environment/variables";
import { baseSepolia } from "thirdweb/chains";

// External
import { Account, inAppWallet, smartWallet, Wallet } from "thirdweb/wallets";
import { utils } from "ethers";
import showToast from "../components/toast";

import { keccak256 } from "@ethersproject/keccak256";
import { toUtf8Bytes } from "@ethersproject/strings";

// Internal
import { BlockchainGiveParams, ThirdwebLoginMethod } from "../types";
import { CCIdentity, connectUser, createCCOTP, updateCC } from "../types/cc";
import { LoginPayload, signLoginPayload } from "thirdweb/auth";
import { AxiosInstance } from "axios";
import { Blockchain } from "./Blockchain";

export class CCService {
  constructor(public axiosInstance: AxiosInstance) {}

  private client: ThirdwebClient = createThirdwebClient({
    clientId: CC_CLIENT_ID,
  });
  private chain: Chain = defineChain(baseSepolia);
  private blockchain = new Blockchain(this.client, this.chain);
  private inApp: Wallet<"inApp"> | null = null;
  private eoaAccount: Account | null = null;
  private smartAccount: Account | null = null;
  private connectUserInfo: connectUser | null = null; // PRIVATE (public just for testing)
  private hashId: string = "";
  public payloadExpiresAt: Date | null = null;

  // INCLUDE LOGIN METHOD AND VALUE
  public async connectUser(create: boolean = true) {
    try {
      console.log("Connecting User...");
      const res = await this._readCC();
      const ccIdentity = res[0];
      let encryptionKey = res[1];
      this.hashId = this._buildHashId(ccIdentity); // new Indices can be the same as indices
      console.log("HashID", this.hashId);

      // Save temp user
      if (create) {
        try {
          await this.axiosInstance.post(`/CC/createTemp`, {
            hashId: this.hashId,
          });
          // eslint-disable-next-line
        } catch (error: any) {
          const message = error.response?.data?.message;
          const status = error.response?.status;
          if (
            message &&
            message !== "Erro" &&
            status &&
            [400, 403, 409].includes(status)
            // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
          )
            showToast(error.response.data.message, "error", 3000);
          else showToast("Erro ao procurar utentes a partilhar", "error", 3000);
          throw new Error("Error");
        }
      }
      // Connect Wallet
      encryptionKey = utils.keccak256(
        utils.toUtf8Bytes(this.hashId + encryptionKey)
      );
      const _eoaAccount = await this._connectWallet(this.hashId, encryptionKey);
      // Create Payload
      const payload = (
        await this.axiosInstance.get(`auth/payloadCC/${_eoaAccount.address}`)
      ).data as LoginPayload;
      // Sign payload
      const signResult = await signLoginPayload({
        account: _eoaAccount,
        payload,
      });
      // Create CC
      this.connectUserInfo = {
        payload: payload as LoginPayload,
        signatureCC: signResult.signature,
        walletCC: _eoaAccount.address,
        smartWalletCC: this.smartAccount!.address,
        identity: ccIdentity,
        hashId: this.hashId,
      };
      this.payloadExpiresAt = new Date(payload.expiration_time);
      console.log("success");
    } catch (error) {
      console.error("Error", error);
      throw new Error("Error");
    }
  }

  public async logout() {
    try {
      if (this.inApp) {
        await this.inApp.disconnect();
        this.inApp = null;
        this.eoaAccount = null;
        this.smartAccount = null;
        this.connectUserInfo = null;
      }
      return true;
    } catch (error) {
      console.error("CC logout error", error);
      return false;
    }
  }

  public async updateUser() {
    try {
      const res = await this._readCC(false);
      const ccIdentity = res[0];
      let encryptionKey = res[1];
      const hashId = this._buildHashId(ccIdentity); // new Indices can be the same as indices
      // Connect Wallet
      encryptionKey = utils.keccak256(
        utils.toUtf8Bytes(hashId + encryptionKey)
      );
      const _eoaAccount = await this._connectWallet(hashId, encryptionKey);
      // Create Payload
      const payload = (
        await this.axiosInstance.get(`auth/payloadCC/${_eoaAccount.address}`)
      ).data as LoginPayload;
      // Sign payload
      const signResult = await signLoginPayload({
        account: _eoaAccount,
        payload,
      });
      // Update CC
      if (!this.eoaAccount || !this.smartAccount) throw new Error("Error"); // Will not happen
      const updateCC: updateCC = {
        payload: payload as LoginPayload,
        signatureCC: signResult.signature,
        walletCC: this.eoaAccount.address,
        smartWalletCC: this.smartAccount.address,
        identity: ccIdentity,
        hashId: hashId,
      };
      await this.axiosInstance.post(`/CC/update`, updateCC);
      showToast("Informação atualizada com sucesso", "success", 3000);
      await this.logout();
      return true;
    } catch (error: any) {
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao procurar utentes a partilhar", "error", 3000);
      throw new Error("Error");
    }
  }

  public async createCCOTP(
    verificationCode: string,
    loginMethod: ThirdwebLoginMethod,
    loginValue: string
  ): Promise<boolean> {
    try {
      //console.log("createCCOTP", this.createCC, this.hashId, this.smartAccount);
      if (!this.connectUserInfo) throw new Error("No createCC");
      const createCC: createCCOTP = {
        ...this.connectUserInfo,
        loginMethod,
        loginValue,
        verificationCode,
      };
      console.log("CC createCCOTP", createCC);
      await this.axiosInstance.post("cc/create", createCC, {
        withCredentials: true,
        timeout: 15000,
      });
      //console.log("CC createCCOTP SUCESS", out);
      showToast("Conta criada com sucesso", "success", 3000);
      await this.logout();
      return true;
      // eslint-disable-next-line
    } catch (error: any) {
      console.error("CC createCCOTP error", error);
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao criar a conta", "error", 3000);
      throw new Error("Erro");
    }
  }

  public async sendOtpSms(phoneNumberWithCode: string): Promise<void> {
    if (!this.connectUserInfo) return; // Should not happen
    try {
      const response = await this.axiosInstance.post(
        `/otp/send-sms/${this.hashId}/${phoneNumberWithCode}/${this.connectUserInfo.identity.countryAbbv}`,
        {}
      );
      if (!response) throw new Error("No response");
      showToast("Código de verificação enviado", "success", 3000);
      // eslint-disable-next-line
    } catch (error: any) {
      console.error("CC sendOtpSms error", error);
      const status = error.response?.status;
      const message = error.response?.data?.message;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao enviar código de verificação", "error", 3000);
      throw new Error("Error");
    }
  }

  public async sendOtpEmail(email: string): Promise<void> {
    if (!this.connectUserInfo) return; // Should not happen
    try {
      //console.log("sendOtpEmail", email, this.hashId, this.createCC);
      const response = await this.axiosInstance.post(
        `/otp/send-email/${this.hashId}/${email}/${this.connectUserInfo.identity.countryAbbv}`,
        {}
      );
      if (!response) throw new Error("No response");
      showToast("Código de verificação enviado", "success", 3000);
      // eslint-disable-next-line
    } catch (error: any) {
      console.error("CC sendEmailOtp error", error);
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao enviar código de verificação", "error", 3000);
      throw new Error("Error");
    }
  }

  public async giveConsent(blockchainGiveParams: BlockchainGiveParams) {
    if (!this.eoaAccount) return; // Should not happen
    return await this.blockchain.giveConsent(blockchainGiveParams);
  }

  private async _readCC(
    withPhoto: boolean = true
  ): Promise<[CCIdentity, string]> {
    let _toastMessage = "Erro ao ler o cartão";
    try {
      const response = await fetch("http://localhost:5000/getData", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Erro");
      const responseText = await response.text();
      if (responseText) {
        if (responseText.includes("birth")) {
          // eslint-disable-next-line prefer-const
          let { documentNumber, valid, ...identity } = JSON.parse(responseText);
          console.log("CC data", identity, documentNumber);
          if (!(valid as string).includes("encontra-se activo")) {
            _toastMessage = "Cartão Invalido";
            throw new Error("Error");
          }
          identity = identity as CCIdentity;
          const [_day, _month, _year] = identity.expiresAt.split(" ");
          identity.expiresAt = new Date(
            Number(_year),
            Number(_month),
            Number(_day)
          );
          identity.birth = identity.birth.replace(/\s/g, "-");
          if (identity.expiresAt < new Date()) {
            _toastMessage = "Cartão expirado";
            throw new Error("Error");
          }
          if (withPhoto) identity.photo = await this._readCCPhoto();

          // Remove last digit from docId
          identity.docId = identity.docId.slice(0, -1);
          return [identity, documentNumber];
        } else if (responseText.includes("No reader found")) {
          _toastMessage = "Nenhum leitor de cartões detectado";
          throw new Error("Error");
        } else if (responseText.includes("No card inserted")) {
          _toastMessage = "Nenhum cartão detectado";
          throw new Error("Error");
        } else if (responseText.includes("Error reading card")) {
          _toastMessage =
            "Erro ao ler cartão. Certifique-se que o cartão de cidadão está inserido corretamente";
        } else {
          //responseText.includes("Error")
          _toastMessage = "Houve um erro ao ler o cartão";
        }
        throw new Error("Error");
      } else throw new Error("Error");
    } catch (error: any) {
      if (error.message.includes("Failed to fetch")) {
        _toastMessage =
          "Erro ao ler o cartão. Certifique-se que tem a app a correr";
      }
      showToast(_toastMessage, "error", 3000);
      throw new Error("Error");
    }
  }

  private async _readCCPhoto(): Promise<string | null> {
    try {
      const response = await fetch("http://localhost:5000/getPhoto", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const responseText = await response.text();
      if (responseText && responseText.includes("photo")) {
        return JSON.parse(responseText).photo;
      }
      return null;
    } catch {
      return null;
    }
  }

  private async _connectWallet(
    hash: string,
    encryptionKey: string
  ): Promise<Account> {
    try {
      // Thirdweb
      console.log("Connecting Wallet...");
      if (this.inApp) await this.inApp.disconnect();
      this.inApp = inAppWallet();
      const eoaAccount = await this.inApp.connect({
        client: this.client,
        chain: this.chain,
        strategy: "auth_endpoint",
        payload: JSON.stringify({ userId: hash }),
        encryptionKey: encryptionKey,
      });
      const smart = smartWallet({
        factoryAddress: FACTORY_ADDRESS,
        gasless: true,
        chain: this.chain,
      });
      const smartAccount = await smart.connect({
        client: this.client,
        personalAccount: eoaAccount,
      });
      console.log("CC user accounts", eoaAccount, smartAccount);
      this.eoaAccount = eoaAccount;
      this.smartAccount = smartAccount;
      this.blockchain.updateAccounts(eoaAccount, smartAccount);
      return eoaAccount;
    } catch (error) {
      console.error("_connectWallet error", error);
      throw new Error("Error");
    }
  }

  private _buildHashId(ccData: CCIdentity): string {
    const constantProperties = [
      ccData.birth,
      ccData.docId,
      ccData.taxNumber,
      ccData.healthNumber,
      ccData.socialSecurityNumber,
      ccData.countryAbbv,
    ];
    return keccak256(toUtf8Bytes(constantProperties.join("")));
  }
}
