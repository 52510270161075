// React
import React, { memo, useContext, useEffect, useState } from "react";

// Internal
import { UserContext } from "../../../services/user";
import { userByIndentity } from "../../../types";
import { transformDateString } from "../../../utils/dateTimeUtils";
import showToast from "../../../components/toast";

import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";
import { IoIosClose } from "react-icons/io";
import { HiArrowLongLeft } from "react-icons/hi2";

// Add TypeScript types for props
interface Card0PatientProps {
  selectedUser: userByIndentity | null;
  setSelectedUser: (categories: userByIndentity | null) => void;
  nextStep: () => void;
  prevStep?: () => void;
}

const Card0Patient = ({
  selectedUser,
  setSelectedUser,
  nextStep,
  prevStep,
}: Card0PatientProps) => {
  // Affiliation context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const [userCC, setUserCC] = useState("");
  const [birth, setBirth] = useState("");

  // Handle action with Enter
  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAction();
      }
    };

    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [userCC, birth, selectedUser]);

  // Get the user by KYC
  const userByIdentity = async () => {
    try {
      const formatedBirth = transformDateString(birth);
      if (!formatedBirth) {
        showToast("Data de nascimento mal formatada", "error", 3000);
        return;
      }
      const response = await userContext.axiosInstance.post(
        `users/userByIdentity/`,
        { docId: userCC, birth: formatedBirth }
      );
      if (response.data.length === 0) {
        showToast("Utilizador não encontrado", "error", 3000);
      }
      setSelectedUser(response.data[0]);
      // eslint-disable-next-line
    } catch (error: any) {
      const message = error.response?.data?.message;
      const status = error.response?.status;
      if (
        message &&
        message !== "Erro" &&
        status &&
        [400, 403, 409].includes(status)
        // 400 = bad request, 403 = forbidden, 409 = conflict, 500 = internal server error
      )
        showToast(error.response.data.message, "error", 3000);
      else showToast("Erro ao buscar utente", "error", 3000);
    }
  };

  const handleAction = () => {
    selectedUser ? nextStep() : userByIdentity();
  };

  return (
    <div className="flex flex-col h-full">
      <CardHeader>
        <CardTitle>Pedir consentimento</CardTitle>
        <CardDescription>Insira os dados do utente</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-2">
        <div>
          <div className="grid gap-2">
            <Label htmlFor="subject">Utente</Label>
            <Input
              id="subject"
              placeholder="Insira nº do Cartão de Cidadão"
              value={userCC}
              onChange={(e) => setUserCC(e.target.value)}
              disabled={false}
              name="cc"
            />
          </div>

          <div className="grid gap-2 mt-8">
            <Label htmlFor="subject">Data de nascimento</Label>
            <Input
              id="subject"
              placeholder="dd/mm/aaaa"
              value={birth}
              onChange={(e) => setBirth(e.target.value)}
              disabled={false}
              name="birth"
            />
          </div>
        </div>
        {selectedUser && (
          <div className="grid grid-cols-[auto,1fr,auto] gap-4 items-center border p-2 rounded-md relative">
            <Avatar className="h-12 w-12 flex-shrink-0">
              <AvatarImage
                src={
                  selectedUser.photo
                    ? `data:image/png;base64,${selectedUser.photo}`
                    : // eslint-disable-next-line
                      require("../../../components/images/profile.jpg")
                }
                alt="@shadcn"
              />
              <AvatarFallback>RG</AvatarFallback>
            </Avatar>
            <div className="flex flex-col text-sm min-w-0">
              <span className="font-semibold break-words">
                {selectedUser.name}
              </span>
              <span className="break-words">{selectedUser.birth}</span>
            </div>
            <button
              className="flex items-center justify-center h-6 w-6 rounded-full hover:bg-gray-200 transition-colors"
              onClick={() => setSelectedUser(null)}
            >
              <IoIosClose className="text-neutral-600" />
            </button>
          </div>
        )}
      </CardContent>
      <CardFooter className="justify-between space-x-2 mt-auto">
        <div
          className="flex flex-inline cursor-pointer text-sm items-center space-x-2 px-4 py-2 hover:bg-gray-200 rounded-lg transition-all duration-300"
          onClick={prevStep}
        >
          <HiArrowLongLeft className="w-6 h-6" />
          <span>Voltar</span>
        </div>
        <Button onClick={handleAction} className="">
          {selectedUser ? "Próximo" : "Procurar Utente"}
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(Card0Patient);
