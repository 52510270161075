import React, { useState, useContext, useEffect, useCallback } from "react";
import { UserContext } from "../../services/user";
import { Button } from "../../components/ui/button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../components/ui/otp-input";
import showToast from "../../components/toast";
import { motion } from "framer-motion";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";
import { HiArrowLongLeft } from "react-icons/hi2";
import debounce from "lodash/debounce";

const Pin = () => {
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const mode = userContext.user.lastPin ? "validate" : "create";
  const [pin, setPin] = useState("");
  const [pin2, setPin2] = useState("");
  const [confirm, setConfirm] = useState(false);

  // Debounced functions
  const debouncedCreatePin = useCallback(
    debounce(async () => {
      if (pin !== pin2) {
        showToast("Os PINs não coincidem", "error", 3000);
        resetCreate();
        return;
      }
      try {
        const response = await userContext.axiosInstance.post("/pin/create", {
          pin,
        });
        if (!response?.data) throw new Error("Erro");
        const lastPin = new Date(response.data);
        userContext.setUser({ ...userContext.user, lastPin });
        showToast("PIN criado com sucesso", "success", 3000);
      } catch (error) {
        console.error(error);
        showToast("Erro ao criar o PIN", "error", 3000);
      }
    }, 300),
    [pin, pin2, userContext]
  );

  const debouncedVerifyPin = useCallback(
    debounce(async () => {
      if (pin.length !== 6) {
        showToast("Introduza um PIN válido", "error", 3000);
        return;
      }
      try {
        const response = await userContext.axiosInstance.get(
          `/pin/verify/${pin}`
        );
        if (!response) throw new Error("Erro");
        const [valid, lastPin] = response.data;
        if (!valid) {
          showToast("PIN inválido, tente novamente", "error", 3000);
          setPin("");
        } else {
          userContext.setUser({
            ...userContext.user,
            lastPin: new Date(lastPin),
          });
        }
      } catch (error) {
        console.error(error);
        showToast("Erro ao verificar o PIN", "error", 3000);
      }
    }, 300),
    [pin, userContext]
  );

  const onSubmit = useCallback(() => {
    mode === "create"
      ? confirm
        ? debouncedCreatePin()
        : setConfirm(true)
      : debouncedVerifyPin();
  }, [mode, confirm, debouncedCreatePin, debouncedVerifyPin]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && (confirm ? pin2.length : pin.length) === 6) {
        onSubmit();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [pin, pin2, confirm, onSubmit]);

  const resetCreate = useCallback(() => {
    setPin("");
    setPin2("");
    setConfirm(false);
  }, []);

  const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      pathLength: 1,
      fill: "rgba(255, 255, 255, 1)",
    },
  };

  // Extracted path data from your SVG
  const paths = [
    "M81.53,570.8c-27.06-51.1-41.36-108.84-41.36-166.99c0-86.99,31.63-170.82,89.05-236.04 C186.01,103.28,263.89,61.4,348.57,49.8c2.59,166.55,140.08,301.94,306.74,301.94c28.23,0,56.23-3.86,83.25-11.46 C734.99,429,673.08,486.2,543.79,520.24C424.56,551.63,257.95,560.95,81.53,570.8z",
    "M397.53,761.17c-62.18,0-123.41-16.21-177.07-46.89c-51.81-29.62-95.75-72.09-127.14-122.89 331.3-18.53,617.57-34.67,660.88-209.8c0.46,7.38,0.69,14.84,0.69,22.22c0,48.24-9.45,95.04-28.08,139.1 c-18,42.55-43.77,80.77-76.59,113.59c-32.82,32.82-71.04,58.59-113.59,76.59C492.58,751.72,445.78,761.17,397.53,761.17z",
  ];

  return (
    <div className="flex bg-white h-screen w-screen items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 795.06 810.89"
            width="60"
            height="60"
          >
            {paths.map((d, index) => (
              <motion.path
                key={index}
                d={d}
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 1.5, ease: "circInOut" },
                  fill: { duration: 1.7, ease: [1, 0, 0.8, 1] },
                }}
                stroke="black"
                fill="black"
                strokeWidth="4"
                style={{ fill: "black" }} // Ensure fill is applied directly
              />
            ))}
          </svg>
        </div>
        <h1 className="text-2xl font-bold text-center mt-5 pb-3">
          Código de Segurança
        </h1>
        <InputOTP
          maxLength={6}
          value={confirm ? pin2 : pin}
          onChange={confirm ? setPin2 : setPin}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        <span className="text-xs text-gray-400 mt-2">
          {mode === "create"
            ? !confirm
              ? "Crie o seu PIN de segurança"
              : "Confirme o seu PIN de segurança"
            : "Introduza o seu PIN de segurança"}
        </span>
        <Button
          variant="secondary"
          className="w-full mt-5 bg-black hover:bg-gray-600 text-white"
          onClick={onSubmit}
          disabled={confirm ? pin2.length !== 6 : pin.length !== 6}
        >
          {mode === "create" ? (!confirm ? "Criar" : "Confirmar") : "Verificar"}
        </Button>

        {confirm && (
          <Button
            variant="secondary"
            className="w-full mt-5"
            onClick={resetCreate}
          >
            Voltar
          </Button>
        )}
      </div>
      <span
        onClick={() => userContext.logout(true)}
        className="fixed top-0 right-0 m-4 flex items-center justify-center px-5 py-2 text-sm text-gray-700 duration-300 bg-white rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700 hover:cursor-pointer"
      >
        <HiArrowLongLeft className="w-6 h-6" />
        <span>Terminar sessão</span>
      </span>
    </div>
  );
};

export default Pin;
