import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";
import {
  updateProfileDto,
  userIdentity,
  userIdentityLabels,
} from "../../../types";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { Input } from "../../../components/ui/input";
import { PhoneInputLight } from "../../../components/ui/phone-input-light";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Button } from "../../../components/ui/button";
import {
  filterChanges,
  showCancelAndDisableUpdate,
} from "../../../utils/compareEntity";
import { formatDateProfile } from "../../../utils/dateTimeUtils";
import {
  profileNameMax,
  profileNameMaxWords,
} from "../../../environment/variables";
import { UserContext } from "../../../services/user";
import showToast from "../../../components/toast";
import { isEmail, stringInLimits } from "../../../utils/stringUtils";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";
import InputWithinLimits from "../../../components/ui/inputWithinLimits";

const ProfileTab = () => {
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const identity = userContext.user.identities[0];

  const [countryCode, setCountryCode] = useState<string | undefined>("PT");
  const [updateProfile, setUpdateProfile] = useState<updateProfileDto>(
    userContext.user.profile
  );
  const { toUpdateProfile, showCancelProfile, disableUpdateProfile } =
    useMemo(() => {
      const update = filterChanges(userContext.user.profile, updateProfile);
      const [showCancel, disableUpdate] = showCancelAndDisableUpdate(update);
      return {
        toUpdateProfile: update as updateProfileDto,
        showCancelProfile: showCancel,
        disableUpdateProfile: disableUpdate,
      };
    }, [userContext.user.profile, updateProfile]);

  const handleOnChangeProfile = useCallback(
    (key: keyof updateProfileDto, value: string | boolean) => {
      setUpdateProfile((prevState) => {
        if (prevState === null) throw new Error("updating null");
        return { ...prevState, [key]: value };
      });
    },
    []
  );

  const handleCancelProfile = useCallback(() => {
    setUpdateProfile(userContext.user.profile || {});
  }, [userContext.user.profile]);

  const submitUpdateProfile = useCallback(async () => {
    if (toUpdateProfile.email && !isEmail(toUpdateProfile.email)) {
      // Empty string
      showToast(`Email inválido`, "error", 1500);
      return false;
    }
    if (
      toUpdateProfile.phone &&
      !isValidPhoneNumber(toUpdateProfile.phone, countryCode as CountryCode)
    ) {
      showToast(`Numero de telemóvel inválido`, "error", 1500);
      return false;
    }
    if (
      toUpdateProfile.name &&
      !/^(\w+\s+){1,2}\w+$/.test(toUpdateProfile.name)
    ) {
      showToast(
        `O seu nome de perfil deve ter entre 2 a 3 palavras, com no minimo 3 caracteres cada`,
        "error",
        3000
      );
      return false;
    }
    try {
      const { data } = await userContext.axiosInstance.patch(
        "users",
        toUpdateProfile
      );
      userContext.setUser({
        ...userContext.user,
        profile: data,
      });
      showToast("Informação atualizada com sucesso", "success", 3000);
    } catch (error) {
      console.error("Error updating:", error);
      showToast("Erro ao atualizar informação", "error", 3000);
    }
  }, [userContext, updateProfile]);
  return (
    <>
      <div className="flex items-start flex-col w-full space-y-1">
        <p className="text-sm font-semibold text-gray-800">Fotografia</p>
        <Avatar className="h-32 w-32 -z-0">
          <AvatarImage
            src={
              userContext.user.profile.photo
                ? `data:image/png;base64,${userContext.user.profile.photo}`
                : "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fHw%3D"
            }
            alt="@shadcn"
          />
          <AvatarFallback>RG</AvatarFallback>
        </Avatar>
        <p className="text-xs font-semibold text-gray-500">
          {" "}
          Upload de uma fotografia recente e com qualidade.{" "}
        </p>
      </div>
      <div className="flex items-start flex-col w-full space-y-1">
        <div>
          <p className="text-sm font-semibold text-gray-800">Nome</p>
          <Input
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              const words = value.trim().split(/\s+/); // count words
              const regex = /^[A-Za-zÀ-ÿ\s]*$/; // Only letters and spaces
              const preventMultipleSpaces = !/\s{2,}/.test(value); // Prevent consecutive spaces
              if (
                value.length <= profileNameMax &&
                (words.length < profileNameMaxWords ||
                  (words.length === profileNameMaxWords &&
                    !value.endsWith(" "))) && // Dont allow spaces after max words
                regex.test(value) &&
                preventMultipleSpaces
              )
                handleOnChangeProfile("name", value);
            }}
            value={updateProfile.name || ""}
            placeholder="Pedro Silva"
            className="border-stone-200"
            //onClear={() =>
            //  handleOnChangeProfile("name", userContext.user.profile.name || "")
            //}
          />
          <InputWithinLimits text={updateProfile.name} max={profileNameMax} />
        </div>
      </div>
      <div className="flex items-start flex-col w-full space-y-1 ">
        <p className="text-sm font-semibold text-gray-800">Email</p>
        <Input
          type="text"
          onChange={(e) => handleOnChangeProfile("email", e.target.value)}
          value={updateProfile.email || ""}
          placeholder="emailExemplo@gmail.com"
          className="border-stone-200"
          //onClear={() =>
          //  handleOnChangeProfile("email", userContext.user.profile.email || "")
          //}
        />
      </div>
      <div className="flex items-start flex-col w-full space-y-1 ">
        <p className="text-sm font-semibold text-gray-800">
          Número de Telémovel
        </p>
        <PhoneInputLight
          className="w-full mt-3 placeholder-gray-500"
          //defaultCountry="PT"
          // shadcn, providing both values and default values, prob
          onChange={(value) => handleOnChangeProfile("phone", value)}
          onCountryChange={(value) => {
            setCountryCode(value);
          }}
          placeholder="913646787"
          value={updateProfile.phone || ""}
          initialValueFormat="national"
          //onClear={() =>
        />
      </div>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="foda-se">
          <AccordionTrigger className="text-xl">
            <p>Informações Pessoais</p>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col md:grid md:grid-cols-2 gap-x-10 gap-y-5">
              {Object.keys(userIdentityLabels).map((key) => {
                const label =
                  userIdentityLabels[key as keyof typeof userIdentityLabels];
                let value = identity ? identity[key as keyof userIdentity] : ""; // Default
                if (key === "expiresAt")
                  value = formatDateProfile(value as string);
                if (value === null) value = "";
                return (
                  <div className="flex flex-col space-y-2" key={key}>
                    <p className="text-sm font-semibold">{label}</p>
                    <Input
                      className="border-slate-100"
                      value={value as string}
                      disabled={true}
                    />
                  </div>
                );
              })}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="justify-end flex space-x-2 pt-10 pb-16 lg:p-0 lg:pb-10">
        {showCancelProfile && (
          <Button onClick={handleCancelProfile} variant="secondary">
            Cancelar
          </Button>
        )}
        <Button
          className="px-6"
          variant="default"
          onClick={() => submitUpdateProfile()}
          disabled={disableUpdateProfile}
        >
          Aplicar Alterações
        </Button>
      </div>
    </>
  );
};

export default memo(ProfileTab);
