import React, { useEffect, useRef, useState } from "react";
import { Slider } from "../../components/ui/slider";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../../components/ui/drawer";
import { Button } from "../../components/ui/button";
import { parseImage } from "../../dicom.ts/src/parser";
import Renderer from "../../dicom.ts/src/renderer/Renderer";
import { BACKEND_DOMAIN } from "../../environment/variables";

const DicomSimple: React.FC = () => {
  const [buffer, setBuffer] = useState<ArrayBuffer | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [dicomFiles, setDicomFiles] = useState<{ [key: string]: string[] }>({});
  const [currentFolder, setCurrentFolder] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [metadata, setMetadata] = useState<any>(null);

  useEffect(() => {
    fetchDicomFiles();
  }, []); // Only re-run the effect if buffer changes

  const fetchDicomFiles = async () => {
    const response = await fetch(`${BACKEND_DOMAIN}/dicom`);
    const data = await response.json();
    setDicomFiles(data);
    const firstFolder = Object.keys(data)[2];
    setCurrentFolder(firstFolder);
    // Set currentIndex to the middle index of the first folder
    setCurrentIndex(Math.floor(data[firstFolder].length / 2));
  };

  const displayDicom = async (
    canvas: HTMLCanvasElement,
    buffer: ArrayBuffer
  ) => {
    try {
      console.log("metadata correct infinite call");
      // get the DCM image
      const image = parseImage(buffer);
      if (image) {
        // Extract metadata: Future get more metadata
        const metadata = Object.fromEntries(
          Object.entries({
            PatientID: image.patientID,
            PatientName: image.getTagValue([0x0010, 0x0010]),
            StudyDate: image.getTagValue([0x0008, 0x0020]),
            Modality: image.getTagValue([0x0008, 0x0060]),
            Manufacturer: image.getTagValue([0x0008, 0x0070]),
            InstitutionName: image.getTagValue([0x0008, 0x0080]),
            ReferringPhysicianName: image.getTagValue([0x0008, 0x0090]),
            SeriesDescription: image.getTagValue([0x0008, 0x103e]),
            PatientBirthDate: image.getTagValue([0x0010, 0x0030]),
            PatientSex: image.getTagValue([0x0010, 0x0040]),
            PatientAge: image.getTagValue([0x0010, 0x1010]),
            BodyPartExamined: image.getTagValue([0x0018, 0x0015]),
            SliceThickness: image.getTagValue([0x0018, 0x0050]),
            KVP: image.getTagValue([0x0018, 0x0060]),
            PixelSpacing: image.getTagValue([0x0028, 0x0030]),
            WindowCenter: image.getTagValue([0x0028, 0x1050]),
            WindowWidth: image.getTagValue([0x0028, 0x1051]),
            InstanceNumber: image.getTagValue([0x0020, 0x0013]),
          }).filter(([_, value]) => value !== null)
        );
        setMetadata(metadata);

        // Create the renderer
        const renderer = new Renderer(canvas);

        // Decode and display frame 0 on the canvas
        await renderer.render(image, 0);

        return metadata; // Return the metadata for use in the drawer
      }
    } catch (e) {
      console.error("Error displaying DICOM:", e);
    }
  };

  useEffect(() => {
    const fetchDicomFile = async () => {
      try {
        if (!currentFolder || dicomFiles[currentFolder].length === 0) return;

        // Fetch the DICOM file from the public folder
        const response = await fetch(
          `/dicom/DICOM/000001F4/AA302E2E/AA00B0BE/${currentFolder}/${dicomFiles[currentFolder][currentIndex]}`
        );
        const arrayBuffer = await response.arrayBuffer();
        setBuffer(arrayBuffer);

        if (canvasRef.current) {
          await displayDicom(canvasRef.current, arrayBuffer);
        }
      } catch (error) {
        console.error("Error fetching or parsing DICOM file:", error);
      }
    };

    fetchDicomFile();
  }, [currentIndex, currentFolder, dicomFiles]);

  const dicomMetadataDrawer = () => {
    return (
      <Drawer>
        <DrawerTrigger>
          <Button className="mt-3">Metadados</Button>
        </DrawerTrigger>
        <DrawerContent className="px-[20%]">
          <DrawerHeader>
            <DrawerTitle className="text-xl">Metadata</DrawerTitle>
            <DrawerClose />
            <DrawerDescription className="text-lg">
              {metadata ? (
                <div className="flex">
                  <div className="w-1/2 pr-5">
                    {Object.entries(metadata)
                      .slice(0, Math.ceil(Object.keys(metadata).length / 2))
                      .map(([key, value], index, array) => (
                        <React.Fragment key={key}>
                          <div className="flex justify-between">
                            <span className="font-semibold text-right">
                              {key}:
                            </span>
                            <span className="text-left">{String(value)}</span>
                          </div>
                          {index < array.length - 1 && (
                            <hr className="my-3 border-t border-gray-300" />
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="w-1/2 pl-5">
                    {Object.entries(metadata)
                      .slice(Math.ceil(Object.keys(metadata).length / 2))
                      .map(([key, value], index, array) => (
                        <React.Fragment key={key}>
                          <div className="flex justify-between">
                            <span className="font-semibold text-right">
                              {key}:
                            </span>
                            <span className="text-left">{String(value)}</span>
                          </div>
                          {index < array.length - 1 && (
                            <hr className="my-3 border-t border-gray-300" />
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="text-lg">No metadata available</div>
              )}
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    );
  };

  // Add event listener for scroll
  useEffect(() => {
    let scrollAccumulator = 0;
    const scrollThreshold = 10; // Number of scroll units before changing image

    const handleScroll = (event: WheelEvent) => {
      if (event.target === canvasRef.current) {
        event.preventDefault(); // Prevent the rest of the page from scrolling

        // Accumulate scroll
        scrollAccumulator += event.deltaY;

        // Check if we've reached the threshold
        if (Math.abs(scrollAccumulator) >= scrollThreshold) {
          const direction = scrollAccumulator > 0 ? 1 : -1;

          setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + direction;

            // Ensure new index is within bounds
            return Math.max(
              0,
              Math.min(dicomFiles[currentFolder].length - 1, newIndex)
            );
          });

          // Reset accumulator
          scrollAccumulator = 0;
        }

        // Log scroll details for debugging
        console.log(
          `Scroll event - deltaY: ${event.deltaY}, accumulator: ${scrollAccumulator}`
        );
      }
    };

    if (canvasRef.current) {
      canvasRef.current.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }
    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeEventListener("wheel", handleScroll);
      }
    };
  }, [dicomFiles, currentFolder]);

  const renderThumbnail = async (
    folder: string,
    file: string,
    canvas: HTMLCanvasElement
  ) => {
    try {
      const response = await fetch(
        `/dicom/DICOM/000001F4/AA302E2E/AA00B0BE/${folder}/${file}`
      );
      const arrayBuffer = await response.arrayBuffer();
      await displayDicom(canvas, arrayBuffer);
    } catch (error) {
      console.error("Error fetching or parsing DICOM file:", error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <canvas
          ref={canvasRef}
          className="border border-black w-full mb-2 rounded-lg"
        />
        <Slider
          className="w-full my-2"
          value={[currentIndex]}
          defaultValue={[0]}
          max={
            !dicomFiles[currentFolder]
              ? 0
              : dicomFiles[currentFolder].length - 1
          }
          min={0}
          step={1}
          onValueChange={(value) => setCurrentIndex(value[0])}
        />
        <div className="flex overflow-x-scroll w-full max-w-full">
          {Object.keys(dicomFiles).map((folder) => {
            const middleIndex = Math.floor(dicomFiles[folder].length / 2);
            const middleFile = dicomFiles[folder][middleIndex];
            return (
              <div
                key={folder}
                className="cursor-pointer flex-shrink-0 w-1/4 p-2"
                onClick={() => {
                  setCurrentFolder(folder);
                  setCurrentIndex(Math.floor(dicomFiles[folder].length / 2));
                }}
              >
                <div className="bg-black">
                  <canvas
                    className="w-full h-full"
                    ref={(canvas) => {
                      if (canvas) {
                        renderThumbnail(folder, middleFile, canvas);
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {dicomMetadataDrawer()}
      </div>
    </div>
  );
};

export default DicomSimple;
