import React from "react";
import { recordType } from "../../types";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { Separator } from "../../components/ui/separator";
import { CalendarIcon, Building2Icon, FolderIcon } from "lucide-react";

interface PdfDetailProps {
  record: recordType;
}

function PdfDetail({ record }: PdfDetailProps) {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div className="flex flex-col order-1 md:order-2">
          <CardHeader>
            <CardTitle className="text-2xl md:text-3xl font-bold">
              {record.name}
            </CardTitle>
            <Badge variant="secondary" className="mt-2 w-fit">
              {record.type}
            </Badge>
          </CardHeader>
          <CardContent className="flex-grow">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Building2Icon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Organização
                  </p>
                  <p className="text-base md:text-lg font-semibold">
                    {record.organization}
                  </p>
                </div>
              </div>
              <Separator className="my-2 bg-gray-400" />
              <div className="flex items-center space-x-3">
                <FolderIcon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Departamento
                  </p>
                  <p className="text-base md:text-lg font-semibold">
                    {record.department}
                  </p>
                </div>
              </div>
              <Separator className="my-2 bg-gray-400" />
              <div className="flex items-center space-x-3">
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Data</p>
                  <p className="text-base md:text-lg font-semibold">
                    {record.date}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </div>
        <div className="rounded-lg border shadow-md h-[770px] overflow-hidden order-2 md:order-1">
          <iframe
            src={`${process.env.PUBLIC_URL}/pdf/${record.pdfUrl}#view=FitH&scrollbar=0`}
            title={record.name}
            width="100%"
            height="100%"
            className="border-none scale-[1.35] origin-top-left"
            style={{
              width: "calc(100% / 1.35)",
              height: "calc(100% / 1.35)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PdfDetail;
