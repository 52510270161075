// Ui
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlinePayments,
} from "react-icons/md";
import { RiUserAddLine } from "react-icons/ri";
import { IoMdHelp } from "react-icons/io";
import { FaRegAddressCard } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

import { LogOut, Moon, Settings, Sun, Users, Wallet } from "lucide-react";

import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../services/user";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import SourceSwitcher from "./source-switcher";
import { useTheme } from "./theme-provider";
import { toast } from "./ui/use-toast";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { useNavigateIfPathDiffers } from "../utils/navigateIfDiffers";
import { FaRegUser } from "react-icons/fa";
import { Separator } from "./ui/separator";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import CreateCC from "../pages/consents/CC/create";
import UpdateCC from "../pages/consents/CC/update";
import { CgNotes } from "react-icons/cg";
import InternalConsentsCard from "../pages/internalConsents/InternalconsentCard";

interface NavBarInterface {
  isLoading: boolean;
}

const PersonalAccountLinks = [
  {
    label: "Consentimentos",
    link: "/consents",
  },
];

const ProfessionalLinks = [
  {
    label: "Utentes",
    link: "/people",
  },
  {
    label: "Consentimentos",
    link: "/consents",
  },
];

const MobileLinks = [
  {
    label: "Perfil",
    link: "/settings?tab=0",
    icon: <FaRegUser className="text-black" size={18} />,
  },
  {
    label: "Afiliações",
    link: "/settings?tab=1",
    icon: <Users className="text-black" size={18} />,
  },
  {
    label: "Pagamentos",
    link: "/settings?tab=3",
    icon: <MdOutlinePayments size={18} />,
  },
  {
    label: "Definições",
    link: "/settings?tab=2",
    icon: <Settings className="text-black" size={18} />,
  },
  {
    label: "Ajuda",
    link: "/settings?tab=4",
    icon: <IoMdHelp className="text-black" size={18} />,
  },
];

const useBreakpoint = () => {
  const [screen, setScreen] = useState("base");

  useEffect(() => {
    const updateScreenSize = () => {
      if (window.matchMedia("(min-width: 1280px)").matches) {
        setScreen("xl");
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        setScreen("md");
      } else {
        setScreen("base");
      }
    };

    // Set the initial value
    updateScreenSize();

    // Add event listener
    window.addEventListener("resize", updateScreenSize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screen;
};

const Navbar = ({ isLoading }: NavBarInterface) => {
  const [isDark, setIsDark] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  //Testing, Dark mode not available
  const toggleTheme = () => {
    setIsDark((prevIsDark) => {
      const newIsDark = !prevIsDark;
      setTheme(newIsDark ? "dark" : "light");
      return newIsDark;
    });
  };

  const { setTheme } = useTheme();

  // Context
  const userContext = useContext(UserContext);

  // Hooks
  const navigate = useNavigate();
  const navigateIfPathDiffers = useNavigateIfPathDiffers();

  // State variables
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Toggle
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const screenSize = useBreakpoint();

  useEffect(() => {
    if (screenSize === "md" || screenSize === "xl") {
      setIsSidebarOpen(false);
    }
  }, [screenSize]);

  return (
    <>
      <nav
        className={`w-screen fixed justify-center z-[1] ${
          isLoading ? "hidden" : "flex"
        } `}
      >
        <div
          className={`flex justify-between w-full max-w-screen-2xl mx-auto  transition-all duration-700 bg-white px-4 py-4 z-50 ${
            isSidebarOpen ? "md:px-12 md:pt-12 px-6 pt-6" : "md:px-8"
          }`}
        >
          {/* If we dont like the animation: "flex justify-between w-full md:px-6 bg-white px-4 py-4 z-20" */}
          <div className="flex items-center hover:cursor-pointer">
            <a
              className="leading-none"
              onClick={() => {
                if (!userContext?.user) return;
                // eslint-disable-next-line
                userContext?.activeAffiliation
                  ? navigateIfPathDiffers("/people")
                  : navigateIfPathDiffers("/consents");
              }}
            >
              <div
                className="flex items-center hover:scale-105 transition-all"
                onClick={() => {
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                <img
                  src={
                    isMobile
                      ? require("./images/humanos-logo-black.png")
                      : require("./images/humanos_logo_text_black.png")
                  }
                  alt="Humanos Logo"
                  className="md:h-7 h-10 w-auto"
                />
              </div>
            </a>
          </div>

          <div className="flex items-center justify-center space-x-2">
            <div className="items-center justify-center space-x-6 flex">
              <div className="items-center justify-center space-x-6 md:flex hidden">
                {userContext?.user &&
                  (userContext?.activeAffiliation
                    ? ProfessionalLinks
                    : PersonalAccountLinks
                  ).map((item, index) => (
                    <span
                      key={index}
                      className="hover:cursor-pointer transition-all hover:scale-110"
                      onClick={() => navigateIfPathDiffers(`${item.link}`)}
                    >
                      {item.label}
                    </span>
                  ))}
              </div>

              <div className="flex items-center">
                <SourceSwitcher className="rounded-full scale-90" />
                <Button
                  variant="outline"
                  size="icon"
                  className="scale-90 md:flex hidden"
                  onClick={() => {
                    toggleTheme();
                    toast({
                      title: "Em breve disponível",
                      description:
                        "A nossa equipa está a trabalhar nesta funcionalidade!",
                    });
                  }}
                >
                  <Sun
                    className={`h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                      isDark ? "-rotate-90 scale-0" : "rotate-0 scale-100"
                    }`}
                  />
                  <Moon
                    className={`absolute h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                      isDark ? "rotate-0 scale-100" : "rotate-90 scale-0"
                    }`}
                  />
                </Button>
                <DropdownMenu onOpenChange={(open) => setIsDropdownOpen(open)}>
                  <DropdownMenuTrigger asChild className="hidden md:flex">
                    <Avatar
                      className={`h-9 w-9 -z-0 ml-3 transition-all ${
                        isDropdownOpen ? "scale-105" : "hover:scale-105"
                      } hover:cursor-pointer`}
                    >
                      <AvatarImage />
                      <AvatarFallback className="bg-gradient-to-br from-teal-400 to-cyan-700 text-white">
                        <FaRegUser size={18} />
                      </AvatarFallback>
                    </Avatar>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56 z-[2]" align="end">
                    <DropdownMenuLabel>Opções</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/settings?tab=0")}
                      >
                        <FaRegUser className="mr-2 h-4 w-4" />
                        <span>Perfil</span>
                      </DropdownMenuItem>
                      {userContext?.user &&
                        userContext.user.affiliations.length > 0 && (
                          <DropdownMenuItem
                            className="hover:cursor-pointer"
                            onClick={() => navigate("/settings?tab=1")}
                          >
                            <Wallet className="mr-2 h-4 w-4" />
                            <span>Afiliações</span>
                          </DropdownMenuItem>
                        )}

                      {userContext?.user && userContext?.activeAffiliation && (
                        <>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            className="flex hover:cursor-pointer"
                            onClick={() => setIsCreateDialogOpen(true)}
                          >
                            <RiUserAddLine className="mr-2 h-4 w-4" />
                            Criar Utente
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="flex hover:cursor-pointer"
                            onClick={() => setIsUpdateDialogOpen(true)}
                          >
                            <FaRegAddressCard className="mr-2 h-4 w-4" />
                            Atualizar Informação
                          </DropdownMenuItem>
                        </>
                      )}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/settings?tab=3")}
                      >
                        <MdOutlinePayments className="mr-2 h-4 w-4" />
                        <span>Pagamentos</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/settings?tab=2")}
                      >
                        <Settings className="mr-2 h-4 w-4" />
                        <span>Definições</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="hover:cursor-pointer"
                        onClick={() => navigate("/settings?tab=4")}
                      >
                        <IoMdHelp className="mr-2 h-4 w-4" />
                        <span>Ajuda</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="bg-neutral-100 hover:cursor-pointer focus:bg-neutral-200"
                      onClick={() => userContext?.logout(true)}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      Terminar Sessão
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <Dialog
                open={isCreateDialogOpen}
                onOpenChange={setIsCreateDialogOpen}
              >
                <DialogTrigger asChild>
                  <span className="hidden"></span>
                </DialogTrigger>
                <DialogContent className="w-full">
                  <CreateCC setIsDialogOpen={setIsCreateDialogOpen} />
                </DialogContent>
              </Dialog>

              <Dialog
                open={isUpdateDialogOpen}
                onOpenChange={setIsUpdateDialogOpen}
              >
                <DialogTrigger asChild>
                  <span className="hidden"></span>
                </DialogTrigger>
                <DialogContent className="w-full">
                  <UpdateCC setIsDialogOpen={setIsUpdateDialogOpen} />
                </DialogContent>
              </Dialog>
            </div>

            <Button
              variant="outline"
              size="icon"
              className=" bg-white border-none hover:bg-white flex md:hidden"
              onClick={() => {
                toggleSidebar();
              }}
            >
              <MdKeyboardArrowDown
                className={`h-[2rem] w-[2rem] transition-all duration-300 ${
                  isSidebarOpen ? "-rotate-90 scale-0 " : "rotate-0 scale-100"
                }`}
              />
              <MdKeyboardArrowUp
                className={`absolute h-[2rem] w-[2rem] transition-all duration-300 ${
                  isSidebarOpen
                    ? "rotate-0 scale-100 bg-red-400 rounded-sm text-red-800 shadow-lg"
                    : "rotate-90 scale-0"
                }`}
              />
            </Button>
          </div>
        </div>

        <div
          className={`fixed top-[4.5rem] z-40 left-0 h-screen w-full shadow-md transition-transform md:duration-700 duration-300 transform ${
            isSidebarOpen ? "translate-y-0" : "-translate-y-full"
          } bg-white text-white flex items-start justify-start`}
        >
          <div className="flex w-screen justify-start flex-col h-screen items-center px-6 pt-6 pb-24">
            {(userContext?.activeAffiliation
              ? ProfessionalLinks
              : PersonalAccountLinks
            ).map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full px-2 hover:bg-neutral-50 transition-all rounded-md hover:cursor-pointer py-3 text-neutral-600"
                onClick={() => {
                  navigateIfPathDiffers(`${item.link}`);
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                <a key={index} className=" text-lg font-light tracking-wide">
                  {item.label}
                </a>
              </div>
            ))}
            <Separator className="my-2" />
            {MobileLinks.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full px-2 hover:bg-neutral-50 transition-all rounded-md hover:cursor-pointer py-3 text-neutral-600"
                onClick={() => {
                  navigateIfPathDiffers(`${item.link}`);
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                <a key={index} className=" text-lg font-light tracking-wide">
                  {item.label}
                </a>
                {item.icon}
              </div>
            ))}
            <div
              className="flex justify-between items-center w-full px-2 hover:bg-neutral-50 transition-all rounded-md hover:cursor-pointer py-3 text-neutral-600"
              onClick={() => {
                toggleTheme(); // Call toggleTheme
                toast({
                  title: "Dark Mode not available",
                  description:
                    "Our developers are working on it, wait for the next update!",
                }); // Call toast with the desired message
              }}
            >
              <p className="text-lg font-light tracking-wide">Theme</p>
              <Button variant="outline" size="icon" className="scale-90 ">
                <Sun
                  className={`h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                    isDark ? "-rotate-90 scale-0" : "rotate-0 scale-100"
                  }`}
                />
                <Moon
                  className={`absolute h-[1.2rem] w-[1.2rem] transition-all duration-300 ${
                    isDark ? "rotate-0 scale-100" : "rotate-90 scale-0"
                  }`}
                />
              </Button>
            </div>
            <Separator className="my-2" />
            <div className="flex flex-col w-full pt-2">
              <Button
                className="text-center text-black hover:bg-neutral-50"
                variant="outline"
                onClick={() => userContext?.logout()}
              >
                Terminar Sessão
              </Button>
            </div>
          </div>
        </div>
      </nav>
      {/*isLoading && <Loading />*/}
    </>
  );
};

export default Navbar;
