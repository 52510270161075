import React, { memo } from "react";

interface ConsentProgressBarProps {
  affiliation: boolean; // step 1-5 or 1-4
  currentStep: number;
  maxStep: number; // The user provided info untill this one
  setStepAccountAwaitBlockchain: (step: number) => void;
  unfinishedStep1: boolean;
  unfinishedStep2: boolean;
}

const ConsentProgressBar: React.FC<ConsentProgressBarProps> = ({
  affiliation,
  currentStep,
  maxStep,
  setStepAccountAwaitBlockchain,
  unfinishedStep1,
  unfinishedStep2,
}) => {
  /// TEST WITH USEMEMO
  const _steps = {
    Patient: 0,
    ShareWith: 1,
    Categories: 2,
    Time: 3,
    Confirm: 4,
  };
  const steps = affiliation
    ? _steps
    : Object.fromEntries(Object.entries(_steps).slice(1));
  return (
    <div className="w-full max-w-[500px] mx-auto mt-2 mb-2">
      <div className="flex justify-between items-center">
        {Object.entries(steps).map(([stepTitle, step], index) => (
          <React.Fragment key={step}>
            <button
              className={`rounded-full w-8 h-8 flex items-center justify-center text-xs ${
                (step === 1 && unfinishedStep1) ||
                (step === 2 && unfinishedStep2)
                  ? "bg-yellow-500 text-white" // Yellow if there is an uncompleted step
                  : step === currentStep
                  ? "bg-green-700 text-white  font-bold" // Dark Green if current step
                  : step <= maxStep
                  ? "bg-green-500 text-white" // Green if already provided info
                  : "bg-gray-200 text-gray-500" // Gray if still needs to provide info
              }`}
              onClick={() => setStepAccountAwaitBlockchain(step)}
              disabled={step > maxStep || step === currentStep}
            >
              {index + 1}
            </button>
            {index + 1 < Object.keys(steps).length && (
              <div
                className={`flex-grow h-1 ${
                  (step === 1 && unfinishedStep1) ||
                  (step === 2 && unfinishedStep2)
                    ? "bg-yellow-500 text-white"
                    : step < maxStep
                    ? "bg-green-500"
                    : "bg-gray-200"
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default memo(ConsentProgressBar);
