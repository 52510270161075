// getChanges, will store previous null/undefined/empty values that werent
export function showCancelAndDisableUpdate(
  update: Record<string, any>
): [boolean, boolean] {
  if (Object.keys(update).length === 0) return [false, true];
  return [
    true,
    // Disable if any is null, undefined, or empty string/object, array
    !Object.values(update).every(
      (value) => typeof value === "boolean" || !!value
    ), // False must pass as valid
  ];
}

export function equalObjects(
  entity: Record<string, any>,
  update: Record<string, any>
) {
  return Object.keys(filterChanges(entity, update)).length === 0;
}

// eslint-disable-next-line
export function filterChanges(
  entity: Record<string, any>,
  update: Record<string, any>
) {
  if (!entity) return update;
  if (!update) return {};
  if (typeof entity !== "object" || typeof entity !== "object") return {};
  update = trimObjectStrings(update);
  entity = trimObjectStrings(entity);
  return Object.fromEntries(
    Object.entries(update)
      // Filter null, then empty, then unchanged values, and also strip strings before comparing
      .filter(([key, value]) => {
        if (!Object.prototype.hasOwnProperty.call(entity, key)) return true; // New Field
        const entityValue = entity[key];
        // Both are false (undefined == null = empty);
        if (!value && !entityValue) return false;
        return !deepEqual(value, entityValue);
      })
  );
  // Allow Update -> Object.keys(filteredEntries).length === 0;
}

// eslint-disable-next-line
function deepEqual(x: any, y: any): boolean {
  if (x === y) return true;
  if (
    typeof x === "object" &&
    x !== null &&
    typeof y === "object" &&
    y !== null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;
    for (const key in x) {
      // Check if y has that key also
      if (Object.prototype.hasOwnProperty.call(y, key)) {
        if (!deepEqual(x[key], y[key])) return false;
      }
    }
    return true;
  }
  return false;
}

// eslint-disable-next-line
function trimObjectStrings<T extends Record<string, any>>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === "string" ? value.trim() : value,
    ])
  ) as T; // Type assertion to ensure the return type matches T
}
