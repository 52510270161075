import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import showToast from "../../../components/toast";
import { PaymentIntent, StripeError } from "@stripe/stripe-js";

export default function CheckoutForm() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      setIsProcessing(true);
      // Explicitly cast the result to include paymentIntent and error
      const result = (await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}`,
        },
      })) as {
        error: StripeError;
        paymentIntent: PaymentIntent;
      };

      const { error, paymentIntent } = result;

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(
            error.message || "Os dados do cartão não estão bem inseridos."
          );
          showToast(
            "Os dados do cartão não estão bem inseridos",
            "error",
            3000
          );
        } else {
          setMessage("Erro ao processar pagamento.");
          showToast("Erro ao processar pagamento", "error", 3000);
        }
        console.log(paymentIntent);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        showToast("Pagamento processado com sucesso!", "success", 3000);
        console.log("Pagamento processado com sucesso!");
        navigate("/consents");
      } else {
        setMessage("Erro ao processar pagamento.");
        showToast("Erro ao processar pagamento!", "error", 3000);
      }
    } catch (error) {
      console.error(error);
      showToast("Erro ao processar pagamento!", "error", 3000);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                country: "PT", // Set Portugal as the default country
              },
            },
          },
        }}
      />
      <div className="flex justify-end mt-10">
        <Button
          disabled={isProcessing || !stripe || !elements}
          onClick={handleSubmit}
        >
          {isProcessing ? "A processar ... " : "Pagar"}
        </Button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
