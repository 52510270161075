import * as React from "react";
import { cn } from "../../lib/utils";
import { IoIosClose } from "react-icons/io";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = "text", onChange, onClear, ...props }, ref) => {
    const [inputValue, setInputValue] = React.useState<string>("");

    // Handle input change
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInputValue(value);
      if (onChange) {
        onChange(event);
      }
    };

    // Clear input value
    const handleClear = () => {
      setInputValue("");
      if (onClear) onClear(); // For cases like profile name
      if (ref && typeof ref !== "function") {
        (ref as React.MutableRefObject<HTMLInputElement>).current?.focus();
      }
    };

    return (
      <div className="relative flex items-center w-full">
        <input
          type={type}
          value={inputValue}
          onChange={handleChange}
          className={cn(
            "flex h-10 w-full rounded-lg border border-ring placeholder:text-neutral-400 border-neutral-200 text-neutral-600 bg-background px-3 py-2 pr-9 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:border-neutral-400 disabled:cursor-not-allowed disabled:opacity-50",
            // Add these styles to override autofill (remove blue background when filled)
            "[&:-webkit-autofill]:bg-background [&:-webkit-autofill]:text-foreground [&:-webkit-autofill]:shadow-[0_0_0px_1000px_#ffffff_inset]",
            className
          )}
          ref={ref}
          {...props}
        />
        {/* {inputValue && (
          <span
            className="absolute right-2 top-[0.55rem] transform flex items-center cursor-pointer z-50 p-1"
            onClick={handleClear}
          >
            <IoIosClose className="text-neutral-600" />
          </span>
        )} */}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
