import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import { format } from "date-fns";

// Define the Plan type
type Plan = {
  name: string;
  price: number;
  duration: string;
};

// Define the props for the Payment component
interface PaymentProps {
  plan: Plan;
  back: () => void;
}

function Payment({ plan, back }: PaymentProps) {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState("");

  const today = new Date();
  const startDate = format(today, "dd/MM/yy");

  const endDate = new Date(today);
  if (plan.duration === "Anual") {
    endDate.setFullYear(today.getFullYear() + 1);
  } else {
    endDate.setMonth(today.getMonth() + 1);
  }

  useEffect(() => {
    stripeConfigurations();
  }, []);

  useEffect(() => {
    createPaymentIntent();
  }, [stripePromise]);

  const stripeConfigurations = async () => {
    try {
      const response = await axios.get("/payments/config", {
        withCredentials: true,
      });
      if (!response.data) {
        console.error("Error fetching Stripe configuration:", response);
        return;
      }
      const { publishableKey } = response.data;
      const stripe = await loadStripe(publishableKey);
      if (stripe !== undefined) setStripePromise(stripe);
    } catch (error) {
      console.error("Error fetching Stripe configuration:", error);
    }
  };

  const createPaymentIntent = async () => {
    try {
      const response = await axios.post(
        "/payments/create-payment-intent",
        {},
        {
          withCredentials: true,
        }
      );
      if (!response.data) {
        console.error("Error creating payment intent:", response);
        return;
      }
      setClientSecret(response.data);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  return (
    <div className="flex w-full transition-all mx-auto space-x-2">
      <div className="w-[65%]">
        <div className="space-y-4 rounded-lg border border-gray-100 bg-white p-6">
          <div className="flex justify-between">
            <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Pagamento
            </h2>
            <span
              className="flex flex-inline cursor-pointer items-center"
              onClick={back}
            >
              <span className="mt-1 hover:cursor-pointer hover:underline transition-all">
                Escolher outro plano
              </span>
            </span>
          </div>
          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12">
            <div className="lg:flex-1">
              {stripePromise && clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm />
                </Elements>
              ) : (
                <p>Loading payment information...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[35%]">
        <div className="mt-6 grow sm:mt-8 lg:mt-0 lg:flex-1">
          <div className="space-y-4 rounded-lg border border-gray-100 bg-white p-6">
            <div className="gap-y-10">
              <div>
                <dl className="flex items-center justify-between gap-4">
                  <dt className="text-base font-bold">
                    Plano {plan.name} {plan.duration}
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    {plan.price}€
                  </dd>
                </dl>
                <p className="text-sm text-gray-500">
                  {startDate} - {format(endDate, "dd/MM/yy")}
                </p>
              </div>
            </div>
            <dl className="flex items-center justify-between gap-4">
              <dt className="text-sm">IVA (23%)</dt>
              <dd className="text-sm font-medium text-gray-900">
                {(plan.price * 1.23 - plan.price).toFixed(2)}€
              </dd>
            </dl>

            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2">
              <dt className="text-base font-bold text-gray-900">Total</dt>
              <dd className="text-base font-bold text-gray-900">
                {(plan.price * 1.23).toFixed(2)}€
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
