// React
import React, { memo, useContext, useState } from "react";

// Internal
import { UserContext } from "../../../services/user";
import { userByIndentity } from "../../../types";

import { CardContent, CardFooter } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";
import { DialogDescription, DialogTitle } from "../../../components/ui/dialog"; // Import the Dialog component

// Add TypeScript types for props
interface Card0PatientProps {
  selectedUser: userByIndentity | null;
  setSelectedUser: (categories: userByIndentity | null) => void;
  nextStep: () => void;
}

const Card0VerifyIdentity = ({ selectedUser, nextStep }: Card0PatientProps) => {
  // Affiliation context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  useState(false);

  const patientRow = () => {
    if (!selectedUser) return null; // Verify if selectedUser exists
    return (
      <>
        <Avatar className="h-12 w-12 flex-shrink-0">
          <AvatarImage
            src={
              selectedUser.photo
                ? `data:image/png;base64,${selectedUser.photo}`
                : // eslint-disable-next-line
                  require("../../../components/images/profile.jpg")
            }
            alt="@shadcn"
          />
          <AvatarFallback>RG</AvatarFallback>
        </Avatar>
        <div className="flex flex-col text-sm min-w-0">
          <span className="font-semibold break-words">{selectedUser.name}</span>
          <span className="break-words">{selectedUser.birth}</span>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <CardContent className="grid gap-2">
        <DialogTitle>Confirmação de Identidade</DialogTitle>
        <DialogDescription>
          Ao avançar confirma que está a interagir com o utente abaixo
          apresentado.
          <div className="grid grid-cols-[auto,1fr,auto] gap-4 items-center relative p-2 border rounded-md mt-5">
            {patientRow()}
          </div>
        </DialogDescription>
      </CardContent>
      <CardFooter className="justify-end space-x-2 mt-auto">
        <Button variant="outline" onClick={() => {}}>
          Cancelar
        </Button>
        <Button onClick={() => nextStep()} className="">
          Verificar
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(Card0VerifyIdentity);
