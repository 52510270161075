// React
import React, { memo, useContext, useEffect, useState } from "react";

// Internal
import { UserContext } from "../../../services/user";
import {
  affiliationByName,
  listOrganizationsConsentCard,
  listOrganizationsOrDepartments,
} from "../../../types";
import RefreshIcon from "@mui/icons-material/Refresh";

import showToast from "../../../components/toast";
import { FaHandHoldingMedical, FaHospital } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import {
  CardTitle,
  CardDescription,
  CardContent,
  CardHeader,
  CardFooter,
} from "../../../components/ui/card";
import { Avatar, AvatarImage } from "@radix-ui/react-avatar";
import { Label } from "@radix-ui/react-label";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";
import { ToggleButton } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { KeyboardIcon } from "lucide-react";

// Add TypeScript types for props
interface Card1ShareWithProps {
  shareType: string;
  setShareType: React.Dispatch<React.SetStateAction<string>>;
  organizations: listOrganizationsConsentCard[];
  selectedOrganization: listOrganizationsConsentCard | null;
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<listOrganizationsConsentCard | null>
  >;
  departments: listOrganizationsOrDepartments[];
  selectedDepartments: listOrganizationsOrDepartments[];
  setSelectedDepartments: React.Dispatch<
    React.SetStateAction<listOrganizationsOrDepartments[]>
  >;
  professionals: affiliationByName[];
  setProfessionals: React.Dispatch<React.SetStateAction<affiliationByName[]>>;
  selectedAffiliations: affiliationByName[];
  setSelectedAffiliations: React.Dispatch<
    React.SetStateAction<affiliationByName[]>
  >;
  prevButton: JSX.Element;
  nextStep: () => void;
}

const itemsStage1 = [
  {
    icon: <FaHospital size={25} />,
    text: "Partilhar com organização",
    shareType: "organization",
    shortcut: "O",
  },
  {
    icon: <FaHandHoldingMedical size={25} />,
    text: "Partilhar com Departamento",
    shareType: "department",
    shortcut: "D",
  },
  {
    icon: <FaUserDoctor size={25} />,
    text: "Partilhar com Profissional de Saúde",
    shareType: "doctor",
    shortcut: "P",
  },
];

const Card1ShareWith = ({
  shareType,
  setShareType,
  organizations,
  selectedOrganization,
  setSelectedOrganization,
  departments,
  selectedDepartments,
  setSelectedDepartments,
  professionals,
  setProfessionals,
  selectedAffiliations,
  setSelectedAffiliations,
  prevButton,
  nextStep,
}: Card1ShareWithProps) => {
  // Affiliation context
  const userContext = useContext(UserContext) as UserContextWithUserType;

  // Search
  const [searchInput] = useState<string>("");
  const [searchProfessional, setSearchProfessional] = useState<string>("");

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (shareType !== "") return;
      if (e.code === "KeyO") {
        setShareType("organization");
      }
      if (e.code === "KeyD") {
        setShareType("department");
      }
      if (e.code === "KeyP") {
        setShareType("doctor");
      }
      if (e.code === "Enter") {
        handleAction();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const searchDcotor = async () => {
    if (!selectedOrganization) return;
    try {
      const { data } = await userContext.axiosInstance.get(
        `users/affiliationByName/${
          selectedOrganization!.id
        }/${searchProfessional}`
      );
      setProfessionals(data);
    } catch (error: any) {
      console.error("Error fetching departments:", error.response.data.message);
      showToast("Nenhum profissional encontrado", "error", 3000);
    }
  };

  const toggleOrganization = (_organization: listOrganizationsConsentCard) => {
    if (_organization === selectedOrganization) setSelectedOrganization(null);
    else setSelectedOrganization(_organization);
    // Reset departments and affiliations on any change of selected orgaization
    setSelectedDepartments([]);
    setSelectedAffiliations([]);
  };

  const toggleDepartments = (department: listOrganizationsOrDepartments) => {
    let newDepartments: listOrganizationsOrDepartments[] = [];
    if (selectedDepartments.includes(department)) {
      newDepartments = selectedDepartments.filter(
        (_department: listOrganizationsOrDepartments) =>
          _department !== department
      );
    } else newDepartments = selectedDepartments.concat(department);
    setSelectedDepartments(newDepartments);
  };

  const toggleProfessionals = (professional: affiliationByName) => {
    let newAffiliations: affiliationByName[] = [];
    if (selectedAffiliations.includes(professional)) {
      newAffiliations = selectedAffiliations.filter(
        (objects) => objects !== professional
      );
    } else setSelectedAffiliations(selectedAffiliations.concat(professional));
  };

  const selectShareType = (type: string) => {
    setShareType(type);
    if (userContext.activeAffiliation) {
      console.log("setting default org", selectedOrganization);
      setSelectedOrganization({
        id: userContext.activeAffiliation.organization.id,
        name: userContext.activeAffiliation.organization.name,
      });
    }
  };

  const handleAction = () => {
    shareType === "doctor" && selectedAffiliations.length === 0
      ? searchDcotor()
      : nextStep();
  };

  const clear = () => {
    setShareType("");
    setSearchProfessional("");
    setSelectedDepartments([]);
    setSelectedAffiliations([]);
    if (!userContext.activeAffiliation) setSelectedOrganization(null);
  };

  return (
    <div className="flex flex-col h-full">
      <CardHeader className="flex justify-between items-center">
        <div className="flex justify-between items-center w-full">
          <div>
            <CardTitle>
              {shareType === "" && "Partilhar com"}
              {shareType === "organization" && "Organização"}
              {shareType === "department" && "Departamento"}
              {shareType === "doctor" && "Profissional de saúde"}
            </CardTitle>
            <CardDescription className="pt-1">
              {shareType === "" &&
                "Selecione com quem quer partilhar os seus dados de saúde"}
              {shareType === "organization" &&
                "Selecione a organização com a qual pretente partilhar os seus dados"}
              {shareType === "department" &&
                "Selecione os departamento da organização com a qual pretente partilhar os seus dados"}
              {shareType === "doctor" &&
                "Selecione o Profissional de Saúde com o qual pretente partilhar os seus dados"}
            </CardDescription>
          </div>
          {shareType !== "" && (
            <ToggleButton
              size="small"
              value="reset"
              title="Reset"
              onChange={clear}
              style={{ padding: "4px", minWidth: "0" }}
            >
              <RefreshIcon style={{ color: "green", fontSize: "1rem" }} />
            </ToggleButton>
          )}
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        {shareType === "" && (
          <div className="flex flex-col justify-between space-y-2 pt-4 h-full">
            {itemsStage1.map((item, index) => (
              <div
                key={index}
                onClick={() => selectShareType(item.shareType)}
                className="flex bg-gray-50 h-full rounded-sm transition-all hover:scale-x-105 hover:cursor-pointer items-center pl-4 gap-2 shadow-sm hover:bg-gray-100"
              >
                {item.icon}
                {item.text}
                <span className="ml-auto text-xs text-gray-400 mr-5 flex items-center">
                  <KeyboardIcon className="mr-1 h-4" />
                  {item.shortcut}
                </span>
              </div>
            ))}
          </div>
        )}
        {(shareType === "organization" ||
          (!selectedOrganization && shareType !== "")) &&
          (userContext.activeAffiliation && selectedOrganization ? (
            <>
              <Input
                placeholder="Procurar Organização"
                className="mt-5 border-gray-100"
                disabled={true}
              />
              <div className="flex flex-col gap-y-2 pt-4">
                {selectedOrganization.name
                  .trim()
                  .toLowerCase()
                  .includes(searchInput.trim().toLowerCase()) && (
                  <div
                    className={`flex rounded-sm transition-all hover:cursor-pointer items-center pl-4 gap-2 shadow-sm py-4 bg-teal-600 text-white
                        `}
                  >
                    {selectedOrganization.name}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Input
                placeholder="Procurar Organização"
                className="mt-5 border-gray-100"
              />
              {organizations && (
                <>
                  <div className="flex flex-col gap-y-2 pt-4">
                    {organizations.map(
                      (organization, index) =>
                        organization.name
                          .trim()
                          .toLowerCase()
                          .includes(searchInput.trim().toLowerCase()) && (
                          <div
                            onClick={() => toggleOrganization(organization)}
                            key={index}
                            className={`flex rounded-sm transition-all hover:cursor-pointer items-center pl-4 gap-2 shadow-sm py-4 ${
                              organization === selectedOrganization
                                ? "bg-teal-600 text-white"
                                : "bg-gray-50 hover:bg-gray-100 hover:scale-x-105"
                            }`}
                          >
                            {organization.name}
                          </div>
                        )
                    )}
                  </div>
                </>
              )}
            </>
          ))}
        {selectedOrganization && shareType === "department" && (
          <>
            <Input
              className="border-gray-100 mt-5"
              placeholder="Procurar Departamento"
            />
            <div className="flex flex-col gap-y-2 my-3">
              {departments.map((department, index) => (
                <div
                  key={index}
                  onClick={() => toggleDepartments(department)}
                  className={`flex rounded-sm transition-all  hover:cursor-pointer items-center pl-4 gap-2 shadow-sm py-4 ${
                    selectedDepartments.includes(department)
                      ? "bg-teal-600 text-white"
                      : "bg-gray-50 hover:bg-gray-100 hover:scale-x-105"
                  }`}
                >
                  {department.name}
                </div>
              ))}
            </div>
          </>
        )}
        {selectedOrganization && shareType === "doctor" && (
          <>
            <div className="grid gap-2">
              <Label htmlFor="subject">Profissional de Saúde</Label>
              <Input
                id="subject"
                placeholder="Nome do Profissional de Saúde"
                value={searchProfessional}
                onChange={(e) => {
                  setSearchProfessional(e.target.value);
                }}
                disabled={false}
                name="cc"
              />
            </div>
            {professionals.length !== 0 && (
              <div>
                {professionals.map((prof) => (
                  <div
                    className={`grid grid-cols-[auto,1fr,auto] gap-4 items-center border p-2 rounded-md relative ${
                      selectedAffiliations.includes(prof)
                        ? "bg-teal-700 text-white"
                        : ""
                    }`}
                    onClick={() => toggleProfessionals(prof)}
                  >
                    <Avatar>
                      <AvatarImage
                        src={
                          prof.photo
                            ? `data:image/png;base64,${prof.photo}`
                            : // eslint-disable-next-line
                              require("../../../components/images/profile.jpg")
                        }
                        className="rounded-full w-12 h-12 object-cover"
                        alt="@shadcn"
                      />
                    </Avatar>
                    <div className="flex flex-col text-sm">
                      <span className="font-semibold">{prof.name}</span>
                      <span>#5473456</span>
                    </div>
                    <button
                      className="flex items-center justify-center h-6 w-6 rounded-full hover:bg-gray-200 transition-colors"
                      onClick={() => {
                        setSelectedAffiliations([]);
                        setProfessionals([]);
                      }}
                    >
                      <IoIosClose className="text-neutral-600" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </CardContent>
      {shareType !== "" && (
        <CardFooter className="flex justify-between mt-auto">
          {prevButton}
          <Button
            type="submit"
            className="bg-teal-700 hover:bg-teal-900"
            onClick={handleAction}
            disabled={
              (shareType === "organization" && !selectedOrganization) ||
              (shareType === "department" &&
                selectedDepartments.length === 0) ||
              (shareType === "doctor" && searchProfessional.length < 5)
            }
          >
            {shareType === "doctor" && selectedAffiliations.length === 0
              ? "Procurar"
              : "Pŕoximo"}
          </Button>
        </CardFooter>
      )}
    </div>
  );
};

export default memo(Card1ShareWith);
