import { UserCreationStatus } from "./enums";
import { DeepPrettify, Prettify } from "./pretier";

export enum userIdentityLabels {
  fullName = "Nome Completo",
  gender = "Gênero",
  birth = "Data de Nascimento",
  docId = "Número de Cartão de Cidadão",
  taxNumber = "Número de Identificação Fiscal",
  healthNumber = "Número de Saúde",
  socialSecurityNumber = "Número de Segurança Social",
  expiresAt = "Data de Validade",
  //countryAbbv = "Abreviação do país",
}

export type patient = {
  photo: string;
  fullName: string;
  birth: string;
  docId: string;
  gender: string;
  healthNumber: string;
  email?: string;
  phone?: string;
};

// DTOs
export type userByIndentityDto = {
  fullName?: string;
  birth?: string;
  docId?: string;
  taxNumber?: string;
  healthNumber?: string;
  socialSecurityNumber?: string;
};

export type updateProfileDto = Partial<userProfile>;
export type updateAffiliationDto = Omit<
  updateProfileDto,
  "photo" | "darkMode"
> & {
  id: string;
};

// Returns
export type userProfile = {
  photo: string;
  name: string;
  email: string;
  phone: string;
  darkMode: boolean;
};

export type userIdentity = {
  fullName: string;
  gender: string;
  birth: string;
  docId: string;
  taxNumber: string;
  healthNumber: string;
  socialSecurityNumber: string;
  expiresAt: Date;
  countryAbbv: string;
};

export type userAffiliationOrganization = {
  id: string;
  name: string;
  description: string;
};
export type userAffiliation = Prettify<{
  id: string;
  name: string;
  email: string;
  phone: string;
  organization: userAffiliationOrganization;
  departments: {
    name: string;
  }[];
  role: {
    name: string;
  };
}>;

export type user = DeepPrettify<{
  id: string;
  wallet: string;
  smartWallet: string;
  creationStatus: UserCreationStatus;
  lastPin: Date | null;
  failedPinAttempts: number;
  profile: userProfile;
  identities: userIdentity[];
  affiliations: userAffiliation[];
}>;

export type userByIndentity = {
  id: string;
  name: string;
  birth: string;
  docId: string;
  photo: string;
};

export type affiliationByName = {
  id: string;
  photo: string;
  name: string;
  role: string;
};
