// React
import React, { memo, useContext, useEffect, useState } from "react";

// Internal
import { UserContext } from "../../../services/user";
import { dataCategoryWithId } from "../../../types";
import { Checkbox } from "../../../components/ui/checkbox";
import { Button } from "../../../components/ui/button";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";

// Add TypeScript types for props
interface Card2CategoriesProps {
  categories: dataCategoryWithId[];
  selectedCategories: dataCategoryWithId[];
  setSelectedCategories: React.Dispatch<
    React.SetStateAction<dataCategoryWithId[]>
  >;
  prevStep: () => void;
  nextStep: () => void;
}

const Card2Categories = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  prevStep,
  nextStep,
}: Card2CategoriesProps) => {
  // Affiliation context
  const [selectAll, setSelectAll] = useState(true);
  const selectAllCategories = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedCategories(newSelectAll ? categories : []);
  };

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      console.log("e.ctrlKey", e);
      if (e.code === "Enter") {
        nextStep();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleCheckboxChange = (
    category: dataCategoryWithId,
    isChecked: boolean
  ) => {
    if (!isChecked) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((object) => object !== category)
      );
    }
  };

  return (
    <div className="flex flex-col h-full">
      <CardHeader>
        <CardTitle>Categorias</CardTitle>
        <CardDescription className="pt-1">
          Selecione quais as categorias de registos médicos a partilhar
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="flex w-full justify-end items-center gap-1 mb-3">
          <p className="text-sm text-black">Selecionar todas</p>
          <Checkbox
            onClick={selectAllCategories}
            checked={categories.length === selectedCategories.length}
          />
        </div>
        {categories.map((category: dataCategoryWithId, index) => (
          <div
            key={index}
            className={`flex h-1/8 rounded-sm transition-all hover:cursor-pointer items-center px-4 py-5 mb-1 shadow-sm ${
              selectedCategories.includes(category)
                ? "bg-teal-600 text-white"
                : "bg-gray-50 hover:bg-gray-100 hover:scale-x-105"
            }`}
            onClick={() =>
              handleCheckboxChange(
                category,
                selectedCategories.includes(category)
              )
            }
          >
            {category.name}
          </div>
        ))}
      </CardContent>
      <CardFooter className="flex justify-between mt-auto">
        <Button
          className="bg-teal-700 hover:bg-teal-900"
          onClick={() => {
            prevStep();
          }}
          disabled={false}
        >
          Anterior
        </Button>
        <Button
          className="bg-teal-700 hover:bg-teal-900"
          onClick={() => nextStep()}
          disabled={selectedCategories.length === 0}
        >
          Próximo
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(Card2Categories);
