import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../services/user";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";
import { HiArrowLongLeft } from "react-icons/hi2";

const icon = {
  hidden: {
    pathLength: 0,
    fill: "black",
  },
  visible: {
    pathLength: 1,
    fill: "black",
  },
};

const paths = [
  "M81.53,570.8c-27.06-51.1-41.36-108.84-41.36-166.99c0-86.99,31.63-170.82,89.05-236.04 C186.01,103.28,263.89,61.4,348.57,49.8c2.59,166.55,140.08,301.94,306.74,301.94c28.23,0,56.23-3.86,83.25-11.46 C734.99,429,673.08,486.2,543.79,520.24C424.56,551.63,257.95,560.95,81.53,570.8z",
  "M397.53,761.17c-62.18,0-123.41-16.21-177.07-46.89c-51.81-29.62-95.75-72.09-127.14-122.89 331.3-18.53,617.57-34.67,660.88-209.8c0.46,7.38,0.69,14.84,0.69,22.22c0,48.24-9.45,95.04-28.08,139.1 c-18,42.55-43.77,80.77-76.59,113.59c-32.82,32.82-71.04,58.59-113.59,76.59C492.58,751.72,445.78,761.17,397.53,761.17z",
];

const SelectUser = () => {
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const [isDoctor, setIsDoctor] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [doctorHovered, setDoctorHovered] = useState(false);
  const [patientHovered, setPatientHovered] = useState(false);

  const toggleDoctor = () => setIsDoctor(!isDoctor);
  const togglePatient = () => setIsPatient(!isPatient);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const typeformEmbed = (window as any).TypeformEmbed;

      if (typeformEmbed) {
        typeformEmbed.makeWidget({
          widgetContainer: document.querySelector(
            "[data-tf-live]"
          ) as HTMLElement,
          onSubmit: () => {
            alert("Form submitted!");
            setIsDoctor(false);
            setIsPatient(false);
            setSubmited(true);
          },
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const updateUser = async () => {
    try {
      const response = await userContext.axiosInstance.post(
        `users/upgrade-account/${isDoctor ? "PENDING_FORM" : "CREATED"}`
      );
      if (response.data) {
        console.log(response.data);
        userContext.setUser({
          ...userContext.user,
          creationStatus: response.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col md:flex-row overflow-hidden relative">
      <span
        onClick={() => userContext.logout(true)}
        className="fixed top-0 right-0 m-4 flex items-center justify-center px-5 py-2 text-sm text-gray-700 duration-300 bg-white rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700 hover:cursor-pointer z-20"
      >
        <HiArrowLongLeft className="w-6 h-6" />
        <span>Terminar sessão</span>
      </span>
      {!isDoctor && !isPatient && (
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none z-10">
          <div className="bg-white p-4 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 795.06 810.89"
              width="100"
              height="100"
            >
              {paths.map((d, index) => (
                <motion.path
                  key={index}
                  d={d}
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 1.5, ease: "circInOut" },
                    fill: { duration: 1.7, ease: [1, 0, 0.8, 1] },
                  }}
                  stroke="black"
                  strokeWidth="1"
                  fill="black"
                />
              ))}
            </svg>
          </div>
        </div>
      )}

      <div
        className={`absolute inset-0 flex items-center justify-center z-20 ${
          isDoctor || isPatient ? "pointer-events-auto" : "pointer-events-none"
        }`}
      >
        <div
          className={`bg-transparent transition-all flex flex-col items-center justify-center group ${
            isDoctor || isPatient
              ? "w-full opacity-90"
              : "md:w-0 w-full md:h-full h-0 opacity-0 pointer-events-none"
          }`}
        >
          <div className="text-center group-hover:opacity-100 group-hover:translate-y-0 md:w-1/2 w-[90%] mx-auto">
            <div
              data-tf-live="01J5DDEGG1W5PGJ8GSA4DC15HG"
              className="w-full"
            ></div>
            <div className="flex-inline space-x-2">
              <button
                className="bg-white font-semibold py-4 px-10 rounded-full border hover:scale-110 transition duration-300 mt-5"
                onClick={updateUser}
              >
                Concluir
              </button>
              <button
                className="bg-white font-semibold py-4 px-10 rounded-full border hover:scale-110 transition duration-300 mt-5"
                onClick={isDoctor ? toggleDoctor : togglePatient}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`h-full transition-all duration-300 flex md:items-end items-center justify-center group ${
          isPatient
            ? "h-0 w-0 opacity-0 pointer-events-none"
            : "w-full opacity-100"
        }`}
        style={{
          backgroundImage:
            "url('https://img.freepik.com/fotos-gratis/close-na-equipe-medica-pronta-para-o-trabalho_23-2149152535.jpg?t=st=1723800083~exp=1723803683~hmac=3d3ffa7fae7c72f561fb24d36c2e74ae5b84bde29d969b855ca124db0e2ca807&w=2000')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter:
            doctorHovered || isDoctor ? "brightness(100%)" : "brightness(15%)",
        }}
        onMouseEnter={() => setDoctorHovered(true)}
        onMouseLeave={() => setDoctorHovered(false)}
      >
        {!isDoctor && (
          <div
            className={`bg-white px-10 py-5 rounded-lg text-center mb-10 transition-all duration-300 ${
              doctorHovered || isDoctor
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            } group-hover:opacity-100 group-hover:translate-y-0`}
          >
            <h1 className="text-3xl font-bold mb-4">Profissional de Saúde</h1>
            <button
              className="bg-black text-white font-semibold py-4 px-10 rounded-full border hover:scale-110 transition duration-300 mb-2"
              onClick={toggleDoctor}
            >
              Criar conta
            </button>
            <div className="text-sm mb-2">
              Já criou a sua conta?
              <span className="text-sm underline hover:cursor-pointer ml-1">
                Login
              </span>
            </div>
          </div>
        )}
      </div>

      <div
        className={`h-full transition-all duration-300 flex md:items-end items-center justify-center group ${
          isDoctor
            ? "h-0 w-0 opacity-0 pointer-events-none"
            : "w-full opacity-100"
        }`}
        style={{
          backgroundImage:
            "url('https://www.mensjournal.com/.image/t_share/MjA1OTkzMDA0NDQyMzMwNDAy/childrens-medical-appointment.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter:
            patientHovered || isPatient
              ? "brightness(100%)"
              : "brightness(15%)",
        }}
        onMouseEnter={() => setPatientHovered(true)}
        onMouseLeave={() => setPatientHovered(false)}
      >
        {!isPatient && (
          <div
            className={`bg-white px-10 py-5 rounded-lg text-center mb-10 transition-all duration-300 ${
              patientHovered || isPatient
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            } group-hover:opacity-100 group-hover:translate-y-0`}
          >
            <h1 className="text-3xl font-bold mb-4">Utente</h1>
            <button
              className="bg-black text-white font-semibold py-4 px-10 rounded-full border hover:scale-110 transition duration-300 mb-2"
              onClick={togglePatient}
            >
              Criar conta
            </button>
            <div className="text-sm mb-2">
              Já criou a sua conta?
              <span className="text-sm underline hover:cursor-pointer ml-1">
                Login
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectUser;
