export function isEmail(email: string): boolean {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
// isPhone -> from shadcn

export function capitalizeWords(str: string): string {
  // p{L} : escape to mat match any kind of letter from any language
  // u : unicode flag to enable unicode mode (handle accented characters)
  return str.replace(
    /\b\p{L}+/gu,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
}

export function hexToRgba(hex: string, opacity: number): string {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${opacity})`;
}

export function emptyOrStringInLimits(
  string: string | undefined | null,
  max: number,
  min?: number
): boolean {
  if (!string) return true; // undefined, empty string, ...
  if (min && string.length < min) return false;
  if (max && string.length > max) return false;
  return true;
}

export function stringInLimits(
  string: string,
  max: number,
  min?: number
): boolean {
  if (!string) return false;
  if (min && string.length < min) return false;
  if (string.length > max) return false;
  return true;
}
