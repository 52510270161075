import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Search } from "lucide-react"; // Import Menu and X icons
import { recordType } from "../../types";
import ImageDetail from "./ImageDetail";
import PdfDetail from "./PdfDetail";
import TextDetail from "./TextDetail";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Separator } from "../../components/ui/separator";
import { UserContext } from "../../services/user";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";

function RecordsDetail() {
  const userContext = useContext(UserContext) as UserContextWithUserType;

  // Hooks
  const navigate = useNavigate();

  const isMounted = useRef(false);

  const location = useLocation();
  const records = location.state.records;
  const [activeRecord, setActiveRecord] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = useMemo(() => {
    console.log("list", records);
    return records.filter(
      (item: recordType) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [records, searchTerm]);

  const renderRecordDetail = () => {
    const record = records[activeRecord];
    switch (record.type) {
      case "Exames":
        return <ImageDetail record={record} />;
      case "Análises":
        return <PdfDetail record={record} />;
      case "Registos Clínicos":
        return <TextDetail record={record} />;
      case "Prescrições":
        return <PdfDetail record={record} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    navigate("/people");
  }, [userContext.activeAffiliation]);

  return (
    <div className="flex flex-col md:flex-row w-full max-w-screen-2xl mx-auto transition-all md:px-12 px-6 md:pt-24 pt-16 pb-4 space-y-5 md:space-y-0">
      {/* Sidebar */}
      <aside
        className={`w-64 bg-white transition-all duration-300 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 fixed md:static top-0 left-0 h-full z-30 overflow-y-auto`}
      >
        <div className="pt-4">
          <div className="relative mb-2">
            <Input
              type="text"
              placeholder="Procurar registos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={18}
            />
          </div>
          <Card className="p-4 md:p-2">
            <div className="space-y-2">
              {filteredList.map((item: recordType, index: number) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      setActiveRecord(index);
                      setSidebarOpen(false);
                    }}
                    className={`w-full text-left px-3 py-2 rounded-md transition-colors duration-200 ${
                      index === activeRecord
                        ? "bg-teal-100 text-teal-800"
                        : "hover:bg-gray-100"
                    }`}
                  >
                    <span className="truncate block">{item.name}</span>
                    <span className="text-xs text-gray-500">{item.type}</span>
                  </button>
                  {index !== filteredList.length - 1 && (
                    <Separator className="my-1 bg-gray-300" />
                  )}
                </div>
              ))}
            </div>
          </Card>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 overflow-y-auto">
        <div className="p-4">
          <div className="md:hidden mb-4">
            <Button
              className="bg-teal-700 hover:bg-teal-800 px-4 py-2 text-white rounded"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              Ver outros registos
            </Button>
          </div>
          <div className="bg-white rounded-lg border shadow-md">
            {renderRecordDetail()}
          </div>
        </div>
      </main>

      {/* Overlay for mobile */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
}

export default RecordsDetail;
