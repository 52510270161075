// src/pages/internalConsents/InternalconsentCard.tsx

import React, { useState } from "react";
import Card0VerifyIdentity from "./tabs/Card0Patient";
import Card1Templates from "./tabs/Card1Templates";
import Card2Signature from "./tabs/Card2Signature";

import { userByIndentity } from "../../types/users";
import { Template } from "../../types/internalConsents";

interface InternalConsentsCardProps {
  setDialogIsOpen: (value: boolean) => void;
  selectedUser: userByIndentity | null; // Added selectedUser to props
  setSelectedUser: (user: userByIndentity | null) => void;
  prevStep?: () => void;
}

const InternalConsentsCard: React.FC<InternalConsentsCardProps> = ({
  setDialogIsOpen,
  selectedUser,
  setSelectedUser,
}) => {
  const [step, setStep] = useState(1);
  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div>
      {step === 1 && (
        <Card0VerifyIdentity
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          nextStep={handleNext}
        />
      )}
      {step === 2 && (
        <Card1Templates
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
          nextStep={handleNext}
        />
      )}
      {step === 3 && <Card2Signature setDialogIsOpen={setDialogIsOpen} />}
    </div>
  );
};

export default InternalConsentsCard;
