// @ts-nocheck
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";

import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// https://mui.com/material-ui/material-icons/
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import MenuIcon from "@mui/icons-material/Menu";
import ContrastIcon from "@mui/icons-material/Contrast";
import SearchIcon from "@mui/icons-material/Search";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import StraightenIcon from "@mui/icons-material/Straighten";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { HiArrowLongLeft } from "react-icons/hi2";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import TagsTable from "./TagsTable";

import "./style.css";
import { App, getDwvVersion, decoderScripts } from "dwv";

// Image decoders (for web workers)
decoderScripts.jpeg2000 = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts[
  "jpeg-lossless"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts[
  "jpeg-baseline"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;

export const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

class DicomComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versions: {
        dwv: getDwvVersion(),
        react: React.version,
      },
      tools: {
        Scroll: {},
        ZoomAndPan: {},
        WindowLevel: {},
        Draw: {
          options: ["Ruler"],
        },
      },
      selectedTool: "Select Tool",
      loadProgress: 0,
      dataLoaded: false,
      dwvApp: null,
      metaData: {},
      orientation: "axial", // Default orientation
      orientations: ["axial", "coronal", "sagittal"], // All possible orientations
      showDicomTags: false,
      currentFileIndex: 0, // Add this line
      dicomUrls: [
        "/dicom/DICOM/000001F4/AA302E2E/AA00B0BE/00008014/EE0C0A3F (1).dcm",
      ], // Add this line
    };
  }

  componentDidMount() {
    // Create app
    const app = new App();
    // Initialize
    app.init({
      dataViewConfigs: { "*": [{ divId: "layerGroup0" }] },
      tools: this.state.tools,
    });
    // Load events
    let nLoadItem = null;
    let nReceivedError = null;
    let nReceivedAbort = null;
    app.addEventListener("loadstart", (/*event*/) => {
      // Reset
      nLoadItem = 0;
      nReceivedError = 0;
      nReceivedAbort = 0;
      // Set state
      this.setState({ loadProgress: 0 });
    });
    app.addEventListener("loadprogress", (event) => {
      this.setState({ loadProgress: event.loaded });
    });
    app.addEventListener("load", (/*event*/) => {
      // Set state
      this.setState({ loadProgress: 100, dataLoaded: true });
    });
    app.addEventListener("loaderror", (event) => {
      console.error(event.error);
      ++nReceivedError;
    });
    app.addEventListener("loadabort", (/*event*/) => {
      ++nReceivedAbort;
    });
    app.addEventListener("loadend", (/*event*/) => {
      if (nReceivedError) {
        this.setState({ loadProgress: 0 });
        alert("Received errors during load. Check the console.");
      }
      if (nReceivedAbort) {
        this.setState({ loadProgress: 0 });
        alert("Load was aborted.");
      }
    });
    // Store
    this.setState({ dwvApp: app });

    // Load DICOM file from public folder
    app.loadURLs(this.state.dicomUrls); // Update this line
  }

  /**
   * Get the icon of a tool.
   *
   * @param {string} tool The tool name.
   * @returns {Icon} The associated icon.
   */
  getToolIcon = (tool) => {
    let res;
    if (tool === "Scroll") {
      res = <MenuIcon />;
    } else if (tool === "ZoomAndPan") {
      res = <SearchIcon />;
    } else if (tool === "WindowLevel") {
      res = <ContrastIcon />;
    } else if (tool === "Draw") {
      res = <StraightenIcon />;
    }
    return res;
  };

  /**
   * Handle a change tool event.
   * @param {string} tool The new tool name.
   */
  onChangeTool = (tool) => {
    if (this.state.dwvApp) {
      this.setState({ selectedTool: tool });
      this.state.dwvApp.setTool(tool);
      if (tool === "Draw") {
        this.onChangeShape(this.state.tools.Draw.options[0]);
      }
    }
  };

  /**
   * Check if a tool can be run.
   *
   * @param {string} tool The tool name.
   * @returns {boolean} True if the tool can be run.
   */
  canRunTool = (tool) => {
    let res;
    if (tool === "Scroll") {
      res = this.state.dwvApp.canScroll();
    } else if (tool === "WindowLevel") {
      res = this.state.dwvApp.canWindowLevel();
    } else {
      res = true;
    }
    return res;
  };

  /**
   * Toggle the viewer orientation.
   */
  toggleOrientation = () => {
    const currentIndex = this.state.orientations.indexOf(
      this.state.orientation
    );
    const nextIndex = (currentIndex + 1) % this.state.orientations.length;
    const newOrientation = this.state.orientations[nextIndex];
    this.setOrientation(newOrientation);
  };

  /**
   * Set the viewer orientation.
   * @param {string} newOrientation The new orientation.
   */
  setOrientation = (newOrientation) => {
    this.setState({ orientation: newOrientation });
    // update data view config
    const config = {
      "*": [
        {
          divId: "layerGroup0",
          orientation: newOrientation,
        },
      ],
    };
    this.state.dwvApp.setDataViewConfigs(config);
    // render data
    const dataIds = this.state.dwvApp.getDataIds();
    for (const dataId of dataIds) {
      this.state.dwvApp.render(dataId);
    }
  };

  /**
   * Handle a change draw shape event.
   * @param {string} shape The new shape name.
   */
  onChangeShape = (shape) => {
    if (this.state.dwvApp) {
      this.state.dwvApp.setToolFeatures({ shapeName: shape });
    }
  };

  /**
   * Handle a reset event.
   */
  onReset = () => {
    if (this.state.dwvApp) {
      this.state.dwvApp.resetDisplay();
    }
  };

  /**
   * Open the DICOM tags dialog.
   */
  handleTagsDialogOpen = () => {
    this.setState({ showDicomTags: true });
  };

  /**
   * Close the DICOM tags dialog.
   */
  handleTagsDialogClose = () => {
    this.setState({ showDicomTags: false });
  };

  // New method to render orientation thumbnails
  renderOrientationThumbnails = () => {
    return this.state.orientations.map((orientationItem, index) => {
      const isSelected = this.state.orientation === orientationItem;
      return (
        <div
          key={index}
          className={`cursor-pointer px-6 py-3 mb-2 ${
            isSelected ? "bg-blue-200 border-2 border-blue-500" : "bg-gray-100"
          }`}
          onClick={() => this.setOrientation(orientationItem)}
        >
          <div className="w-full h-24 flex items-center justify-center">
            <span className="text-lg font-semibold">{orientationItem}</span>
          </div>
          <p className="text-xs mt-1 text-center">{orientationItem}</p>
        </div>
      );
    });
  };

  // Add this method to switch to the next file
  nextFile = () => {
    const nextIndex =
      (this.state.currentFileIndex + 1) % this.state.dicomUrls.length;
    this.setState({ currentFileIndex: nextIndex }, () => {
      this.state.dwvApp.loadURLs([this.state.dicomUrls[nextIndex]]);
    });
  };

  // Add this method to switch to the previous file
  prevFile = () => {
    const prevIndex =
      (this.state.currentFileIndex - 1 + this.state.dicomUrls.length) %
      this.state.dicomUrls.length;
    this.setState({ currentFileIndex: prevIndex }, () => {
      this.state.dwvApp.loadURLs([this.state.dicomUrls[prevIndex]]);
    });
  };

  render() {
    const { classes } = this.props;
    const { tools, loadProgress, dataLoaded, metaData } = this.state;

    const handleToolChange = (event, newTool) => {
      if (newTool) {
        this.onChangeTool(newTool);
      }
    };
    const toolsButtons = Object.keys(tools).map((tool) => {
      return (
        <ToggleButton
          value={tool}
          key={tool}
          title={tool}
          disabled={!dataLoaded || !this.canRunTool(tool)}
        >
          {this.getToolIcon(tool)}
        </ToggleButton>
      );
    });

    return (
      <div className="w-full h-screen flex items-center justify-center pt-24">
        <div className="flex justify-center items-center h-screen w-1/2">
          <div className="flex w-full">
            <div className="pr-4 w-[200px]">
              <div className="flex flex-inline cursor-pointer items-center space-x-2 px-4 py-2 mb-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-all duration-300">
                <HiArrowLongLeft className="w-6 h-6" />
                <span>Voltar</span>
              </div>
              {this.renderOrientationThumbnails()}
            </div>
            <div className="w-full">
              <div className="w-full">
                <Stack
                  direction="row"
                  spacing={1}
                  padding={1}
                  justifyContent="center"
                  flexWrap="wrap"
                >
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={this.state.selectedTool}
                    exclusive
                    onChange={handleToolChange}
                  >
                    {toolsButtons}
                  </ToggleButtonGroup>

                  <ToggleButton
                    size="small"
                    value="reset"
                    title="Reset"
                    disabled={!dataLoaded}
                    onChange={this.onReset}
                  >
                    <RefreshIcon />
                  </ToggleButton>

                  <ToggleButton
                    size="small"
                    value="toggleOrientation"
                    title="Toggle Orientation"
                    disabled={!dataLoaded}
                    onClick={this.toggleOrientation}
                  >
                    <CameraswitchIcon />
                  </ToggleButton>

                  <ToggleButton
                    size="small"
                    value="tags"
                    title="Tags"
                    disabled={!dataLoaded}
                    onClick={this.handleTagsDialogOpen}
                  >
                    <LibraryBooksIcon />
                  </ToggleButton>

                  <Dialog
                    open={this.state.showDicomTags}
                    onClose={this.handleTagsDialogClose}
                    TransitionComponent={TransitionUp}
                  >
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton
                          color="inherit"
                          onClick={this.handleTagsDialogClose}
                          aria-label="Close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography
                          variant="h6"
                          color="inherit"
                          className={classes.flex}
                        >
                          DICOM Tags
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    <TagsTable data={metaData} />
                  </Dialog>
                </Stack>

                <div id="layerGroup0" className="flex-1 overflow-auto">
                  {/* DICOM image will be rendered here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
} // DicomComponent

DicomComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DicomComponent;
