import React, { useContext, useEffect, useMemo, useState } from "react";

import Loading from "../../../components/loading";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../../../components/ui/otp-input";
import { Input } from "../../../components/ui/input";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";
import { isEmail } from "../../../utils/stringUtils";
import { CCService } from "../../../services/CC";
import { UserContext } from "../../../services/user";
import { PhoneInputLight } from "../../../components/ui/phone-input-light";
import showToast from "../../../components/toast";
import {
  CardDescription,
  CardFooter,
  CardTitle,
  CardContent,
  CardHeader,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { OTP_SPACING } from "../../../environment/variables";
import { UserContextWithUserType } from "../../../shared/UserContentWithUser";

interface CreateCCProps {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// No need fo useCallback since is all the same component and not in child
const CreateCC = ({ setIsDialogOpen }: CreateCCProps) => {
  // Context and CC service
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const ccService = useMemo(() => new CCService(userContext.axiosInstance), []);

  // Steps
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState<string | undefined>("PT");
  const [otp, setOtp] = useState("");
  const [otpCountdown, setOtpCountdown] = useState<number>(0);

  const [waiting, setWaiting] = useState(false);
  const [payloadExpiresAt, setPayloadExpiresAt] = useState<Date | null>(null);
  const [canGoOTP, setCanGoOTP] = useState(false);

  useEffect(() => {
    return () => {
      const _logout = async () => {
        await ccService.logout();
      };
      _logout();
    };
  }, []);

  useEffect(() => {
    if (payloadExpiresAt && new Date() > payloadExpiresAt) {
      //console.log("Payload expired");
      if (waiting && step === 2) return;
      showToast("Sessão para criar novo utilizador expirou", "error", 3000);
      // CLOSE
      setIsDialogOpen(false);
    }
  }, [waiting]);

  async function readCCData() {
    setWaiting(true);
    try {
      //console.log("Connecting User...");
      await ccService.connectUser();
      const _payloadExpiresAt = ccService.payloadExpiresAt;
      setPayloadExpiresAt(_payloadExpiresAt);
      if (_payloadExpiresAt) {
        const timer = _payloadExpiresAt.getTime() - new Date().getTime();
        setTimeout(() => {
          if (waiting && step === 2) return; // Executes in the useEffect then
          showToast("Sessão para criar novo utilizador expirou", "error", 3000);
          setIsDialogOpen(false);
        }, timer);
      }
      nextStep();
    } catch (error: any) {
      console.error("Error reading CC data", error);
      const status = error.response?.status;
      if (status && status === 409) {
        //Cartão de Cidadão já associado a um utilizador
        setIsDialogOpen(false);
      }
    } finally {
      setWaiting(false);
    }
  }

  function isValidEmailOrPhone() {
    if (waiting) return false;
    if (
      phoneNumber &&
      isValidPhoneNumber(phoneNumber, countryCode as CountryCode)
    )
      return true;
    if (isEmail(email)) return true;
    return false;
  }

  async function sendOtp() {
    if (waiting) return;
    setCanGoOTP(false);
    setWaiting(true);
    try {
      if (email.length > 0) {
        await ccService.sendOtpEmail(email);
      } else {
        await ccService.sendOtpSms(phoneNumber);
      }
      nextStep();
      let countdown = OTP_SPACING;
      const timerId = setInterval(() => {
        countdown -= 1;
        setOtpCountdown(countdown);
        if (countdown === 0) clearInterval(timerId); // Stop the timer when it reaches 0
      }, 1000);
      setCanGoOTP(true);
    } catch (error: any) {
      console.error("Error sending OTP", error);
    } finally {
      setWaiting(false);
    }
  }

  async function createUser() {
    if (waiting) return;
    setCanGoOTP(false);
    setWaiting(true);
    try {
      await ccService.createCCOTP(
        otp,
        email.length > 0 ? "EMAIL" : "PHONE",
        email.length > 0 ? email : phoneNumber
      );
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error creating user", error);
    } finally {
      setWaiting(false);
    }
  }

  function validOtp() {
    if (waiting) return false;
    return otp.length === 6;
  }

  function nextStep() {
    setStep(step + 1);
  }

  return (
    <div className={`flex flex-col gap-2 h-[500px]`}>
      {step === 0 && (
        <>
          <CardHeader>
            <CardTitle>Criar conta com Cartão de Cidadão</CardTitle>
            <CardDescription className="text-sm text-gray-800">
              Quando o cartao de cidadão estiver inserido no leitor, clique em
              Ler Cartão
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col justify-between h-full">
            <div className="flex-grow flex items-center justify-center">
              <Loading full={false} disableTimeout={true} />
            </div>
            <Button
              className="bg-teal-700 hover:bg-teal-900 w-full"
              onClick={readCCData}
              disabled={waiting}
            >
              Ler Cartão
            </Button>
          </CardContent>
        </>
      )}
      {step === 1 && (
        <>
          <CardHeader>
            <CardTitle>Associar email ou número de telemóvel</CardTitle>
            <CardDescription className="text-sm text-gray-800">
              Quando o cartao de cidadão estiver inserido no leitor, clique em
              Ler Cartão
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col h-full">
            <p>
              Associar o cartão de cidadão a um email ou número de telemóvel só
              pode ser feito uma vez. Certifique-se que o email ou número de
              telemóvel que vai associar é o correto.
            </p>
            <Input
              type="email"
              className="mt-7"
              placeholder="email@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={phoneNumber !== "" || waiting}
            />
            <p className="text-xs font-semibold text-gray-500 mt-1">
              Inserir email.
            </p>
            <PhoneInputLight
              className="w-full mt-7 placeholder-gray-500"
              defaultCountry={"PT"}
              onChange={setPhoneNumber}
              onCountryChange={setCountryCode}
              defaultValue={phoneNumber}
              disabled={email !== "" || waiting}
            />
            <p className="text-xs font-semibold text-gray-500 mt-1">
              Inserir número de telemóvel.
            </p>
            <Button
              className="bg-teal-700 hover:bg-teal-900 mt-8"
              onClick={sendOtp}
              disabled={!isValidEmailOrPhone() || otpCountdown > 0}
            >
              Submeter {otpCountdown > 0 ? `(${otpCountdown})` : ""}
            </Button>
          </CardContent>
          <CardFooter className="justify-end space-x-2">
            {canGoOTP ? (
              <Button
                onClick={() => setStep((nextStep) => nextStep + 1)}
                disabled={false} // waiting
              >
                Próximo
              </Button>
            ) : (
              <> </>
            )}
          </CardFooter>
        </>
      )}
      {step === 2 && (
        <>
          <CardHeader>
            <CardTitle>Verificar Código de Segurança</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col h-full">
            <p>
              Associar o cartão de cidadão a um email ou número de telemóvel só
              pode ser feito uma vez. Certifique-se que o email ou número de
              telemóvel que vai associar é o correto.
            </p>
            <p className="text-gray-500">
              Foi enviado um código de segurança para{" "}
              {email.length > 0 ? email : phoneNumber}
            </p>
            {waiting && (
              <Loading
                full={false}
                disableTimeout={true}
                children={
                  <span className="font-semibold font-md">
                    A confirmar o código de verificação
                  </span>
                }
              />
            )}
            <div className="flex flex-col items-center justify-center">
              <InputOTP maxLength={6} onChange={setOtp}>
                <InputOTPGroup>
                  <InputOTPSlot index={0} showChar={true} />
                  <InputOTPSlot index={1} showChar={true} />
                  <InputOTPSlot index={2} showChar={true} />
                  <InputOTPSlot index={3} showChar={true} />
                  <InputOTPSlot index={4} showChar={true} />
                  <InputOTPSlot index={5} showChar={true} />
                </InputOTPGroup>
              </InputOTP>
            </div>
            <Button
              className="bg-teal-700 hover:bg-teal-900 mt-8"
              onClick={createUser}
              disabled={!validOtp()}
            >
              Verificar e Criar Conta
            </Button>
          </CardContent>
          <CardFooter className="justify-start space-x-2">
            <Button
              onClick={() => setStep((prevStep) => prevStep - 1)}
              disabled={waiting}
            >
              Anterior
            </Button>
          </CardFooter>
        </>
      )}
    </div>
  );
};

export default CreateCC;
